import { useEffect, useState } from "react";
import "./Teste_Contactos.css";
import { Link, useLocation } from "react-router-dom";
import Portugal_flag from "../../assets/images/Portugal_flag.png";
import United_Kingdom_flag from "../../assets/images/United-Kingdom_flag.png";
import { ReactComponent as ArrowDown } from "../../assets/images/Arrow_down.svg";
import { ReactComponent as ArrowDownYellow } from "../../assets/images/Arrow_down_active.svg";
import { ReactComponent as ArrowDownTransparent } from "../../assets/images/Arrow_down_transparent.svg";
import video from "../../assets/videos/example_video.mp4";
import SocialMediaBarPT from "../../components/SocialMediaBar/SocialMediaBar_PT";
import image from "../../assets/images/jm_investments.jpg";
import instagram from "../../assets/images/instagram.png";
import facebook from "../../assets/images/facebook.png";
import linkedin from "../../assets/images/linkedin.png";
import whatsapp from "../../assets/images/whatsapp.png";
import mail from "../../assets/images/mail.png";
import ReactWhatsapp from "react-whatsapp";

export default function TesteContactosEN() {
  const location = useLocation();
  const [buttonActive, setButtonActive] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 640) {
        setButtonActive(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="app-container-contactos">
      <div className="container-header">
        <div className="container-options">
          <Link
            to="/en"
            className={`container-suboption ${
              location.pathname === "/en" ? "active" : ""
            }`}
          >
            Home
          </Link>
          <Link
            to="/en/about-us"
            className={`container-suboption ${
              location.pathname === "/en/about-us" ? "active" : ""
            }`}
          >
            About Us
          </Link>
          <Link
            to="/en/real-estate"
            className={`container-suboption ${
              location.pathname === "/en/real-estate" ? "active" : ""
            }`}
          >
            Real Estate
          </Link>
          <Link
            to="/en/contacts"
            className={`container-suboption ${
              location.pathname === "/en/contacts" ? "active" : ""
            }`}
          >
            Contacts
          </Link>
          <div className="container-suboption">
            <div
              className={`container-suboption-flag ${
                dropdownOpen ? "active" : ""
              }`}
              onClick={handleDropdownToggle}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <img
                src={United_Kingdom_flag}
                className="container-flag"
                alt="EN"
              />
              {isHovered || dropdownOpen ? (
                <ArrowDownYellow className="container-arrow" fill="#c28e2b" />
              ) : (
                <ArrowDown className="container-arrow" />
              )}
            </div>
            {dropdownOpen && (
              // <div className="container-dropdown-content">
              //   <Link to="/en" className="container-dropdown-item">
              //     <img
              //       src={United_Kingdom_flag}
              //       className="container-flag"
              //       alt="EN"
              //     />
              //   </Link>
              // </div>
              <div
                className="container-dropdown-content"
                // onClick={handleDropdownToggle}
              >
                <div className="container-suboption-flag">
                  <Link to="/pt/contactos" className="container-dropdown-item">
                    <img
                      src={Portugal_flag}
                      className="container-flag"
                      alt="PT"
                    />
                  </Link>
                  <ArrowDownTransparent className="container-arrow" />
                </div>
              </div>
            )}
          </div>
        </div>
        <button
          className={`container-menu ${buttonActive ? "active" : ""}`}
          onClick={() => setButtonActive(!buttonActive)}
        >
          {!buttonActive ? (
            <svg
              className="menu-icon"
              stroke="currentColor"
              fill="#ffffff"
              strokeWidth="0"
              viewBox="0 0 24 24"
              aria-hidden="true"
              height="3em"
              width="3em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="menu-line top"
                d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75z"
              ></path>
              <path
                className="menu-line middle"
                d="M3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12z"
              ></path>
              <path
                className="menu-line bottom"
                d="M3 17.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
              ></path>
            </svg>
          ) : (
            <svg
              className="menu-icon-active"
              stroke="#ffffff"
              fill="#ffffff"
              strokeWidth="0"
              viewBox="0 0 24 24"
              aria-hidden="true"
              height="3em"
              width="3em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke="#ffffff"
                stroke-width="1.5"
                d="M18.973 5.027L5.028 18.972m0-13.945l13.944 13.945"
              ></path>
            </svg>
          )}
        </button>
        <div className={`menu-container ${buttonActive ? "active" : ""}`}>
          <div className={`menu-subcontainer ${buttonActive ? "active" : ""}`}>
            <div className="menu-subcontainer-column">
              <Link
                to="/en"
                className={`menu-subcontainer-option ${
                  location.pathname === "/en" ? "active" : ""
                }`}
              >
                Home
              </Link>
              <Link
                to="/en/about-us"
                className={`menu-subcontainer-option ${
                  location.pathname === "/en/about-us" ? "active" : ""
                }`}
              >
                About Us
              </Link>
              <Link
                to="/en/real-estate"
                className={`menu-subcontainer-option ${
                  location.pathname === "/en/real-estate" ? "active" : ""
                }`}
              >
                Real Estate
              </Link>
              <Link
                to="/en/contacts"
                className={`menu-subcontainer-option ${
                  location.pathname === "/en/contacts" ? "active" : ""
                }`}
              >
                Contacts
              </Link>
              <Link
                to="/pt/contactos"
                className={`menu-subcontainer-option ${
                  location.pathname === "/pt" ? "active" : ""
                }`}
              >
                <div className="menu-container-flag">
                  <div className="menu-subcontainer-flag">
                    <img
                      src={Portugal_flag}
                      alt="PT"
                      className="menu-subcontainer-flag-image"
                    />
                  </div>
                  <div>PT</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="content"> */}
      {/* <div className="test-contacts-container"> */}
      <div className="test-contacts-content">
        <div className="test-contacts-square">
          <img src={image} className="contacts-square-image"></img>
        </div>
        <div className="test-contacts-content-text">
          <h1 className="test-h1">OFFICE HOURS</h1>
          <div className="test-contacts-content-text-row">
            <p className="test-contacts-text">MONDAY TO FRIDAY</p>
            <p className="test-contacts-sub-text">09h to 20h</p>
          </div>

          <div className="test-contacts-content-text-row">
            <p className="test-contacts-text">SATURDAY</p>
            <p className="test-contacts-sub-text">09h to 12h</p>
          </div>
        </div>
        <div class="test-social-media-bar">
          <div className="social-media-buttons">
            <a href="mailto:geral@jminvestments.pt">
              <img src={mail} className="test-mediabar-icon" />
            </a>
            <ReactWhatsapp
              className="whatsapp-button"
              number="351965043170"
              message="Olá!"
            >
              <img src={whatsapp} className="test-mediabar-icon" />
            </ReactWhatsapp>
            <Link
              to="https://www.linkedin.com/in/jorge-miguel-fonseca/"
              target="_blank"
            >
              <img src={linkedin} className="test-mediabar-icon" />
            </Link>
            <Link
              to="https://www.facebook.com/share/7Zk8SRamQpm4r7wk/"
              target="_blank"
            >
              <img src={facebook} className="test-mediabar-icon" />
            </Link>
            <Link
              to="https://www.instagram.com/jm__investments?igsh=NHJ5Y3JzbTRodW0z"
              target="_blank"
            >
              <img src={instagram} className="test-mediabar-icon" />
            </Link>
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}
    </div>
  );
}
