import React, { useState, useEffect } from "react";
import "./ConfirmationPopup.css";
import CheckMark from "../../../assets/images/check_mark.png";

export default function ConfirmationPopup({ showPopup, message }) {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (showPopup) {
      setIsVisible(true);
      // setTimeout(() => {
      //   setIsVisible(false);
      // }, 1000);
    }
  }, [showPopup]);

  return (
    <>
      {isVisible && (
        <div
          className={`popup-overlay ${
            message.includes("sucesso") ? "green" : "red"
          }`}
        >
          <div className="confirmation-class-modal">
            <div className="popup-row">
              <img src={CheckMark} className="confirmation-popup-image" />
              <p className="popup-text">{message}</p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
