import { useEffect, useState } from "react";
import "./Teste.css";
import { Link, useLocation } from "react-router-dom";
import Portugal_flag from "../../assets/images/Portugal_flag.png";
import United_Kingdom_flag from "../../assets/images/United-Kingdom_flag.png";
import ProfileImage from "../../assets/images/JM_INVESTMENTS_image.jpg";
import { ReactComponent as ArrowDown } from "../../assets/images/Arrow_down.svg";
import { ReactComponent as ArrowDownYellow } from "../../assets/images/Arrow_down_active.svg";
import { ReactComponent as ArrowDownTransparent } from "../../assets/images/Arrow_down_transparent.svg";
import video from "../../assets/videos/home_video.mp4";

export default function TesteSobreNosEN() {
  const location = useLocation();
  const [buttonActive, setButtonActive] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 640) {
        setButtonActive(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="app-container-sobre-nos">
      <div className="container-header">
        <div className="container-options">
          <Link
            to="/en"
            className={`container-suboption ${
              location.pathname === "/en" ? "active" : ""
            }`}
          >
            Home
          </Link>
          <Link
            to="/en/about-us"
            className={`container-suboption ${
              location.pathname === "/en/about-us" ? "active" : ""
            }`}
          >
            About Us
          </Link>
          <Link
            to="/en/real-estate"
            className={`container-suboption ${
              location.pathname === "/en/real-estate" ? "active" : ""
            }`}
          >
            Real Estate
          </Link>
          <Link
            to="/en/contacts"
            className={`container-suboption ${
              location.pathname === "/en/contacts" ? "active" : ""
            }`}
          >
            Contacts
          </Link>
          <div className="container-suboption">
            <div
              className={`container-suboption-flag ${
                dropdownOpen ? "active" : ""
              }`}
              onClick={handleDropdownToggle}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <img
                src={United_Kingdom_flag}
                className="container-flag"
                alt="EN"
              />
              {isHovered || dropdownOpen ? (
                <ArrowDownYellow className="container-arrow" fill="#c28e2b" />
              ) : (
                <ArrowDown className="container-arrow" />
              )}
            </div>
            {dropdownOpen && (
              // <div className="container-dropdown-content">
              //   <Link to="/en" className="container-dropdown-item">
              //     <img
              //       src={United_Kingdom_flag}
              //       className="container-flag"
              //       alt="EN"
              //     />
              //   </Link>
              // </div>
              <div
                className="container-dropdown-content"
                // onClick={handleDropdownToggle}
              >
                <div className="container-suboption-flag">
                  <Link to="/pt/sobre-nos" className="container-dropdown-item">
                    <img
                      src={Portugal_flag}
                      className="container-flag"
                      alt="PT"
                    />
                  </Link>
                  <ArrowDownTransparent className="container-arrow" />
                </div>
              </div>
            )}
          </div>
        </div>
        <button
          className={`container-menu ${buttonActive ? "active" : ""}`}
          onClick={() => setButtonActive(!buttonActive)}
        >
          {!buttonActive ? (
            <svg
              className="menu-icon"
              stroke="currentColor"
              fill="#ffffff"
              strokeWidth="0"
              viewBox="0 0 24 24"
              aria-hidden="true"
              height="3em"
              width="3em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="menu-line top"
                d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75z"
              ></path>
              <path
                className="menu-line middle"
                d="M3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12z"
              ></path>
              <path
                className="menu-line bottom"
                d="M3 17.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
              ></path>
            </svg>
          ) : (
            <svg
              className="menu-icon-active"
              stroke="#ffffff"
              fill="#ffffff"
              strokeWidth="0"
              viewBox="0 0 24 24"
              aria-hidden="true"
              height="3em"
              width="3em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke="#ffffff"
                stroke-width="1.5"
                d="M18.973 5.027L5.028 18.972m0-13.945l13.944 13.945"
              ></path>
            </svg>
          )}
        </button>
        <div className={`menu-container ${buttonActive ? "active" : ""}`}>
          <div className={`menu-subcontainer ${buttonActive ? "active" : ""}`}>
            <div className="menu-subcontainer-column">
              <Link
                to="/en"
                className={`menu-subcontainer-option ${
                  location.pathname === "/en" ? "active" : ""
                }`}
              >
                Home
              </Link>
              <Link
                to="/en/about-us"
                className={`menu-subcontainer-option ${
                  location.pathname === "/en/about-us" ? "active" : ""
                }`}
              >
                About Us
              </Link>
              <Link
                to="/en/real-estate"
                className={`menu-subcontainer-option ${
                  location.pathname === "/en/real-estate" ? "active" : ""
                }`}
              >
                Real Estate
              </Link>
              <Link
                to="/en/contacts"
                className={`menu-subcontainer-option ${
                  location.pathname === "/en/contacts" ? "active" : ""
                }`}
              >
                Contacts
              </Link>
              <Link
                to="/pt/sobre-nos"
                className={`menu-subcontainer-option ${
                  location.pathname === "/pt/sobre-nos" ? "active" : ""
                }`}
              >
                <div className="menu-container-flag">
                  <div className="menu-subcontainer-flag">
                    <img
                      src={Portugal_flag}
                      alt="PT"
                      className="menu-subcontainer-flag-image"
                    />
                  </div>
                  <div>PT</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div
        className="test-sobre-nos-content"
        style={{
          paddingBottom: window.innerWidth >= 839 && !showMore ? "268px" : "",
        }}
      >
        <div className="test-sobre-nos-content-column">
          <div className="test-contacts-content-text">
            <h1 className="test-h1" style={{ color: "#c28e2b" }}>
              Welcome to JM Investments
            </h1>
            <div
              className="test-contacts-content-text-row"
              style={{ width: "90%", marginTop: "80px" }}
            >
              <p
                className="test-contacts-text"
                style={{
                  fontFamily: "Open-Sans, sans-serif",
                  color: "#efede7",
                  textAlign: "center",
                }}
              >
                Welcome to JM Investments, your gateway to the property market.
                property market where excellence and customer satisfaction are
                our top priority. We are a dedicated company, committed to
                providing the best investment for our clients.
              </p>
            </div>

            <div
              className="test-contacts-content-text-row"
              style={{ width: "90%", marginTop: "50px" }}
            >
              <p
                className="test-contacts-text"
                style={{
                  fontFamily: "Open-Sans, sans-serif",
                  color: "#efede7",
                  textAlign: "center",
                }}
              >
                We stand out for our dedicated and personalised service,
                ensuring continuous and transparent monitoring at every all
                stages of the property transaction. At JM Investments, we
                believe that each client is unique and deserves a personalised
                and approach, tailored to their specific needs and objectives.
                specific objectives.
              </p>
            </div>

            <div
              className="test-contacts-content-text-row"
              style={{ width: "90%", marginTop: "50px" }}
            >
              <p
                className="test-contacts-text"
                style={{
                  fontFamily: "Open-Sans, sans-serif",
                  color: "#efede7",
                  textAlign: "center",
                }}
              >
                In addition to our property brokerage service, we also we also
                offer credit brokerage services, providing the best the best
                financial solutions to meet the needs of needs.
              </p>
            </div>

            {!showMore && (
              <div
                className="test-contacts-content-text-row"
                style={{ width: "90%", marginTop: "50px" }}
              >
                <button
                  className="test-contacts-text"
                  style={{
                    fontFamily: "Open-Sans, sans-serif",
                    color: "#efede7",
                    textAlign: "center",
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowMore(true)}
                >
                  Read more...
                </button>
              </div>
            )}
            {showMore && (
              <>
                <h1
                  className="test-h1"
                  style={{ marginTop: "80px", fontWeight: "bold" }}
                >
                  CEO - Miguel Fonseca
                </h1>
                <div className="test-contacts-content-about-row">
                  <div className="test-contacts-content-about-text-row">
                    <p
                      className="test-contacts-text"
                      style={{
                        fontFamily: "Open-Sans, sans-serif",
                        color: "#efede7",
                        textAlign: "center",
                      }}
                    >
                      The CEO of JM Investments is Miguel Fonseca, an
                      experienced experienced professional, Chartered Accountant
                      and vast experience in the property business. After 7
                      years working in this dynamic sector, he decided a year
                      ago to realise his dream of starting his own company,
                      bringing with him a passion and passion and dedication
                      that set him apart in the market.
                    </p>
                    <p
                      className="test-contacts-text"
                      style={{
                        fontFamily: "Open-Sans, sans-serif",
                        color: "#efede7",
                        textAlign: "center",
                        marginTop: "50px",
                      }}
                    >
                      For Miguel, who played federated football for 18 years,
                      football was his school of life, where he learnt the the
                      importance of competition and the determination to achieve
                      goals. These qualities are now reflected in the philosophy
                      of JM Investments, where we are committed to exceeding our
                      clients' expectations and guaranteeing their success with
                      every investment.
                    </p>
                  </div>
                  <img src={ProfileImage} className="ceo-image" />
                </div>

                <div
                  className="test-contacts-content-text-row"
                  style={{ width: "90%", marginTop: "50px" }}
                >
                  <p
                    className="test-contacts-text"
                    style={{
                      fontFamily: "Open-Sans, sans-serif",
                      fontWeight: "bold",
                      color: "#efede7",
                      textAlign: "center",
                    }}
                  >
                    At JM Investments, your property dream is our priority.
                    We're here to make it a reality.
                  </p>
                </div>

                <button
                  className="test-contacts-text"
                  style={{
                    fontFamily: "Open-Sans, sans-serif",
                    color: "#efede7",
                    textAlign: "center",
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "50px",
                  }}
                  onClick={() => setShowMore(false)}
                >
                  Read less...
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
