import { useEffect, useState } from "react";
import "./Teste_Sobre_Nos.css";
import { Link, useLocation } from "react-router-dom";
import Portugal_flag from "../../assets/images/Portugal_flag.png";
import United_Kingdom_flag from "../../assets/images/United-Kingdom_flag.png";
import ProfileImage from "../../assets/images/JM_INVESTMENTS_image.jpg";
import { ReactComponent as ArrowDown } from "../../assets/images/Arrow_down.svg";
import { ReactComponent as ArrowDownYellow } from "../../assets/images/Arrow_down_active.svg";
import { ReactComponent as ArrowDownTransparent } from "../../assets/images/Arrow_down_transparent.svg";
import video from "../../assets/videos/home_video.mp4";

export default function TesteSobreNos() {
  const location = useLocation();
  const [buttonActive, setButtonActive] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 640) {
        setButtonActive(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    // <div
    //   className="app-container-sobre-nos"
    //   style={{ height: window.innerWidth <= 839 ? "" : "100%" }}
    // >
    <div className="app-container-sobre-nos">
      <div className="container-header">
        <div className="container-options">
          <Link
            to="/pt"
            className={`container-suboption ${
              location.pathname === "/pt" ? "active" : ""
            }`}
          >
            Início
          </Link>
          <Link
            to="/pt/sobre-nos"
            className={`container-suboption ${
              location.pathname === "/pt/sobre-nos" ? "active" : ""
            }`}
          >
            Sobre Nós
          </Link>
          <Link
            to="/pt/imoveis"
            className={`container-suboption ${
              location.pathname === "/pt/imoveis" ? "active" : ""
            }`}
          >
            Imóveis
          </Link>
          <Link
            to="/pt/contactos"
            className={`container-suboption ${
              location.pathname === "/teste-contactos" ? "active" : ""
            }`}
          >
            Contactos
          </Link>
          <div className="container-suboption">
            <div
              className={`container-suboption-flag ${
                dropdownOpen ? "active" : ""
              }`}
              onClick={handleDropdownToggle}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <img src={Portugal_flag} className="container-flag" alt="PT" />
              {isHovered || dropdownOpen ? (
                <ArrowDownYellow className="container-arrow" fill="#c28e2b" />
              ) : (
                <ArrowDown className="container-arrow" />
              )}
            </div>
            {dropdownOpen && (
              // <div className="container-dropdown-content">
              //   <Link to="/en" className="container-dropdown-item">
              //     <img
              //       src={United_Kingdom_flag}
              //       className="container-flag"
              //       alt="EN"
              //     />
              //   </Link>
              // </div>
              <div
                className="container-dropdown-content"
                // onClick={handleDropdownToggle}
              >
                <div className="container-suboption-flag">
                  <Link to="/en/about-us" className="container-dropdown-item">
                    <img
                      src={United_Kingdom_flag}
                      className="container-flag"
                      alt="EN"
                    />
                  </Link>
                  <ArrowDownTransparent className="container-arrow" />
                </div>
              </div>
            )}
          </div>
        </div>
        <button
          className={`container-menu ${buttonActive ? "active" : ""}`}
          onClick={() => setButtonActive(!buttonActive)}
        >
          {!buttonActive ? (
            <svg
              className="menu-icon"
              stroke="currentColor"
              fill="#ffffff"
              strokeWidth="0"
              viewBox="0 0 24 24"
              aria-hidden="true"
              height="3em"
              width="3em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="menu-line top"
                d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75z"
              ></path>
              <path
                className="menu-line middle"
                d="M3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12z"
              ></path>
              <path
                className="menu-line bottom"
                d="M3 17.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
              ></path>
            </svg>
          ) : (
            <svg
              className="menu-icon-active"
              stroke="#ffffff"
              fill="#ffffff"
              strokeWidth="0"
              viewBox="0 0 24 24"
              aria-hidden="true"
              height="3em"
              width="3em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke="#ffffff"
                stroke-width="1.5"
                d="M18.973 5.027L5.028 18.972m0-13.945l13.944 13.945"
              ></path>
            </svg>
          )}
        </button>
        <div className={`menu-container ${buttonActive ? "active" : ""}`}>
          <div className={`menu-subcontainer ${buttonActive ? "active" : ""}`}>
            <div className="menu-subcontainer-column">
              <Link
                to="/pt"
                className={`menu-subcontainer-option ${
                  location.pathname === "/pt" ? "active" : ""
                }`}
              >
                Início
              </Link>
              <Link
                to="/pt/sobre-nos"
                className={`menu-subcontainer-option ${
                  location.pathname === "/pt/sobre-nos" ? "active" : ""
                }`}
              >
                Sobre Nós
              </Link>
              <Link
                to="/pt/imoveis"
                className={`menu-subcontainer-option ${
                  location.pathname === "/pt/imoveis" ? "active" : ""
                }`}
              >
                Imóveis
              </Link>
              <Link
                to="/pt/contactos"
                className={`menu-subcontainer-option ${
                  location.pathname === "/pt/contactos" ? "active" : ""
                }`}
              >
                Contactos
              </Link>
              <Link
                to="/en/about-us"
                className={`menu-subcontainer-option ${
                  location.pathname === "/en/about-us" ? "active" : ""
                }`}
              >
                <div className="menu-container-flag">
                  <div className="menu-subcontainer-flag">
                    <img
                      src={United_Kingdom_flag}
                      alt="EN"
                      className="menu-subcontainer-flag-image"
                    />
                  </div>
                  <div>EN</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div
        className="test-sobre-nos-content"
        style={{
          paddingBottom: window.innerWidth >= 839 && !showMore ? "240px" : "",
        }}
      >
        <div className="test-sobre-nos-content-column">
          <div className="test-contacts-content-text">
            <h1 className="test-h1" style={{ color: "#c28e2b" }}>
              Bem-vindo à JM Investments
            </h1>
            <div
              className="test-contacts-content-text-row"
              style={{ width: "90%", marginTop: "80px" }}
            >
              <p
                className="test-contacts-text"
                style={{
                  fontFamily: "Open-Sans, sans-serif",
                  color: "#efede7",
                  textAlign: "center",
                }}
              >
                Bem-vindo à JM Investments, a sua porta de entrada para o
                mercado imobiliário onde a excelência e a satisfação do cliente
                são a nossa prioridade máxima. Somos uma empresa dedicada,
                comprometida em proporcionar o melhor investimento para os
                nossos clientes.
              </p>
            </div>

            <div
              className="test-contacts-content-text-row"
              style={{ width: "90%", marginTop: "50px" }}
            >
              <p
                className="test-contacts-text"
                style={{
                  fontFamily: "Open-Sans, sans-serif",
                  color: "#efede7",
                  textAlign: "center",
                }}
              >
                Destacamo-nos pelo nosso atendimento dedicado e personalizado,
                assegurando um acompanhamento contínuo e transparente em todas
                as etapas da transação imobiliária. Na JM Investments,
                acreditamos que cada cliente é único e merece uma abordagem
                personalizada e cuidadosa, adaptada às suas necessidades e
                objetivos específicos.
              </p>
            </div>

            <div
              className="test-contacts-content-text-row"
              style={{ width: "90%", marginTop: "50px" }}
            >
              <p
                className="test-contacts-text"
                style={{
                  fontFamily: "Open-Sans, sans-serif",
                  color: "#efede7",
                  textAlign: "center",
                }}
              >
                Para além do nosso serviço de intermediação imobiliária, também
                oferecemos o serviço de intermediação de crédito, proporcionando
                as melhores soluções financeiras adequadas às necessidades de
                cada cliente.
              </p>
            </div>

            {!showMore && (
              <div
                className="test-contacts-content-text-row"
                style={{ width: "90%", marginTop: "50px" }}
              >
                <button
                  className="test-contacts-text"
                  style={{
                    fontFamily: "Open-Sans, sans-serif",
                    color: "#efede7",
                    textAlign: "center",
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onClick={() => setShowMore(true)}
                >
                  Ler mais...
                </button>
              </div>
            )}
            {showMore && (
              <>
                <h1
                  className="test-h1"
                  style={{ marginTop: "80px", fontWeight: "bold" }}
                >
                  CEO - Miguel Fonseca
                </h1>
                <div className="test-contacts-content-about-row">
                  <div className="test-contacts-content-about-text-row">
                    <p
                      className="test-contacts-text"
                      style={{
                        fontFamily: "Open-Sans, sans-serif",
                        color: "#efede7",
                        textAlign: "center",
                      }}
                    >
                      Como CEO da JM Investments está Miguel Fonseca, um
                      profissional experiente, Contabilista Certificado e com
                      vasta experiência no ramo imobiliário. Depois de 7 anos a
                      trabalhar neste setor dinâmico, decidiu há um ano realizar
                      o sonho de abrir a sua própria empresa, trazendo consigo
                      uma paixão e dedicação que o distinguem no mercado.
                    </p>
                    <p
                      className="test-contacts-text"
                      style={{
                        fontFamily: "Open-Sans, sans-serif",
                        color: "#efede7",
                        textAlign: "center",
                        marginTop: "50px",
                      }}
                    >
                      Para o Miguel, que praticou futebol federado durante 18
                      anos, o futebol foi a sua escola de vida, onde aprendeu a
                      importância da competição e a determinação em alcançar
                      objetivos. Essas qualidades são agora refletidas na
                      filosofia da JM Investments, onde estamos empenhados em
                      superar as expectativas dos nossos clientes e em garantir
                      o seu sucesso em cada investimento.
                    </p>
                  </div>
                  <img src={ProfileImage} className="ceo-image" />
                </div>

                <div
                  className="test-contacts-content-text-row"
                  style={{ width: "90%", marginTop: "50px" }}
                >
                  <p
                    className="test-contacts-text"
                    style={{
                      fontFamily: "Open-Sans, sans-serif",
                      fontWeight: "bold",
                      color: "#efede7",
                      textAlign: "center",
                    }}
                  >
                    Na JM Investments, o seu sonho imobiliário é a nossa
                    prioridade. Estamos aqui para transformá-lo em realidade.
                  </p>
                </div>

                <button
                  className="test-contacts-text"
                  style={{
                    fontFamily: "Open-Sans, sans-serif",
                    color: "#efede7",
                    textAlign: "center",
                    backgroundColor: "transparent",
                    border: "none",
                    cursor: "pointer",
                    marginTop: "50px",
                  }}
                  onClick={() => setShowMore(false)}
                >
                  Ler menos...
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
