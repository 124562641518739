import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./CloseModal.css";
import ConfirmationPopup from "../../components/Popup/ConfirmationPopup/ConfimationPopup";
import { postRequestToken } from "../../api";

export default function CloseModalPT({ onCancel }) {
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [showModal, setShowModal] = useState(true);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (showPopup) {
      setTimeout(() => {
        window.location.href = "/pt";
        // localStorage.clear();
      }, 700);
    }
  }, [showPopup]);

  const handleLogout = async () => {
    setIsLoggingOut(true);
    try {
      //await validateInfoUser();
      await postRequestToken("/auth/logout", null, token);
      localStorage.clear();

      setIsLoggingOut(false);

      setShowModal(false);
      setPopupMessage("Logout efetuado com sucesso.");
      setShowPopup(true);
    } catch (error) {
      setPopupMessage(error.message);
      setShowPopup(true);
    }
  };

  return (
    <div className="modal-overlay" onClick={onCancel}>
      {showModal && (
        <div className="class-modal">
          <div className="export-row">
            <h2 className="export-title" style={{ fontSize: "20px" }}>
              Tem a certeza que pretende sair?
            </h2>
          </div>
          <div className="close-buttons">
            <button
              className="button-cancel"
              style={{ marginLeft: "0px" }}
              onClick={onCancel}
            >
              Cancelar
            </button>

            <button
              onClick={handleLogout}
              className={`close-button-confirm ${
                isLoggingOut ? "close-form--submit-loading" : ""
              }`}
              disabled={isLoggingOut}
            >
              {isLoggingOut ? "" : "Sair"}
            </button>
          </div>
        </div>
      )}
      {/* {showModal && (
        <ConfirmationLogoutModal
          showModal={showModal}
          title="Logout"
          description="Tem a certeza que pretende fazer logout?"
          sub_description="Esta ação é permanente e não pode recuperar a aula eliminada. Se houver alguma associação com um tipo de aula, esta vai ser removida."
          onConfirm={handleConfirmLogout}
          onCancel={handleCancelLogout}
        />
      )} */}
      {showPopup && (
        <ConfirmationPopup
          showPopup={showPopup}
          message="Logout realizado com sucesso!"
        />
      )}
    </div>
  );
}
