import { useEffect, useRef, useState } from "react";
import "./Teste_Imoveis.css";
import { Link, useLocation } from "react-router-dom";
import Portugal_flag from "../../assets/images/Portugal_flag.png";
import United_Kingdom_flag from "../../assets/images/United-Kingdom_flag.png";
import { ReactComponent as ArrowDown } from "../../assets/images/Arrow_down.svg";
import { ReactComponent as ArrowDownYellow } from "../../assets/images/Arrow_down_active.svg";
import { ReactComponent as ArrowDownTransparent } from "../../assets/images/Arrow_down_transparent.svg";
import video from "../../assets/videos/jm_investments.mp4";
import audioOn from "../../assets/images/audio_on.png";
import audioOff from "../../assets/images/audio_off.png";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from "../../config/firebaseConfig";
import { getRequest } from "../../api";
import noImageIcon from "../../assets/images/no_image.png";
import Loading from "../../components/Loading/Loading";
import { useSwipeable } from "react-swipeable";

const imagesRef = ref(storage, "images/properties/photos/");

export default function TesteImoveisEN() {
  const videoRef = useRef(null);
  const location = useLocation();
  const [buttonActive, setButtonActive] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  // const [properties, setProperties] = useState([]);
  const [properties, setProperties] = useState([]);
  const [isMuted, setIsMuted] = useState(true);
  // const [highlightedProperties, setHighlightedProperties] = useState([]);
  const [highlightedProperties, setHighlightedProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const [imageList, setImageList] = useState([]);
  const [imageList, setImageList] = useState([]);
  // const [highlightImageList, setHighlightImageList] = useState([]);
  const [highlightImageList, setHighlightImageList] = useState([]);
  const [imageIndices, setImageIndices] = useState({});
  const [counter, setCounter] = useState(0);
  const [currentPropertyIndex, setCurrentPropertyIndex] = useState(0);
  const [selectedProperty, setSelectedProperty] = useState(
    highlightedProperties[0]
  );
  const parallaxRef = useRef(null);
  const [intervalId, setIntervalId] = useState(null);
  const [activeDot, setActiveDot] = useState(1);
  const [activeDotPromotion, setActiveDotPromotion] = useState(1);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Adicione um event listener para atualizar o estado quando a largura da janela mudar
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Limpe o event listener quando o componente for desmontado
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
    videoRef.current.muted = !videoRef.current.muted;
  };

  const handleChangeProperty = (index) => {
    clearInterval(intervalId);
    setCurrentPropertyIndex(index);
    setSelectedProperty(highlightedProperties[index]);
    startCarousel();
  };

  useEffect(() => {
    const fetchProperties = async () => {
      const res = await getRequest("/properties", null);
      console.log(res);
      setProperties(res);
      const highlightProperties = res.filter((prop) => prop.highlight);
      setHighlightedProperties(highlightProperties);
      const firstProperty = highlightProperties[0];
      setSelectedProperty(firstProperty);

      const resImages = await listAll(imagesRef);
      const urls = await Promise.all(
        Object.values(resImages.items)
          .filter((itemRef) => itemRef.name.includes(`_0`))
          .map(async (itemRef) => {
            const url = await getDownloadURL(itemRef);
            return new Promise((resolve, reject) => {
              const img = new Image();
              img.onload = () => resolve(url);
              img.onerror = reject;
              img.src = url;
            });
          })
      );

      const highlightedUrls = urls.filter((url) => {
        return highlightProperties.some((prop) =>
          url.includes(`property_${prop.property_id}_0`)
        );
      });

      setImageList(urls);
      setHighlightImageList(highlightedUrls);

      setIsLoading(false);
    };
    fetchProperties();
  }, []);

  // console.log(highlightedProperties);
  // console.log(imageList);
  // console.log(highlightImageList);

  const propertiesGridCode = (property, type, propertiesGrid) => {
    return (
      // <Link
      //   key={property.property_id}
      //   to={`${encodeURIComponent(property.name)}`}
      //   className="test-properties-home-row-property-card-link"
      // >
      //   <div className="real-estate-home-row-images">
      //     <div className="real-estate-column-images">
      //       <div className="real-estate-image-wrapper">
      //         {(property.salestate === "Reservado" ||
      //           property.salestate === "Vendido") && (
      //           <div>
      //             <div
      //               className={`reserved-banner ${
      //                 property.salestate === "Reservado"
      //                   ? "reserved"
      //                   : property.salestate === "Vendido"
      //                   ? "sold"
      //                   : ""
      //               }`}
      //             >
      //               {property.salestate === "Reservado"
      //                 ? "Reservado"
      //                 : "Vendido"}
      //             </div>
      //             <div
      //               className={`banner-triangle ${
      //                 property.salestate === "Reservado"
      //                   ? "reserved"
      //                   : property.salestate === "Vendido"
      //                   ? "sold"
      //                   : ""
      //               }`}
      //             ></div>
      //           </div>
      //         )}

      //         {imageList.length > 0 &&
      //           (imageList.find((url) =>
      //             url.includes(`property_${property.property_id}_0`)
      //           ) ? (
      //             <div className="image-container">
      //               <img
      //                 src={imageList.find((url) =>
      //                   url.includes(`property_${property.property_id}_0`)
      //                 )}
      //                 className="real-estate-image"
      //               />
      //             </div>
      //           ) : (
      //             <div className="no-image-container-general">
      //               <img className="no-image-icon-general" src={noImageIcon} />
      //             </div>
      //           ))}
      //       </div>
      //       <p className="real-state-text-location">{property.name}</p>
      //       <p className="real-state-text-location">
      //         {property.freguesia_name}, {property.municipio_name}
      //       </p>
      //       <p className="real-state-text-type">{property.propertytype_name}</p>
      //       <p className="real-state-text-price">
      //         {property.promotionprice ? (
      //           <span>
      //             from{" "}
      //             <span className="test-old-price">
      //               {formatNumber(property.price)}€
      //             </span>{" "}
      //             {formatNumber(property.promotionprice)}€
      //           </span>
      //         ) : (
      //           <>
      //             from{" "}
      //             <span className="test-normal-price">
      //               {formatNumber(property.price)} €
      //             </span>
      //           </>
      //         )}
      //       </p>
      //     </div>
      //   </div>
      // </Link>
      <Link
        to={`${encodeURIComponent(property.name)}`}
        className={`test-properties-home-row-property-card-link-recent ${
          propertiesGrid.length === 2 ? "two-elements" : ""
        }`}
      >
        <div className="test-properties-home-real-estate-card-column">
          {(property.salestate === "Reservado" ||
            property.salestate === "Vendido") && (
            <>
              <div
                className={`test-reserved-banner ${
                  property.salestate === "Reservado" ? "reserved" : "sold"
                }`}
              >
                {property.salestate === "Reservado" ? "Reserved" : "Sold"}
              </div>
              <div
                className={`test-banner-triangle ${
                  property.salestate === "Reservado" ? "reserved" : "sold"
                }`}
              ></div>
            </>
          )}

          {imageList.find((url) =>
            url.includes(`property_${property.property_id}_0`)
          ) ? (
            <img
              className="test-properties-home-carousel-image"
              style={{ borderRadius: " 10px 10px 0px 0px" }}
              src={imageList.find((url) =>
                url.includes(`property_${property.property_id}_0`)
              )}
            />
          ) : (
            <div className="test-properties-home-no-image-container-general-recent">
              <img className="no-image-icon-general" src={noImageIcon} />
            </div>
          )}
          <div className="test-real-estate-content-row-recent">
            <div className="test-property-column">
              <p
                className="test-real-state-text-name-row"
                style={{ marginTop: "0px" }}
              >
                {property.propertytype_name === "Moradia"
                  ? "House"
                  : property.propertytype_name === "Apartamento"
                  ? "Apartment"
                  : property.propertytype_name === "Quinta"
                  ? "Farm"
                  : property.propertytype_name === "Terreno"
                  ? "Land"
                  : ""}{" "}
                {property.room}
              </p>
              <p
                className="test-real-state-text-location-row"
                style={{ marginTop: "0px" }}
              >
                {truncateText(
                  `${property.municipio_name} > ${property.freguesia_name}`,
                  100,
                  windowWidth
                )}
                {/* {truncateText(
                            `União das freguesias de Alandroal (Nossa Senhora da Conceição), São Brás dos Matos (Mina do Bugalho) e Juromenha (Nossa Senhora do Loreto) > Figueira de Castelo Rodrigo`,
                            100,
                            window.innerWidth
                          )} */}
              </p>
            </div>

            <p className="test-real-state-text-price-row">
              {property.promotionprice ? (
                <span>
                  of{" "}
                  <span className="test-old-price">
                    {formatNumber(property.price)}€
                  </span>{" "}
                  {formatNumber(property.promotionprice)}€
                </span>
              ) : (
                <>
                  of{" "}
                  <span className="test-normal-price">
                    {formatNumber(property.price)} €
                  </span>
                </>
              )}
            </p>
          </div>
        </div>
      </Link>
    );
  };

  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  useEffect(() => {
    const handleScroll = () => {
      if (parallaxRef.current) {
        const scrollPosition = window.scrollY;
        const parallaxSpeed = 0.3;
        parallaxRef.current.style.backgroundPositionY = `${
          -scrollPosition * parallaxSpeed
        }px`;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const startCarousel = () => {
    const newIntervalId = setInterval(() => {
      setCurrentPropertyIndex((currentIdx) => {
        const nextIndex = (currentIdx + 1) % highlightedProperties.length;
        setSelectedProperty(highlightedProperties[nextIndex]);
        return nextIndex;
      });
    }, 5000);

    // Limpa o intervalo anterior
    clearInterval(intervalId);

    // Define o novo intervalo
    setIntervalId(newIntervalId);
  };

  useEffect(() => {
    startCarousel();
    return () => clearInterval(intervalId);
  }, [highlightedProperties]);

  const truncateText = (text, maxLength, width) => {
    // console.log(width);
    if (
      (width >= 0 && width <= 768) ||
      (width >= 768 && width <= 840) ||
      (width >= 1024 && width <= 1062)
    ) {
      console.log("Aqui");
      console.log(text.length);
      console.log(maxLength);
      if (text.length <= maxLength) {
        return text;
      }
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const handlers = useSwipeable({
    onSwipedLeft: () =>
      handleChangeProperty(
        (currentPropertyIndex + 1) % highlightedProperties.length
      ),
    onSwipedRight: () =>
      handleChangeProperty(
        (currentPropertyIndex - 1 + highlightedProperties.length) %
          highlightedProperties.length
      ),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  // const useHandlersRecents = (type) => {
  //   const handlersRecents = useSwipeable({
  //     onSwipedLeft: () => setActiveDot(getNextDot(1, type)),
  //     onSwipedRight: () => setActiveDot(getNextDot(-1, type)),
  //     preventDefaultTouchmoveEvent: true,
  //     trackMouse: true,
  //   });

  //   return handlersRecents;
  // };

  const handlersRecents = useSwipeable({
    onSwipedLeft: () => setActiveDot(getNextDot(1)),
    onSwipedRight: () => setActiveDot(getNextDot(-1)),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const getNextDot = (direction) => {
    let propertiesToUse = properties;
    // if (type === "promotion") {
    //   propertiesToUse = properties.filter(
    //     (property) => property.promotionprice !== null
    //   );
    // }

    const numberOfDots =
      windowWidth >= 530 && windowWidth < 768
        ? Math.min(2, propertiesToUse.length)
        : Math.min(3, propertiesToUse.length);

    if (numberOfDots <= 1) return 1;

    let newDot = activeDot + direction;

    if (newDot > numberOfDots) {
      newDot = 1;
    } else if (newDot < 1) {
      newDot = numberOfDots;
    }

    return newDot;
  };

  const handlersPromotions = useSwipeable({
    onSwipedLeft: () => setActiveDotPromotion(getNextDotPromotion(1)),
    onSwipedRight: () => setActiveDotPromotion(getNextDotPromotion(-1)),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const getNextDotPromotion = (direction) => {
    let propertiesToUse = properties.filter(
      (property) => property.promotionprice !== null
    );

    const numberOfDots =
      windowWidth >= 530 && windowWidth < 768
        ? Math.min(2, propertiesToUse.length)
        : Math.min(3, propertiesToUse.length);

    if (numberOfDots <= 1) return 1;

    let newDot = activeDotPromotion + direction;

    if (newDot > numberOfDots) {
      newDot = 1;
    } else if (newDot < 1) {
      newDot = numberOfDots;
    }

    return newDot;
  };

  // Lógica para mostrar os dots
  const renderDots = (type) => {
    if (windowWidth < 530) {
      if (type != "promotion") {
        if (properties.length > 2) {
          return (
            <div className="test-carousel-dots">
              {[1, 2, 3].map((dot) => (
                <div
                  key={dot}
                  className={`test-carousel-dot ${
                    dot === activeDot ? "active" : ""
                  }`}
                  onClick={() => setActiveDot(dot)}
                />
              ))}
            </div>
          );
        } else if (properties.length === 2) {
          return (
            <div className="test-carousel-dots">
              {[1, 2].map((dot) => (
                <div
                  key={dot}
                  className={`test-carousel-dot ${
                    dot === activeDot ? "active" : ""
                  }`}
                  onClick={() => setActiveDot(dot)}
                />
              ))}
            </div>
          );
        }
      } else {
        var propertiesFilter = properties.filter(
          (property) => type !== "promotion" || property.promotionprice !== null
        );

        if (propertiesFilter.length > 2) {
          return (
            <div className="test-carousel-dots">
              {[1, 2, 3].map((dot) => (
                <div
                  key={dot}
                  className={`test-carousel-dot ${
                    dot === activeDotPromotion ? "active" : ""
                  }`}
                  onClick={() => setActiveDotPromotion(dot)}
                />
              ))}
            </div>
          );
        } else if (propertiesFilter.length === 2) {
          return (
            <div className="test-carousel-dots">
              {[1, 2].map((dot) => (
                <div
                  key={dot}
                  className={`test-carousel-dot ${
                    dot === activeDotPromotion ? "active" : ""
                  }`}
                  onClick={() => setActiveDotPromotion(dot)}
                />
              ))}
            </div>
          );
        }
      }
    } else if (
      properties.length > 2 &&
      windowWidth >= 530 &&
      windowWidth < 768
    ) {
      if (type != "promotion") {
        return (
          <div className="test-carousel-dots">
            {[1, 2].map((dot) => (
              <div
                key={dot}
                className={`test-carousel-dot ${
                  dot === activeDot ? "active" : ""
                }`}
                onClick={() => setActiveDot(dot)}
              />
            ))}
          </div>
        );
      } else {
        return (
          <div className="test-carousel-dots">
            {[1, 2].map((dot) => (
              <div
                key={dot}
                className={`test-carousel-dot ${
                  dot === activeDotPromotion ? "active" : ""
                }`}
                onClick={() => setActiveDotPromotion(dot)}
              />
            ))}
          </div>
        );
      }
    }
  };

  // Lógica para mostrar os elementos visíveis
  const renderVisibleElements = (type) => {
    console.log(
      properties
        .sort((a, b) => new Date(b.insertion_date) - new Date(a.insertion_date))
        .slice(0, 3)
    );

    if (windowWidth < 530) {
      const elementsToShow = 1; // Sempre mostrar 1 imóvel
      var startIndex;
      if (type != "promotion") {
        startIndex = activeDot === 1 ? 0 : activeDot === 2 ? 1 : 2; // Índice inicial para o slice
      } else {
        startIndex =
          activeDotPromotion === 1 ? 0 : activeDotPromotion === 2 ? 1 : 2;
      }

      return (
        <div className="test-properties-home-carousel-container">
          <div className="test-properties-home-carousel-row">
            {properties
              .sort(
                (a, b) =>
                  new Date(b.insertion_date) - new Date(a.insertion_date)
              )
              .filter(
                (property) =>
                  type !== "promotion" || property.promotionprice !== null
              )
              .slice(startIndex, startIndex + elementsToShow)
              .map((property) => {
                return propertiesGridCode(
                  property,
                  type,
                  properties.filter(
                    (property) =>
                      type !== "promotion" || property.promotionprice !== null
                  )
                );
              })}
          </div>
          {renderDots(type)}
        </div>
      );
    } else if (windowWidth >= 530 && windowWidth < 768) {
      var elementsToShow = activeDot === 1 ? 2 : 1; // Número de elementos a mostrar
      var startIndex = activeDot === 1 ? 0 : 2; // Índice inicial para o slice
      var startIndex;
      if (type != "promotion") {
        elementsToShow = activeDot === 1 ? 2 : 1;
        var startIndex = activeDot === 1 ? 0 : 2;
      } else {
        elementsToShow = activeDotPromotion === 1 ? 2 : 1;
        var startIndex = activeDotPromotion === 1 ? 0 : 2;
      }
      return (
        <div className="test-properties-home-carousel-container">
          <div className="test-properties-home-carousel-row">
            {properties
              .sort(
                (a, b) =>
                  new Date(b.insertion_date) - new Date(a.insertion_date)
              )
              .filter(
                (property) =>
                  type !== "promotion" || property.promotionprice !== null
              )
              .slice(startIndex, startIndex + elementsToShow)
              .map((property) => {
                return propertiesGridCode(
                  property,
                  type,
                  properties.filter(
                    (property) =>
                      type !== "promotion" || property.promotionprice !== null
                  )
                );
              })}
          </div>
          {renderDots(type)}
        </div>
      );
    } else {
      // Mostrar 3 elementos em telas maiores
      return (
        <div className="test-properties-home-carousel-container">
          <div className="test-properties-home-carousel-row">
            {properties
              .sort(
                (a, b) =>
                  new Date(b.insertion_date) - new Date(a.insertion_date)
              )
              .filter(
                (property) =>
                  type !== "promotion" || property.promotionprice !== null
              )
              .slice(0, 3)
              .map((property) => {
                return propertiesGridCode(
                  property,
                  type,
                  properties.filter(
                    (property) =>
                      type !== "promotion" || property.promotionprice !== null
                  )
                );
              })}
          </div>
        </div>
      );
    }
  };

  // const recentPropertiesHandlers = useHandlersRecents("recent");
  // const promotionPropertiesHandlers = useHandlersRecents("promotion");

  return (
    <div className="app-container-imoveis">
      <div className="container-header">
        <div className="container-options">
          <Link
            to="/en"
            className={`container-suboption ${
              location.pathname === "/en" ? "active" : ""
            }`}
          >
            Home
          </Link>
          <Link
            to="/en/about-us"
            className={`container-suboption ${
              location.pathname === "/en/about-us" ? "active" : ""
            }`}
          >
            About Us
          </Link>
          <Link
            to="/en/real-estate"
            className={`container-suboption ${
              location.pathname === "/en/real-estate" ? "active" : ""
            }`}
          >
            Real Estate
          </Link>
          <Link
            to="/en/contacts"
            className={`container-suboption ${
              location.pathname === "/en/contacts" ? "active" : ""
            }`}
          >
            Contacts
          </Link>
          <div className="container-suboption">
            <div
              className={`container-suboption-flag ${
                dropdownOpen ? "active" : ""
              }`}
              onClick={handleDropdownToggle}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <img
                src={United_Kingdom_flag}
                className="container-flag"
                alt="EN"
              />
              {isHovered || dropdownOpen ? (
                <ArrowDownYellow className="container-arrow" fill="#c28e2b" />
              ) : (
                <ArrowDown className="container-arrow" />
              )}
            </div>
            {dropdownOpen && (
              // <div className="container-dropdown-content">
              //   <Link to="/en" className="container-dropdown-item">
              //     <img
              //       src={United_Kingdom_flag}
              //       className="container-flag"
              //       alt="EN"
              //     />
              //   </Link>
              // </div>
              <div
                className="container-dropdown-content"
                // onClick={handleDropdownToggle}
              >
                <div className="container-suboption-flag">
                  <Link to="/pt/imoveis" className="container-dropdown-item">
                    <img
                      src={Portugal_flag}
                      className="container-flag"
                      alt="PT"
                    />
                  </Link>
                  <ArrowDownTransparent className="container-arrow" />
                </div>
              </div>
            )}
          </div>
        </div>
        <button
          className={`container-menu ${buttonActive ? "active" : ""}`}
          onClick={() => setButtonActive(!buttonActive)}
        >
          {!buttonActive ? (
            <svg
              className="menu-icon"
              stroke="currentColor"
              fill="#ffffff"
              strokeWidth="0"
              viewBox="0 0 24 24"
              aria-hidden="true"
              height="3em"
              width="3em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="menu-line top"
                d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75z"
              ></path>
              <path
                className="menu-line middle"
                d="M3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12z"
              ></path>
              <path
                className="menu-line bottom"
                d="M3 17.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
              ></path>
            </svg>
          ) : (
            <svg
              className="menu-icon-active"
              stroke="#ffffff"
              fill="#ffffff"
              strokeWidth="0"
              viewBox="0 0 24 24"
              aria-hidden="true"
              height="3em"
              width="3em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke="#ffffff"
                stroke-width="1.5"
                d="M18.973 5.027L5.028 18.972m0-13.945l13.944 13.945"
              ></path>
            </svg>
          )}
        </button>
        <div className={`menu-container ${buttonActive ? "active" : ""}`}>
          <div className={`menu-subcontainer ${buttonActive ? "active" : ""}`}>
            <div className="menu-subcontainer-column">
              <Link
                to="/en"
                className={`menu-subcontainer-option ${
                  location.pathname === "/en" ? "active" : ""
                }`}
              >
                Home
              </Link>
              <Link
                to="/en/about-us"
                className={`menu-subcontainer-option ${
                  location.pathname === "/en/about-us" ? "active" : ""
                }`}
              >
                About Us
              </Link>
              <Link
                to="/en/real-estate"
                className={`menu-subcontainer-option ${
                  location.pathname === "/en/real-estate" ? "active" : ""
                }`}
              >
                Real Estate
              </Link>
              <Link
                to="/en/contacts"
                className={`menu-subcontainer-option ${
                  location.pathname === "/en/contacts" ? "active" : ""
                }`}
              >
                Contacts
              </Link>
              <Link
                to="/pt/imoveis"
                className={`menu-subcontainer-option ${
                  location.pathname === "/pt" ? "active" : ""
                }`}
              >
                <div className="menu-container-flag">
                  <div className="menu-subcontainer-flag">
                    <img
                      src={Portugal_flag}
                      alt="PT"
                      className="menu-subcontainer-flag-image"
                    />
                  </div>
                  <div>PT</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`test-video-container ${
          properties.length === 0 ? "no-properties" : ""
        }`}
      >
        <video
          className={`test-real-estate-home-video ${
            properties.length === 0 ? "no-properties" : ""
          }`}
          ref={videoRef}
          src={video}
          autoPlay
          loop
          muted={isMuted}
          playsInline
        />
        {/* <video
          className="background-video"
          autoPlay
          loop
          muted
          playsInline
          src={video}
        ></video> */}
        <div
          className={`test-video-column ${
            properties.length === 0 ? "no-properties" : ""
          }`}
        >
          <div
            className={`test-video-text ${
              properties.length === 0 ? "no-properties" : ""
            }`}
          >
            At{" "}
            <span style={{ color: "#c28e2b", fontWeight: "bold" }}>
              JM INVESTMENTS
            </span>{" "}
            we offer a service of excellence adapted to the needs of each client
          </div>
          <div
            className={`test-real-estate-home-buttons-row ${
              !properties.filter((property) => property.promotionprice !== null)
                .length > 0
                ? "no-properties"
                : ""
            }`}
          >
            <Link
              to="/en/buy-real-estate"
              className="test-real-estate-home-button"
            >
              Buy
            </Link>
            {/* <button className="real-estate-home-button">Vender</button> */}
          </div>
        </div>

        <button onClick={toggleMute} className="test-mute-button">
          <img src={isMuted ? audioOff : audioOn} className="test-mute-icon" />
        </button>
      </div>

      {properties.length > 0 && highlightedProperties.length > 0 && (
        <div
          className="test-real-estate-home-row"
          ref={parallaxRef}
          {...handlers}
        >
          <div className="test-real-estate-home-properties-carousel">
            <div className="test-real-estate-home-title-highlight-container">
              <div
                className="test-real-estate-home-title"
                style={{ width: "100%" }}
              >
                Featured properties
              </div>
            </div>
            {isLoading ? (
              <Loading />
            ) : properties.length > 0 ? (
              <div className="test-properties-home-carousel-container">
                <Link
                  to={`${encodeURIComponent(selectedProperty.name)}`}
                  className="test-properties-home-row-property-card-link"
                >
                  <div className="test-properties-home-real-estate-card-row">
                    {(selectedProperty.salestate === "Reservado" ||
                      selectedProperty.salestate === "Vendido") && (
                      <>
                        <div
                          className={`test-reserved-banner ${
                            selectedProperty.salestate === "Reservado"
                              ? "reserved"
                              : "sold"
                          }`}
                        >
                          {selectedProperty.salestate === "Reservado"
                            ? "Reserved"
                            : "Sold"}
                        </div>
                        <div
                          className={`test-banner-triangle ${
                            selectedProperty.salestate === "Reservado"
                              ? "reserved"
                              : "sold"
                          }`}
                        ></div>
                      </>
                    )}
                    {console.log(`property_${selectedProperty.property_id}_0`)}
                    {console.log(
                      highlightImageList.find((url) =>
                        url.includes(
                          `property_${selectedProperty.property_id}_0`
                        )
                      )
                    )}
                    {highlightImageList.find((url) =>
                      url.includes(`property_${selectedProperty.property_id}_0`)
                    ) ? (
                      <img
                        className="test-properties-home-carousel-image-highlight"
                        src={highlightImageList.find((url) =>
                          url.includes(
                            `property_${selectedProperty.property_id}_0`
                          )
                        )}
                      />
                    ) : (
                      <div className="test-properties-home-no-image-container-general">
                        <img
                          className="no-image-icon-general"
                          src={noImageIcon}
                        />
                      </div>
                    )}
                    <div className="test-real-estate-content-row">
                      <div className="test-property-column">
                        <p
                          className="test-real-state-text-name-row"
                          style={{ marginTop: "0px" }}
                        >
                          {selectedProperty.propertytype_name === "Moradia"
                            ? "House"
                            : selectedProperty.propertytype_name ===
                              "Apartamento"
                            ? "Apartment"
                            : selectedProperty.propertytype_name === "Quinta"
                            ? "Farm"
                            : selectedProperty.propertytype_name === "Terreno"
                            ? "Land"
                            : ""}{" "}
                          {selectedProperty.room}
                        </p>
                        <p
                          className="test-real-state-text-location-row"
                          style={{ marginTop: "0px" }}
                        >
                          {truncateText(
                            `${selectedProperty.municipio_name} > ${selectedProperty.freguesia_name}`,
                            100,
                            windowWidth
                          )}
                          {/* {truncateText(
                            `União das freguesias de Alandroal (Nossa Senhora da Conceição), São Brás dos Matos (Mina do Bugalho) e Juromenha (Nossa Senhora do Loreto) > Figueira de Castelo Rodrigo`,
                            100,
                            window.innerWidth
                          )} */}
                        </p>
                      </div>

                      <p className="test-real-state-text-price-row">
                        {selectedProperty.promotionprice ? (
                          <span>
                            of{" "}
                            <span className="test-old-price">
                              {formatNumber(selectedProperty.price)}€
                            </span>{" "}
                            {formatNumber(selectedProperty.promotionprice)}€
                          </span>
                        ) : (
                          <>
                            of{" "}
                            <span className="test-normal-price">
                              {formatNumber(selectedProperty.price)} €
                            </span>
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                </Link>
                {highlightedProperties.length > 1 && (
                  <div className="test-carousel-dots">
                    {highlightedProperties.map((_, index) => (
                      <span
                        key={index}
                        className={`test-carousel-dot ${
                          index === currentPropertyIndex ? "active" : ""
                        }`}
                        onClick={() => handleChangeProperty(index)}
                      />
                    ))}
                  </div>
                )}
              </div>
            ) : (
              <div>There are no properties</div>
            )}
          </div>
        </div>
      )}

      {properties.length > 0 && (
        <div
          className="test-real-estate-home-properties-carousel"
          {...handlersRecents}
        >
          <div className="test-real-estate-home-title-container">
            <div className="test-real-estate-home-title">Latest properties</div>
            <Link
              to={"/en/buy-real-estate"}
              className="real-estate-home-see-more"
            >
              See all
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                className="icon"
                viewBox="0 0 18 14"
                width="16px"
                height="12px"
                fill="#ffffff"
                style={{ marginLeft: "10px" }}
              >
                <path
                  d="M10.155 12.195a.75.75 0 0 0 1.133.977l5.946-5.946.073-.084a.75.75 0 0 0-.073-.977L11.288.219l-.084-.073a.75.75 0 0 0-.977.073l-.073.084a.75.75 0 0 0 .073.977l4.666 4.666H.75l-.102.007A.75.75 0 0 0 .75 7.446h14.142l-4.664 4.665-.073.084z"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </Link>
          </div>
          {isLoading ? <Loading /> : <>{renderVisibleElements("recents")}</>}
        </div>
      )}

      {properties.filter((property) => property.promotionprice !== null)
        .length > 0 && (
        <div
          className="test-real-estate-home-properties-carousel"
          {...handlersPromotions}
        >
          <div className="test-real-estate-home-title-container">
            <div className="test-real-estate-home-title">
              Properties on sale
            </div>
            <Link
              to={"/en/buy-real-estate"}
              className="real-estate-home-see-more"
            >
              See all
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                className="icon"
                viewBox="0 0 18 14"
                width="16px"
                height="12px"
                fill="#ffffff"
                style={{ marginLeft: "10px" }}
              >
                <path
                  d="M10.155 12.195a.75.75 0 0 0 1.133.977l5.946-5.946.073-.084a.75.75 0 0 0-.073-.977L11.288.219l-.084-.073a.75.75 0 0 0-.977.073l-.073.084a.75.75 0 0 0 .073.977l4.666 4.666H.75l-.102.007A.75.75 0 0 0 .75 7.446h14.142l-4.664 4.665-.073.084z"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </Link>
          </div>
          {isLoading ? <Loading /> : <>{renderVisibleElements("promotion")}</>}
        </div>
      )}

      {/* {properties.length > 0 && (
        <div className="real-estate-home-row-container">
          <div className="real-estate-home-title-container">
            <div className="real-estate-home-title">Imóveis mais recentes</div>
            {/* TODO mandar com o parâmetro de mais recentes
            <Link
              to={"/pt/compra-imoveis"}
              className="real-estate-home-see-more"
            >
              Ver todos
              <svg
                xmlns="http://www.w3.org/2000/svg"
                version="1.1"
                class="icon"
                viewBox="0 0 18 14"
                width="16px"
                height="12px"
                fill="#ffffff"
                style={{ marginLeft: "10px" }}
              >
                <path
                  d="M10.155 12.195a.75.75 0 0 0 1.133.977l5.946-5.946.073-.084a.75.75 0 0 0-.073-.977L11.288.219l-.084-.073a.75.75 0 0 0-.977.073l-.073.084a.75.75 0 0 0 .073.977l4.666 4.666H.75l-.102.007A.75.75 0 0 0 .75 7.446h14.142l-4.664 4.665-.073.084z"
                  fill-rule="evenodd"
                ></path>
              </svg>
            </Link>
          </div>
          {isLoading ? (
            <Loading />
          ) : (
            <div className="real-estate-home-properties-row">
              {properties.length > 0 ? (
                properties
                  .sort(
                    (a, b) =>
                      new Date(b.insertion_date) - new Date(a.insertion_date)
                  )
                  .slice(0, 3)
                  .map((property) => {
                    return propertiesGridCode(property);
                  })
              ) : (
                <div>Não existem imóveis</div>
              )}
            </div>
          )}
        </div>
      )} */}

      {/* {properties.length > 0 &&
        properties.filter((property) => property.promotionprice !== null)
          .length > 0 && (
          <div className="real-estate-home-row-container">
            <div className="real-estate-home-title-container">
              <div className="real-estate-home-title">Imóveis em promoção</div>
              <Link
                to={"/pt/compra-imoveis"}
                className="real-estate-home-see-more"
              >
                Ver todos
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  version="1.1"
                  className="icon"
                  viewBox="0 0 18 14"
                  width="16px"
                  height="12px"
                  fill="#ffffff"
                  style={{ marginLeft: "10px" }}
                >
                  <path
                    d="M10.155 12.195a.75.75 0 0 0 1.133.977l5.946-5.946.073-.084a.75.75 0 0 0-.073-.977L11.288.219l-.084-.073a.75.75 0 0 0-.977.073l-.073.084a.75.75 0 0 0 .073.977l4.666 4.666H.75l-.102.007A.75.75 0 0 0 .75 7.446h14.142l-4.664 4.665-.073.084z"
                    fillRule="evenodd"
                  ></path>
                </svg>
              </Link>
            </div>
            {isLoading ? (
              <Loading />
            ) : (
              <div className="real-estate-home-properties-row">
                {properties
                  .filter((property) => property.promotionprice !== null)
                  .slice(0, 5)
                  .map((property) => {
                    return propertiesGridCode(property);
                  })}
              </div>
            )}
          </div>
        )} */}

      {/* <div className="content"> */}
      {/* <div className="test-contacts-container"> */}
      {/* <div className="test-contacts-content">
          <div className="test-contacts-square">
            <img src={image} className="contacts-square-image"></img>
          </div>
          <div className="test-contacts-content-text">
            <h1 className="test-h1">
              HORÁRIO DE <br />
              FUNCIONAMENTO
            </h1>
            <div className="test-contacts-content-text-row">
              <p className="test-contacts-text">SEGUNDA A SEXTA</p>
              <p className="test-contacts-sub-text">09h às 20h</p>
            </div>

            <div className="test-contacts-content-text-row">
              <p className="test-contacts-text">SÁBADO</p>
              <p className="test-contacts-sub-text">09h às 12h</p>
            </div>
          </div>
          <div class="test-social-media-bar">
            <div className="social-media-buttons">
              <a href="mailto:geral@jminvestments.pt">
                <img src={mail} className="test-mediabar-icon" />
              </a>
              <ReactWhatsapp
                className="whatsapp-button"
                number="933044663"
                message="Olá!"
              >
                <img src={whatsapp} className="test-mediabar-icon" />
              </ReactWhatsapp>
              <Link
                to="https://www.linkedin.com/in/jorge-miguel-fonseca/"
                target="_blank"
              >
                <img src={linkedin} className="test-mediabar-icon" />
              </Link>
              <Link
                to="https://www.facebook.com/share/7Zk8SRamQpm4r7wk/"
                target="_blank"
              >
                <img src={facebook} className="test-mediabar-icon" />
              </Link>
              <Link
                to="https://www.instagram.com/jm__investments?igsh=NHJ5Y3JzbTRodW0z"
                target="_blank"
              >
                <img src={instagram} className="test-mediabar-icon" />
              </Link>
            </div>
          </div>
        </div> */}
      {/* </div> */}
      {/* </div> */}
    </div>
  );
}
