import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import PageNotFoundIcon from "../../assets/images/page_not_found.png";
import SocialMediaBarPT from "../../components/SocialMediaBar/SocialMediaBar_PT";
import instagram from "../../assets/images/instagram.png";
import facebook from "../../assets/images/facebook.png";
import linkedin from "../../assets/images/linkedin.png";
import whatsapp from "../../assets/images/whatsapp.png";
import mail from "../../assets/images/mail.png";
import ReactWhatsapp from "react-whatsapp";

export default function SoonPageEN() {
  return (
    <div className="app">
      <div className="page-not-found-container">
        <div className="clock-container">
          <div className="clock" />
        </div>
        <h1 className="soon-title">WE'LL BE BACK SOON!</h1>
        <p className="soon-text">
          We are redesigning our website with the aim of improving your
          experience
        </p>
        <p className="soon-sub-text-en">
          You can contact us at:{" "}
          <span>
            <a href="mailto:geral@jminvestments.pt" className="soon-link">
              geral@jminvestments.pt
            </a>
          </span>
          {""} or <span className="soon-link">+351 965 043 170 </span>/
          <span className="soon-link"> +351 916 433 843 </span>
        </p>
        {/* Obrigado por ser paciente. Por
          enquanto pode entrar em contacto connosco através de{" "}
          <span>geral@jminvestments.pt</span> ou através dos números: +351 965
          043 170 / +351 916 433 843 */}
        <div class="soon-social-media-bar">
          <div className="social-media-buttons">
            <ReactWhatsapp
              className="whatsapp-button"
              number="965043170"
              message="Hello!"
            >
              <img src={whatsapp} className="mediabar-icon" />
            </ReactWhatsapp>
            <Link
              to="https://www.linkedin.com/in/jorge-miguel-fonseca/"
              target="_blank"
            >
              <img src={linkedin} className="mediabar-icon" />
            </Link>
            <Link
              to="https://www.facebook.com/share/tgm4hhMpgHntJtqg/"
              target="_blank"
            >
              <img src={facebook} className="mediabar-icon" />
            </Link>
            <Link
              to="https://www.instagram.com/jm__investments?igsh=NHJ5Y3JzbTRodW0z"
              target="_blank"
            >
              <img src={instagram} className="mediabar-icon" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
