import React, { useEffect, useState } from "react";
import "./Dashboard_Home.css";
import SideBarPT from "../../../../components/SideBar/SideBar";

export default function DashboardHome() {
  const [userName, setUserName] = useState("");

  useEffect(() => {
    const storedUserName = localStorage.getItem("userName");
    if (storedUserName) {
      setUserName(storedUserName);
    }
  }, []);

  return (
    <div>
      {/* <div className="sidebar-container"> */}
      <SideBarPT />
      {/* </div> */}
      <div className="dashboard-container">
        <div className="dashboard-content-container">
          <h2 className="contacts-h2">JM INVESTMENTS</h2>
          <p className="content-text">
            Bem-vindo/a, <strong>{userName}</strong>!
          </p>
        </div>
      </div>
    </div>
  );
}
