import React, { useEffect } from "react";
import { Route, BrowserRouter, Routes, useNavigate } from "react-router-dom";
import MainPagePT from "./pages/MainPage_PT";
import AboutsUsSectionPT from "./pages/AboutUs/AboutUs_PT";
import RealEstateSectionPT from "./pages/RealEstate/RealEstate_PT";
import ContactsSectionPT from "./pages/Contacts/Contacts_PT";
import Login from "./pages/private/Dashboard/Login/Login";
import DashboardHome from "./pages/private/Dashboard/Home/Dashboard_Home";
import DashboardRealEstatePT from "./pages/private/Dashboard/RealEstate/Dashboard_Real_Estate";
import MainPageEN from "./pages/MainPage_EN";
import AboutsUsSectionEN from "./pages/AboutUs/AboutUs_EN";
import RealEstateSectionEN from "./pages/RealEstate/RealEstate_EN";
import ContactsSectionEN from "./pages/Contacts/Contacts_EN";
import { ProtectedRoutes } from "./utils/isAuthenticated";
import PropertyPagePT from "./pages/RealEstate/PropertyPage/PropertyPage_PT";
import PropertyPageEN from "./pages/RealEstate/PropertyPage/PropertyPage_EN";
import RealEstateHomePT from "./pages/RealEstate/RealEstate_Home_PT";
import PageNotFound from "./pages/PageNotFound/PageNotFound";
import DashboardRealEstateHighlight from "./pages/private/Dashboard/RealEstate/Dashboard_Real_Estate_Highlight";
import SoonPagePT from "./pages/SoonPage/SoonPage_PT";
import SoonPageEN from "./pages/SoonPage/SoonPage_EN";
import DashboardRealEstateAddProperty from "./pages/private/Dashboard/RealEstate/Dashboard_Real_Estate_Add_Property";
import DashboardRealEstateEditProperty from "./pages/private/Dashboard/RealEstate/Dashboard_Real_Estate_Edit_Property";
import Teste from "./pages/Teste/Teste";
import TesteContactos from "./pages/Teste/Teste_Contactos";
import TesteImoveis from "./pages/Teste/Teste_Imoveis";
import TesteCompraImoveis from "./pages/Teste/Teste_Compra_Imoveis";
import TesteDetalhesImovel from "./pages/Teste/Teste_Detalhes_Imovel";
import TesteEN from "./pages/Teste/Teste_EN";
import TesteContactosEN from "./pages/Teste/Teste_Contactos_EN";
import TesteImoveisEN from "./pages/Teste/Teste_Imoveis_EN";
import TesteCompraImoveisEN from "./pages/Teste/Teste_Compra_Imoveis_EN";
import TesteDetalhesImovelEN from "./pages/Teste/Teste_Detalhes_Imovel_EN";
import TesteSobreNos from "./pages/Teste/Teste_Sobre_Nos";
import TesteSobreNosEN from "./pages/Teste/Teste_Sobre_Nos_EN";

function LanguageRedirect() {
  const navigate = useNavigate();

  useEffect(() => {
    const userLang = navigator.language || navigator.userLanguage;
    if (userLang.includes("pt")) {
      navigate("/pt");
    } else {
      navigate("/en");
    }
  }, [navigate]);

  return null;
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route exact path="/pt" element={<MainPagePT />} /> */}
        <Route path="/" element={<LanguageRedirect />} />
        <Route path="teste/pt" element={<Teste />} />
        <Route path="teste/en" element={<TesteEN />} />
        <Route path="teste/pt/sobre-nos" element={<TesteSobreNos />} />
        <Route path="teste/pt/contactos" element={<TesteContactos />} />
        <Route path="teste/pt/imoveis" element={<TesteImoveis />} />
        <Route path="teste/pt/compra-imoveis" element={<TesteCompraImoveis />} />
        <Route
          path="teste/pt/imoveis/:propertyName"
          element={<TesteDetalhesImovel />}
        />
        <Route exact path="teste/en/about-us" element={<TesteSobreNosEN />} />
        <Route exact path="teste/en/contacts" element={<TesteContactosEN />} />
        <Route exact path="teste/en/real-estate" element={<TesteImoveisEN />} />
        <Route
          exact
          path="teste/en/buy-real-estate"
          element={<TesteCompraImoveisEN />}
        />
        <Route
          path="teste/en/real-estate/:propertyName"
          element={<TesteDetalhesImovelEN />}
        />

        <Route path="/pt" element={<SoonPagePT />} />
        <Route path="/en" element={<SoonPageEN />} />
        {/* <Route exact path="/pt/sobre-nos" element={<AboutsUsSectionPT />} />
        <Route exact path="/pt/imoveis" element={<RealEstateHomePT />} />
        <Route
          exact
          path="/pt/compra-imoveis"
          element={<RealEstateSectionPT />}
        />
        <Route path="/pt/imoveis/:propertyName" element={<PropertyPagePT />} />
        <Route exact path="/pt/contactos" element={<ContactsSectionPT />} /> */}
        <Route exact path="/dashboard/login" element={<Login />} />
        <Route element={<ProtectedRoutes />}>
          <Route exact path="/dashboard" element={<DashboardHome />} />
          <Route
            exact
            path="/dashboard/imoveis"
            element={<DashboardRealEstatePT />}
          />
          <Route
            exact
            path="/dashboard/imoveis/destaque"
            element={<DashboardRealEstateHighlight />}
          />
          <Route
            exact
            path="/dashboard/imoveis/adicionar"
            element={<DashboardRealEstateAddProperty />}
          />
          <Route
            exact
            path="/dashboard/imoveis/editar/:propertyId"
            element={<DashboardRealEstateEditProperty />}
          />
          <Route
            path="/dashboard/imoveis/:propertyName"
            element={<PropertyPagePT />}
          />
        </Route>

        {/* <Route exact path="/en" element={<MainPageEN />} />
        <Route exact path="/en/about-us" element={<AboutsUsSectionEN />} />
        <Route exact path="/en/real-estate" element={<RealEstateSectionEN />} />
        <Route
          path="/en/real-estate/:propertyName"
          element={<PropertyPageEN />}
        />
        <Route exact path="/en/contacts" element={<ContactsSectionEN />} /> */}

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
