import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import ClockIcon from "../../assets/images/clock.png";
import instagram from "../../assets/images/instagram.png";
import facebook from "../../assets/images/facebook.png";
import linkedin from "../../assets/images/linkedin.png";
import whatsapp from "../../assets/images/whatsapp.png";
import mail from "../../assets/images/mail.png";
import ReactWhatsapp from "react-whatsapp";

export default function SoonPagePT() {
  const navigate = useNavigate();

  useEffect(() => {
    const userLang = navigator.language || navigator.userLanguage;
    if (!userLang.includes("pt") && !userLang.includes("br")) {
      navigate("/en");
    }
  }, [navigate]);

  return (
    <div className="app">
      <div className="page-not-found-container">
        {/* <img src={ClockIcon} className="page-not-found-icon" /> */}
        {/* <div class="clock-container">
          <div class="clock-face">
            <div class="hand"></div>
            <div class="hour-marks">
              <div class="mark mark1"></div>
              <div class="mark mark2"></div>
              <div class="mark mark3"></div>
              <div class="mark mark4"></div>
              <div class="mark mark5"></div>
              <div class="mark mark6"></div>
              <div class="mark mark7"></div>
              <div class="mark mark8"></div>
              <div class="mark mark9"></div>
              <div class="mark mark10"></div>
              <div class="mark mark11"></div>
              <div class="mark mark12"></div>
            </div>
          </div>
        </div> */}
        <div className="clock-container">
          <div className="clock" />
        </div>
        <h1 className="soon-title">VOLTAREMOS EM BREVE!</h1>
        <p className="soon-text">
          Estamos a remodelar o nosso website com o objetivo de melhorar a sua
          experiência
        </p>
        <p className="soon-sub-text">
          Pode entrar em contacto connosco através de:{" "}
          <span>
            <a href="mailto:geral@jminvestments.pt" className="soon-link">
              geral@jminvestments.pt
            </a>
          </span>
          {""} ou <span className="soon-link">+351 965 043 170 </span>/
          <span className="soon-link"> +351 916 433 843 </span>
        </p>
        {/* Obrigado por ser paciente. Por
          enquanto pode entrar em contacto connosco através de{" "}
          <span>geral@jminvestments.pt</span> ou através dos números: +351 965
          043 170 / +351 916 433 843 */}
        <div class="soon-social-media-bar">
          <div className="social-media-buttons">
            <ReactWhatsapp
              className="whatsapp-button"
              number="965043170"
              message="Olá!"
            >
              <img src={whatsapp} className="mediabar-icon" />
            </ReactWhatsapp>
            <Link
              to="https://www.linkedin.com/in/jorge-miguel-fonseca/"
              target="_blank"
            >
              <img src={linkedin} className="mediabar-icon" />
            </Link>
            <Link
              to="https://www.facebook.com/share/tgm4hhMpgHntJtqg/"
              target="_blank"
            >
              <img src={facebook} className="mediabar-icon" />
            </Link>
            <Link
              to="https://www.instagram.com/jm__investments?igsh=NHJ5Y3JzbTRodW0z"
              target="_blank"
            >
              <img src={instagram} className="mediabar-icon" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
