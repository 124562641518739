import { MyLocation } from "@material-ui/icons";
import {
  Map,
  useMap,
  useMapsLibrary,
  AdvancedMarker,
} from "@vis.gl/react-google-maps";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import type { Marker } from "@googlemaps/markerclusterer";
import React, { ElementRef, useEffect, useRef, useState } from "react";
import Loading from "../Loading/Loading.js";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from "../../config/firebaseConfig.js";
import noImageIcon from "../../assets/images/no_image.png";
import currentLocationMarker from "../../assets/images/current_location_marker.png";
import currentLocationIcon from "../../assets/images/current_location_icon.png";
import { Link } from "react-router-dom";
import trees from "./data/trees.ts";

type Polyline = google.maps.Polyline;

type RawMarker = [string, number, number];

type MarkerObject = {
  key: string;
  name: string;
  lat: number;
  lng: number;
};

// console.log(trees);

interface MapProps {
  width?: string;
  height?: string;
  onShapeDraw?: (polygon: Polyline) => void;
}

const imagesRef = ref(storage, "images/properties/photos/");

export default function AppMap({ onShapeDraw }: MapProps) {
  const coreLibrary = useMapsLibrary("core");
  const mapsLibrary = useMapsLibrary("maps");
  const mapInstance = useMap();
  const mapContainerRef = useRef<ElementRef<"div"> | null>(null);
  const isMouseDownRef = useRef<boolean>(false);
  const polylineRef = useRef<Polyline | null>(null);
  const polygonRef = useRef<google.maps.Polygon | null>(null);
  const mapContainer = mapContainerRef.current;
  const myLocatioMarkerRef = useRef<google.maps.Marker[]>([]);
  const markersRef = useRef<google.maps.Marker[]>([]);
  const selectedMarkersRef = useRef<google.maps.Marker[]>([]);
  const [drawingMode, setDrawingMode] = useState(false);
  const [buttonText, setButtonText] = useState("Desenhar");
  const [center, setCenter] = useState<{ lat: number; lng: number } | null>(
    null
  );
  const markersClusters: RawMarker[] = [];
  const [pointsReady, setPointsReady] = useState(false);
  const [markers, setMarkers] = useState<
    {
      id: string;
      name: string;
      lat: number;
      lng: number;
      price: number;
      promotionprice?: number;
      key: string;
    }[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const [activeMarker, setActiveMarker] = useState<string | null>(null);
  const [imageList, setImageList] = useState<string[]>([]);
  const [ctrlPressed, setCtrlPressed] = useState(false);
  const coordinates = [
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa Térrea 30",
      lat: 41.46995886081861,
      lng: -8.544392363028576,
      price: 200000,
      promotionprice: 190000,
    },
    {
      id: "449792e4-f801-4ce8-91b8-0b8b078f78c6",
      name: "Casa 2",
      lat: 41.52360945680725,
      lng: -8.669843480819845,
      price: 200000,
      promotionprice: 190000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 3",
      lat: 41.24231349002823,
      lng: -8.59293918376977,
      price: 200000,
      promotionprice: 190000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 1",
      lat: 41.41966725799957,
      lng: -8.648555064270235,
      price: 200000,
      promotionprice: 190000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 2",
      lat: 41.54622416503221,
      lng: -8.595469002630013,
      price: 200000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 3",
      lat: 41.462566419046745,
      lng: -8.602584085605761,
      price: 200000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 1",
      lat: 41.44763521012495,
      lng: -8.55277850477553,
      price: 200000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 2",
      lat: 41.45136833458011,
      lng: -8.484473708208357,
      price: 200000,
      promotionprice: 190000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 3",
      lat: 41.45456798456728,
      lng: -8.525029681170118,
      price: 200000,
      promotionprice: 190000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 1",
      lat: 41.467898160679944,
      lng: -8.46668600076899,
      price: 200000,
      promotionprice: 190000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 2",
      lat: 41.46469916831067,
      lng: -8.535702305633736,
      price: 200000,
      promotionprice: 190000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 3",
      lat: 41.475894951074714,
      lng: -8.595469002630013,
      price: 200000,
      promotionprice: 190000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 1",
      lat: 41.351566810333665,
      lng: -8.623217826235425,
      price: 200000,
      promotionprice: 190000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 2",
      lat: 41.331267551289834,
      lng: -8.624640842830576,
      price: 200000,
      promotionprice: 190000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 3",
      lat: 41.56326216411867,
      lng: -8.54210588031191,
      price: 200000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 1",
      lat: 41.22596215108056,
      lng: -8.549301156993165,
      price: 200000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 2",
      lat: 41.303893111110774,
      lng: -8.614353640461132,
      price: 200000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 3",
      lat: 41.4046068508906,
      lng: -8.639764766815807,
      price: 200000,
      promotionprice: 190000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 1",
      lat: 41.464046259318415,
      lng: -8.602156299810888,
      price: 200000,
      promotionprice: 190000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 2",
      lat: 41.43433335874924,
      lng: -8.61232075035276,
      price: 200000,
      promotionprice: 190000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 3",
      lat: 41.5279970512063,
      lng: -8.166101371564665,
      price: 200000,
      promotionprice: 190000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 1",
      lat: 41.63596072345936,
      lng: -8.30637078904247,
      price: 200000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 2",
      lat: 41.64355699492389,
      lng: -8.474084222983327,
      price: 200000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 3",
      lat: 41.6101267031043,
      lng: -8.687537684362598,
      price: 200000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 1",
      lat: 41.161697854467725,
      lng: -8.64489377131119,
      price: 200000,
      promotionprice: 190000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 2",
      lat: 41.42418039812462,
      lng: -8.753919358933006,
      price: 200000,
      promotionprice: 190000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 3",
      lat: 39.967610330707735,
      lng: -8.418892106176294,
      price: 200000,
      promotionprice: 190000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 1",
      lat: 40.26636203085813,
      lng: -8.465771770296197,
      price: 200000,
      promotionprice: 190000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 2",
      lat: 40.42714185960241,
      lng: -8.598597485302593,
      price: 200000,
      promotionprice: 190000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 3",
      lat: 38.797901000536875,
      lng: -9.17020152216474,
      price: 200000,
      promotionprice: 190000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 1",
      lat: 38.76989143539395,
      lng: -9.256438533896302,
      price: 200000,
      promotionprice: 190000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 2",
      lat: 38.75494850202013,
      lng: -9.325907237791174,
      price: 200000,
      promotionprice: 190000,
    },
    {
      id: "9a49a8de-33f2-4d54-94d8-e8344c13758c",
      name: "Casa 3",
      lat: 41.45760955149625,
      lng: -8.542188446537903,
      price: 200000,
      promotionprice: 190000,
    },
  ];
  const [currentLocation, setCurrentLocation] = useState<{
    lat: number | null;
    lng: number | null;
  }>({
    lat: null,
    lng: null,
  });

  useEffect(() => {
    // Função para ativar/desativar gesture handling
    const updateGestureHandling = () => {
      if (mapInstance && drawingMode) {
        console.log(ctrlPressed);
        mapInstance.setOptions({
          gestureHandling: ctrlPressed ? "auto" : "none",
        });
      }
    };

    // Event listeners para a tecla Ctrl
    const handleKeyDown = (event) => {
      if (event.ctrlKey && !ctrlPressed) {
        setCtrlPressed(true);
      }
    };

    const handleKeyUp = (event) => {
      if (!event.ctrlKey && ctrlPressed) {
        setCtrlPressed(false);
      }
    };

    // Adiciona event listeners
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    // Atualiza o gestureHandling quando o estado de ctrlPressed muda
    updateGestureHandling();

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [ctrlPressed, mapInstance]);

  // const formatted: MarkerObject[] = markersClusters.map(([name, lat, lng]) => ({
  //   name,
  //   lat,
  //   lng,
  //   key: JSON.stringify({ name, lat, lng }),
  // }));

  useEffect(() => {
    console.log(mapInstance);
    // if (!mapInstance) return;

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          const userPosition = { lat: latitude, lng: longitude };
          const marker = new window.google.maps.Marker({
            position: userPosition,
            map: mapInstance,
            title: "User position",
            icon: currentLocationMarker,
          });
          myLocatioMarkerRef.current.push(marker);
          setCenter({ lat: latitude, lng: longitude });
        },
        (error) => {
          console.error("Erro ao obter geolocalização:", error);
        }
      );
    } else {
      console.error("Geolocalização não suportada no navegador.");
    }
  }, [mapInstance]);

  const getUserLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentLocation({ lat: latitude, lng: longitude });
          if (mapInstance) {
            mapInstance.panTo({ lat: latitude, lng: longitude });
            mapInstance.setZoom(15);
          }
        },
        (error) => {
          console.error("Erro ao obter geolocalização:", error);
        }
      );
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  };

  const toggleDrawingMode = () => {
    if (drawingMode) {
      if (polygonRef.current) {
        polygonRef.current.setMap(null);
        polygonRef.current = null;
      }
      if (polylineRef.current) {
        polylineRef.current.setMap(null);
        polylineRef.current = null;
      }
      console.log(showPopup);
      if (showPopup) {
        setShowPopup(false);
      }
      setActiveMarker(null);
      setButtonText("Desenhar");
    } else {
      setButtonText("Limpar");
    }
    setDrawingMode(!drawingMode);
  };

  const triggerMouseMoveEventOnMap = () => {
    if (!coreLibrary || !mapInstance || !drawingMode) return;
    coreLibrary.event.trigger(mapInstance, "mousemove");
  };

  const handleMouseDownListener = () => {
    if (polygonRef.current) {
      return;
    }

    polylineRef.current = new mapsLibrary!.Polyline({
      strokeColor: "#005DA4",
      strokeOpacity: 1,
      strokeWeight: 3,
    });
    polylineRef.current.setMap(mapInstance);
    isMouseDownRef.current = true;
  };

  const handleMouseUpListener = () => {
    if (polygonRef.current) {
      return;
    }

    const path = polylineRef.current!.getPath().getArray();
    path.push(polylineRef.current!.getPath().getArray()[0]);
    polylineRef.current?.setPath(path);
    onShapeDraw?.(polylineRef.current!);

    const polygon = new mapsLibrary!.Polygon({
      paths: path,
      strokeColor: "#011932",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#023164",
      fillOpacity: 0.35,
    });
    polygon.setMap(mapInstance);
    polygonRef.current = polygon;

    console.log(markersRef.current.map((marker) => marker.getTitle()));

    selectedMarkersRef.current = [];

    markersRef.current.forEach((marker) => {
      const markerPosition = marker.getPosition();
      if (
        markerPosition &&
        google.maps.geometry.poly.containsLocation(markerPosition, polygon)
      ) {
        selectedMarkersRef.current.push(marker);
      }
    });

    console.log(selectedMarkersRef.current.map((marker) => marker.getTitle()));

    setShowPopup(true);

    isMouseDownRef.current = false;
  };

  useEffect(() => {
    if (!mapInstance || !coreLibrary || !mapContainer || !drawingMode) return;

    if (ctrlPressed) {
      return;
    }

    mapInstance.setOptions({
      gestureHandling: "none",
    });

    // if (drawingMode) {
    //   // Clear existing markers
    //   markersRef.current.forEach((marker) => marker.setMap(null));
    //   // markersRef.current = [];
    // }

    const handleMapMouseMoveListener = (e: google.maps.MapMouseEvent) => {
      if (!isMouseDownRef.current || !e) {
        return;
      }
      const path = polylineRef.current!.getPath().getArray();
      path.push(e.latLng!);
      polylineRef.current!.setPath(path);
    };

    mapContainer.addEventListener("mousedown", handleMouseDownListener);
    mapContainer.addEventListener("mouseup", handleMouseUpListener);

    mapContainer.addEventListener("touchmove", triggerMouseMoveEventOnMap);
    mapContainer.addEventListener("touchstart", handleMouseDownListener);
    mapContainer.addEventListener("touchend", handleMouseUpListener);

    coreLibrary.event.addListener(
      mapInstance,
      "mousemove",
      handleMapMouseMoveListener
    );

    return () => {
      coreLibrary.event.clearListeners(mapInstance, "mousemove");
      mapContainer.removeEventListener("mousedown", handleMouseDownListener);
      mapContainer.removeEventListener("mouseup", handleMouseUpListener);

      mapContainer.removeEventListener("touchmove", triggerMouseMoveEventOnMap);
      mapContainer.removeEventListener("touchstart", handleMouseDownListener);
      mapContainer.removeEventListener("touchend", handleMouseUpListener);

      mapInstance.setOptions({
        gestureHandling: "auto",
      });
    };
  }, [ctrlPressed, mapInstance, coreLibrary, mapContainer, drawingMode]);

  useEffect(() => {
    async function fetchMarkers() {
      try {
        const formattedMarkers = coordinates.map((coordinate) => ({
          id: coordinate.id,
          name: coordinate.name,
          lat: coordinate.lat,
          lng: coordinate.lng,
          price: coordinate.price,
          promotionprice: coordinate.promotionprice,
          key: JSON.stringify({
            id: coordinate.id,
            name: coordinate.name,
            lat: coordinate.lat,
            lng: coordinate.lng,
            price: coordinate.price,
            promotionprice: coordinate.promotionprice,
          }),
        }));

        const resImages = await listAll(imagesRef);
        const urls = await Promise.all(
          Object.values(resImages.items)
            .filter((itemRef) => itemRef.name.includes(`_0`))
            .map(async (itemRef) => {
              const url = await getDownloadURL(itemRef);
              return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve(url);
                img.onerror = reject;
                img.src = url;
              });
            })
        );

        setImageList(urls as string[]);

        setMarkers(formattedMarkers);
        setIsLoading(false);
      } catch (error) {
        console.error("Erro ao obter árvores do servidor:", error);
        setIsLoading(false);
      }
    }

    fetchMarkers();
  }, []);

  useEffect(() => {
    if (!mapInstance) return;

    coordinates.forEach((coordinate, index) => {
      const marker = new window.google.maps.Marker({
        position: coordinate,
        map: mapInstance,
        title: `Marker ${index + 1}`,
      });
      markersRef.current.push(marker);
    });

    markersRef.current.forEach((marker) => marker.setMap(null));

    console.log(markersRef.current.map((marker) => marker.getTitle()));

    // return () => {
    //   // Remove todos os marcadores ao desmontar o componente
    //   markersRef.current.forEach((marker) => marker.setMap(null));
    // };
  }, [mapInstance]);

  return (
    <div>
      {!center || isLoading ? (
        <Loading />
      ) : (
        <div>
          <button
            className={`draw-shape-button ${
              buttonText === "Limpar" ? "clean" : ""
            }`}
            onClick={toggleDrawingMode}
          >
            {buttonText}
          </button>
          <button
            className={`current-location-button ${
              buttonText === "Limpar" ? "clean" : ""
            }`}
            onClick={getUserLocation}
          >
            <img
              src={currentLocationIcon}
              className="current-location-icon"
            ></img>
          </button>
          {showPopup &&
            (selectedMarkersRef.current.length > 0 ? (
              <div className="search-area-popup">
                Foram encontrados {selectedMarkersRef.current.length} imóveis
                nesta área
              </div>
            ) : (
              <div className="search-area-popup-error">
                Não foi encontrado nenhum imóvel nesta área
              </div>
            ))}
          <div ref={mapContainerRef} className="map-window">
            <Map
              defaultCenter={center}
              defaultZoom={10}
              maxZoom={20}
              minZoom={3}
              options={{
                fullscreenControl: false,
                zoomControl: true,
                streetViewControl: false,
                mapTypeControl: false,
                gestureHandling: "auto",
              }}
              mapId={"4e1d2b6dd3e37a7b"}
              onClick={() => setActiveMarker(null)}
            >
              <Markers
                points={markers}
                activeMarker={activeMarker}
                setActiveMarker={setActiveMarker}
                images={imageList}
                drawingMode={drawingMode}
              />
            </Map>
          </div>
        </div>
      )}
    </div>
  );
}

type Point = google.maps.LatLngLiteral & { key: string };
type Props = {
  points: Point[];
  activeMarker: string | null;
  setActiveMarker: (markerKey: string | null) => void;
  images: string[];
  drawingMode: boolean;
};

const Markers = ({
  points,
  activeMarker,
  setActiveMarker,
  images,
  drawingMode,
}: Props) => {
  const map = useMap();
  const [markers, setMarkers] = useState<{ [key: string]: Marker }>({});
  const clusterer = useRef<MarkerClusterer | null>(null);

  const handleMarkerClick = (point) => {
    if (activeMarker === point.key) {
      setActiveMarker(null);
    } else {
      setActiveMarker(point.key);
    }
  };

  // console.log("Aqui", points);
  // console.log("Aqui", images);

  console.log("Aqui");

  useEffect(() => {
    if (!map) return;
    if (!clusterer.current || !drawingMode) {
      clusterer.current = new MarkerClusterer({ map });
    }
  }, [map]);

  // useEffect(() => {
  //   console.log("Entrou aqui 1 ");
  //   if (drawingMode) {
  //     console.log("Entrou aqui");
  //     // clusterer.current?.clearMarkers();
  //     // clusterer.current = null;
  //   }
  // }, [drawingMode]);

  // useEffect(() => {
  //   if (drawingMode) {
  //     if (clusterer.current) {
  //       clusterer.current.clearMarkers();
  //     }
  //   } else {
  //     if (clusterer.current) {
  //       clusterer.current.addMarkers(Object.values(markers));
  //     }
  //   }
  // }, [drawingMode, markers]);

  useEffect(() => {
    clusterer.current?.clearMarkers();
    clusterer.current?.addMarkers(Object.values(markers));
  }, [markers]);

  const setMarkerRef = (marker: Marker | null, key: string) => {
    if (marker && markers[key]) return;
    if (!marker && !markers[key]) return;

    setMarkers((prev) => {
      if (marker) {
        return { ...prev, [key]: marker };
      } else {
        const newMarkers = { ...prev };
        delete newMarkers[key];
        return newMarkers;
      }
    });
  };

  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  return (
    <>
      {!drawingMode &&
        points.map((point) => (
          <AdvancedMarker
            position={point}
            key={point.key}
            ref={(marker) => setMarkerRef(marker, point.key)}
            onClick={() => handleMarkerClick(point)}
            zIndex={activeMarker === point.key ? 2000 : 1}
          >
            <span
              className={`marker ${
                activeMarker === point.key ? "active-marker" : ""
              }`}
            >
              <div className="blue-circle"></div>
              <div className="white-circle"></div>
            </span>
            {activeMarker === point.key && (
              <div className="marker-square">
                <div className="marker-triangle"></div>
                {images.find((url) =>
                  url.includes(`property_${JSON.parse(point.key).id}`)
                ) ? (
                  <img
                    src={images.find((url) =>
                      url.includes(`property_${JSON.parse(point.key).id}`)
                    )}
                    className="marker-real-estate-image"
                    title="image"
                  />
                ) : (
                  <div className="marker-no-image-container-general">
                    <img
                      className="marker-no-image-icon-general"
                      src={noImageIcon}
                      title="no-image"
                    />
                  </div>
                )}

                <h1 className="marker-title">{JSON.parse(point.key).name}</h1>

                <p className="marker-price">
                  {JSON.parse(point.key).promotionprice ? (
                    <span>
                      <span className="marker-old-price">
                        {formatNumber(JSON.parse(point.key).price)}€
                      </span>{" "}
                      {formatNumber(JSON.parse(point.key).promotionprice)}€
                    </span>
                  ) : (
                    `${formatNumber(JSON.parse(point.key).price)}€`
                  )}
                </p>

                <Link
                  key={JSON.parse(point.key).id}
                  to={`${encodeURIComponent(JSON.parse(point.key).name)}`}
                  className="marker-button"
                >
                  <span>Ver detalhes</span>
                  <span>{">"}</span>
                </Link>
              </div>
            )}
          </AdvancedMarker>
        ))}
    </>
  );
};