import { APIProvider } from "@vis.gl/react-google-maps";
import React from "react";
import { PropsWithChildren } from "react";

const MapProvider = ({ children }: PropsWithChildren) => {
  return (
    <APIProvider apiKey={"AIzaSyDQThmy7SHzAz38EWNv3lyEXWeDlIzJBxk"}>
      {children}
    </APIProvider>
  );
};

export default MapProvider;
