import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import "./RealEstate.css";
import NavbarPT from "../../components/Navbar/Navbar_PT";
import { getRequest } from "../../api";
import Loading from "../../components/Loading/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from "../../config/firebaseConfig";
import noImageIcon from "../../assets/images/no_image.png";

const imagesRef = ref(storage, "images/properties/photos/");

export default function RealEstateSectionPT() {
  const ref = useRef(null);
  const [properties, setProperties] = useState([]);
  const [propertiesTypes, setPropertiesTypes] = useState([]);
  const [propertiesRooms, setPropertiesRooms] = useState([]);
  const [propertiesStates, setPropertiesStates] = useState([]);
  const [propertiesSales, setPropertiesSales] = useState([]);
  const [selectedPropertyTypeName, setSelectedPropertyTypeName] =
    useState(null);
  const [selectedPropertyRoom, setSelectedPropertyRoom] = useState(null);
  const [selectedPropertyState, setSelectedPropertyState] = useState(null);
  const [selectedPropertySale, setSelectedPropertySale] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [minSquareMeters, setMinSquareMeters] = useState(0);
  const [maxSquareMeters, setMaxSquareMeters] = useState(1000);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(10000000);
  const [imageList, setImageList] = useState([]);
  const [error, setError] = useState(null);
  const [showButton, setShowButton] = useState(true);
  const [rowsInSection, setRowsInSection] = useState(1);
  const propertiesPerPage = 9;

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 2000);

  //   return () => clearTimeout(timer);
  // }, []);

  useEffect(() => {
    const fetchProperties = async () => {
      const res = await getRequest("/properties", null);
      setProperties(res);

      const resImages = await listAll(imagesRef);
      const urls = await Promise.all(
        Object.values(resImages.items)
          .filter((itemRef) => itemRef.name.includes(`_0`))
          .map(async (itemRef) => {
            const url = await getDownloadURL(itemRef);
            return new Promise((resolve, reject) => {
              const img = new Image();
              img.onload = () => resolve(url);
              img.onerror = reject;
              img.src = url;
            });
          })
      );

      setImageList(urls);

      setIsLoading(false);
    };
    fetchProperties();
  }, []);

  useEffect(() => {
    const fetchPropertiesFilters = async () => {
      const res = await getRequest("/property/filters", null);
      setPropertiesTypes(res.types);
      setPropertiesRooms(res.rooms);
      setPropertiesStates(res.states);
      setPropertiesSales(res.sales);
    };
    fetchPropertiesFilters();
  }, []);

  // useEffect(() => {
  //   const fetchImageURLs = async () => {
  //     try {
  //       const res = await listAll(imagesRef);
  //       const resUsers = await listAll(usersImagesRef);
  //       const urls = await Promise.all(
  //         Object.values(res.items).map((itemRef) => getDownloadURL(itemRef))
  //       );
  //       const usersUrls = await Promise.all(
  //         Object.values(resUsers.items).map((userItemRef) =>
  //           getDownloadURL(userItemRef)
  //         )
  //       );
  //       setImageList(urls);
  //       setUsersImageList(usersUrls);
  //       setIsImagesLoaded(true);
  //     } catch (error) {
  //       console.log("Erro ao listar os arquivos na pasta:", error);
  //     }
  //   };

  //   fetchImageURLs();
  // }, [imagesRef, usersImagesRef]);

  const filters = [
    {
      key: "searchInput",
      match: (property) => {
        const normalizedSearchInput = searchInput
          ? searchInput
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          : "";
        const normalizedPropertyName = property.name
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        return (
          !searchInput || normalizedPropertyName.includes(normalizedSearchInput)
        );
      },
    },
    {
      key: "selectedPropertyTypeName",
      match: (property) =>
        !selectedPropertyTypeName ||
        property.propertytype_name === selectedPropertyTypeName,
    },
    {
      key: "selectedPropertyRoom",
      match: (property) =>
        !selectedPropertyRoom || property.room === selectedPropertyRoom,
    },
    {
      key: "selectedPropertyState",
      match: (property) =>
        !selectedPropertyState || property.state === selectedPropertyState,
    },
    {
      key: "selectedPropertySale",
      match: (property) =>
        !selectedPropertySale || property.sale === selectedPropertySale,
    },
    {
      key: "squareMeters",
      match: (property) =>
        (!minSquareMeters || property.square >= minSquareMeters) &&
        (!maxSquareMeters || property.square <= maxSquareMeters),
    },
    {
      key: "price",
      match: (property) =>
        (!minPrice || property.price >= minPrice) &&
        (!maxPrice || property.price <= maxPrice),
    },
  ];

  const filteredProperties = properties.filter((property) => {
    return filters.every((filter) => filter.match(property));
  });

  const totalPages = Math.ceil(filteredProperties.length / propertiesPerPage);

  function clearFilters() {
    setSearchInput("");
    setSelectedPropertyTypeName(null);
    setSelectedPropertyRoom(null);
    setSelectedPropertyState(null);
    setSelectedPropertySale(null);
    setMinSquareMeters(0);
    setMaxSquareMeters(1000);
    setMinPrice(0);
    setMaxPrice(10000000);
  }

  const renderPaginationButtons = () => {
    const buttons = [];
    const pageLimit = 3;
    const leftSide = currentPage - Math.floor(pageLimit / 2);
    const rightSide = currentPage + Math.floor(pageLimit / 2);

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= leftSide && i <= rightSide) ||
        (currentPage === 2 && i === 3) ||
        (currentPage === totalPages - 1 && i === totalPages - 2)
      ) {
        buttons.push(
          <button
            key={i}
            className={`pagination-button ${
              currentPage === i ? "active-page" : ""
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      } else if (
        i === leftSide - 1 ||
        i === rightSide + 1 ||
        (currentPage === 2 && i === 4) ||
        (currentPage === totalPages - 1 && i === totalPages - 3)
      ) {
        buttons.push(
          <span key={i} className="pagination-ellipsis">
            ...
          </span>
        );
      }
    }
    return buttons;
  };

  useEffect(() => {
    // Coloque o código de scroll dentro do useEffect
    scrollToTop();
  }, [currentPage]); // Executa o useEffect sempre que currentPage mudar

  // Função para fazer o scroll para o topo
  const scrollToTop = () => {
    // window.scrollTo(0, 0);
    ref.current?.scrollToTop({ behavior: "smooth" });
  };

  useEffect(() => {
    Math.ceil(properties.length / (propertiesPerPage * 3));
    let rows;
    if (currentPage === totalPages) {
      // Última página pode ter menos linhas
      rows =
        properties.length % propertiesPerPage === 0
          ? 3
          : Math.ceil((properties.length % propertiesPerPage) / 3);
    } else {
      rows = 3;
    }
    console.log(rows);
    setRowsInSection(rows);
    console.log(rowsInSection);
  }, [currentPage]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const startIndex = (currentPage - 1) * propertiesPerPage;
  const endIndex = startIndex + propertiesPerPage;
  const currentProperties = filteredProperties.slice(startIndex, endIndex);

  const handleSquareMinChange = (e) => {
    const value = e.target.value;
    if (value === "" || (parseInt(value) >= 0 && parseInt(value) <= 999)) {
      setMinSquareMeters(value === "" ? "" : parseInt(value));

      if (maxSquareMeters < parseInt(value)) {
        setMaxSquareMeters(parseInt(value) + 1);
      }
    }
  };

  const handleSquareMaxChange = (e) => {
    const value = e.target.value;
    if (value === "" || (parseInt(value) >= 1 && parseInt(value) <= 1000)) {
      setMaxSquareMeters(value === "" ? "" : parseInt(value));

      if (minSquareMeters > parseInt(value)) {
        setMinSquareMeters(parseInt(value) - 1);
      }
    }
  };

  const handleSquareDecrementMin = () => {
    if (minSquareMeters > 0) {
      setMinSquareMeters((prevValue) => prevValue - 1);
    }
  };

  const handleSquareIncrementMin = () => {
    if (minSquareMeters < 999) {
      setMinSquareMeters((prevValue) => prevValue + 1);
      if (minSquareMeters + 1 >= maxSquareMeters) {
        setMaxSquareMeters((prevValue) => prevValue + 1);
      }
    }
  };

  const handleSquareDecrementMax = () => {
    if (maxSquareMeters > 1) {
      setMaxSquareMeters((prevValue) => prevValue - 1);
      if (maxPrice - 1 <= minSquareMeters) {
        setMinSquareMeters((prevValue) => prevValue - 1);
      }
    }
  };

  const handleSquareIncrementMax = () => {
    if (maxSquareMeters < 1000) {
      setMaxSquareMeters((prevValue) => prevValue + 1);
    }
  };

  const handlePriceMinChange = (e) => {
    const value = e.target.value;
    if (value === "" || (parseInt(value) >= 0 && parseInt(value) <= 9999999)) {
      setMinPrice(value === "" ? "" : parseInt(value));

      if (maxPrice < parseInt(value)) {
        setMaxPrice(parseInt(value) + 1);
      }
    }
  };

  const handlePriceMaxChange = (e) => {
    const value = e.target.value;
    if (value === "" || (parseInt(value) >= 1 && parseInt(value) <= 10000000)) {
      setMaxPrice(value === "" ? "" : parseInt(value));

      if (minPrice > parseInt(value)) {
        setMinPrice(parseInt(value) - 1);
      }
    }
  };

  const handlePriceDecrementMin = () => {
    if (minPrice > 0) {
      setMinPrice((prevValue) => prevValue - 1);
    }
  };

  const handlePriceIncrementMin = () => {
    if (minPrice < 9999999) {
      setMinPrice((prevValue) => prevValue + 1);
      if (minPrice + 1 >= maxPrice) {
        setMaxPrice((prevValue) => prevValue + 1);
      }
    }
  };

  const handlePriceDecrementMax = () => {
    if (maxPrice > 1) {
      setMaxPrice((prevValue) => prevValue - 1);
      if (maxPrice - 1 <= minPrice) {
        setMinPrice((prevValue) => prevValue - 1);
      }
    }
  };

  const handlePriceIncrementMax = () => {
    if (maxSquareMeters < 10000000) {
      setMaxPrice((prevValue) => prevValue + 1);
    }
  };

  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  return (
    <div className="app">
      {/* <div className="sidebar-container"> */}
      {/* <NavbarPT visible={true} /> */}
      {/* <nav className={`navbar ${visible ? "" : "hidden"}`}>
        <h1>Navbar</h1>
      </nav> */}
      <NavbarPT />
      {/* </div> */}
      <div
        className={`real-estate-container ${
          !isLoading
            ? rowsInSection === 1
              ? "oneLine"
              : rowsInSection === 2
              ? "twoLines"
              : "threeLines"
            : "loading"
        }`}
      >
        <div className="real-estate-content">
          <div className="real-estate-column">
            <h2 className="real-estate-title">IMÓVEIS</h2>

            {isLoading ? (
              <Loading />
            ) : (
              // </div>
              <div className="real-estate-page-container">
                <div className="filters-bar-container">
                  <div className="filters-bar-content">
                    <div className="search-container">
                      <input
                        className="search-input"
                        placeholder="Pesquisar"
                        value={searchInput}
                        onChange={(event) => setSearchInput(event.target.value)}
                      />
                      <FontAwesomeIcon
                        icon={faSearch}
                        className="search-icon"
                      />
                    </div>
                    <select
                      name="type"
                      onChange={(event) =>
                        setSelectedPropertyTypeName(event.target.value)
                      }
                      required
                      value={selectedPropertyTypeName || ""}
                      defaultValue=""
                      className="property-type-dropdown"
                    >
                      <option value="">Tipo de imóvel</option>
                      {propertiesTypes.map((type) => (
                        <option key={type.propertytype_id} value={type.name}>
                          {type.name}
                        </option>
                      ))}
                    </select>{" "}
                    <select
                      name="room"
                      onChange={(event) =>
                        setSelectedPropertyRoom(event.target.value)
                      }
                      required
                      value={selectedPropertyRoom || ""}
                      defaultValue=""
                      className="property-type-dropdown"
                    >
                      <option value="">Quartos</option>
                      {propertiesRooms.map((room) => (
                        <option key={room.room_id} value={room.room}>
                          {room.room}
                        </option>
                      ))}
                    </select>{" "}
                    <select
                      name="state"
                      onChange={(event) =>
                        setSelectedPropertyState(event.target.value)
                      }
                      required
                      value={selectedPropertyState || ""}
                      defaultValue=""
                      className="property-type-dropdown"
                    >
                      <option value="">Estado</option>
                      {propertiesStates.map((state) => (
                        <option key={state.state_id} value={state.state}>
                          {state.state}
                        </option>
                      ))}
                    </select>
                    <select
                      name="sale"
                      onChange={(event) =>
                        setSelectedPropertySale(event.target.value)
                      }
                      required
                      value={selectedPropertySale || ""}
                      defaultValue=""
                      className="property-type-dropdown"
                    >
                      <option value="">Venda</option>
                      {propertiesSales.map((sale) => (
                        <option key={sale.sale_id} value={sale.sale}>
                          {sale.sale}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="input-container square">
                  <button
                    onClick={handleSquareDecrementMin}
                    className="button-left"
                  >
                    -
                  </button>
                  <input
                    type="text"
                    value={minSquareMeters}
                    onChange={handleSquareMinChange}
                    className="input-text square"
                  />
                  <button
                    onClick={handleSquareIncrementMin}
                    className="button-right"
                  >
                    +
                  </button>
                </div>

                <div className="input-container square">
                  <button
                    onClick={handleSquareDecrementMax}
                    className="button-left"
                  >
                    -
                  </button>
                  <input
                    type="text"
                    value={maxSquareMeters}
                    onChange={handleSquareMaxChange}
                    className="input-text square"
                  />
                  <button
                    onClick={handleSquareIncrementMax}
                    className="button-right"
                  >
                    +
                  </button>
                </div>

                <div className="input-container price">
                  <button
                    onClick={handlePriceDecrementMin}
                    className="button-left"
                  >
                    -
                  </button>
                  <input
                    type="text"
                    value={minPrice}
                    onChange={handlePriceMinChange}
                    className="input-text price"
                  />
                  <button
                    onClick={handlePriceIncrementMin}
                    className="button-right"
                  >
                    +
                  </button>
                </div>

                <div className="input-container price">
                  <button
                    onClick={handlePriceDecrementMax}
                    className="button-left"
                  >
                    -
                  </button>
                  <input
                    type="text"
                    value={maxPrice}
                    onChange={handlePriceMaxChange}
                    className="input-text price"
                  />
                  <button
                    onClick={handlePriceIncrementMax}
                    className="button-right"
                  >
                    +
                  </button>
                </div>

                <button id="clear-filters-button" onClick={clearFilters}>
                  Limpar Filtros
                </button>
                {/* <button onClick={applyFilters}>Confirmar</button> */}
                <div className="properties-container-top">
                  <div className="properties-container">
                    {currentProperties.map((property) => {
                      const imageUrl = imageList.find((url) =>
                        url.includes(`property_${property.property_id}`)
                      );
                      return (
                        <Link
                          key={property.property_id}
                          to={`${encodeURIComponent(property.name)}`}
                          className="property-card-link"
                        >
                          <div className="real-estate-column-images">
                            <div className="real-estate-image-wrapper">
                              {property.salestate === "Reservado" && (
                                <div className="reserved-banner reserved">
                                  Reservado
                                </div>
                              )}
                              {property.salestate === "Vendido" && (
                                <div className="reserved-banner sold">
                                  Vendido
                                </div>
                              )}
                              {imageUrl ? (
                                <img
                                  src={imageUrl}
                                  className="real-estate-image"
                                />
                              ) : (
                                <div className="no-image-container-general">
                                  <img
                                    className="no-image-icon-general"
                                    src={noImageIcon}
                                  />
                                </div>
                              )}
                            </div>
                            <p className="real-state-text-location">
                              {property.location}
                            </p>
                            <p className="real-state-text-type">
                              {property.propertytype_name}
                            </p>
                            <p className="real-state-text-price">
                              {property.promotionprice ? (
                                <span>
                                  from{" "}
                                  <span className="old-price">
                                    {formatNumber(property.price)}€
                                  </span>{" "}
                                  {formatNumber(property.promotionprice)}€
                                </span>
                              ) : (
                                `from ${formatNumber(property.price)}€`
                              )}
                            </p>
                          </div>
                        </Link>
                      );
                    })}
                  </div>
                </div>
                {filteredProperties.length > propertiesPerPage && (
                  <div className="pagination">
                    <button
                      className="button-cancel"
                      style={{ width: "87px", marginRight: "10px" }}
                      disabled={currentPage === 1}
                      onClick={() => handlePageChange(currentPage - 1)}
                    >
                      Anterior
                    </button>
                    {renderPaginationButtons()}
                    <button
                      className="close-button-confirm"
                      style={{ width: "87px", marginLeft: "10px" }}
                      disabled={currentPage === totalPages}
                      onClick={() => handlePageChange(currentPage + 1)}
                    >
                      Próxima
                    </button>
                  </div>
                )}
                {(properties.length === 0 ||
                  (filteredProperties.length === 0 && searchInput) ||
                  (selectedPropertyTypeName &&
                    properties.filter(
                      (property) =>
                        property.propertytype_name === selectedPropertyTypeName
                    ).length === 0) ||
                  (selectedPropertyRoom &&
                    properties.filter(
                      (property) => property.room === selectedPropertyRoom
                    ).length === 0) ||
                  (selectedPropertyState &&
                    properties.filter(
                      (property) => property.state === selectedPropertyState
                    ).length === 0) ||
                  (selectedPropertySale &&
                    properties.filter(
                      (property) => property.sale === selectedPropertySale
                    ).length === 0) ||
                  filteredProperties.every(
                    (property) =>
                      parseInt(property.squareMeters) >=
                        parseInt(minSquareMeters) &&
                      parseInt(property.squareMeters) <=
                        parseInt(maxSquareMeters)
                  ) ||
                  filteredProperties.every(
                    (property) =>
                      parseInt(property.price) <= parseInt(minPrice) &&
                      parseInt(property.price) >= parseInt(maxPrice)
                  )) && (
                  <div className="properties-error-container">
                    <p className="properties-error">
                      Não existem imóveis que satisfaçam a sua pesquisa!
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
