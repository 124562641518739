import React, { useState, useEffect } from "react";
import "./ErrorPopup.css";

const ErrorPopup = ({ message, onClose }) => {
  const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
      const timer = setTimeout(() => {
        setIsVisible(false);
        onClose();
      }, 3000);

      return () => clearTimeout(timer);
    }, [onClose]);

  const handleClose = () => {
    setIsVisible(false);
    onClose();
  };

  return (
    <div className={`error-popup ${isVisible ? "visible" : "hidden"}`}>
      <span className="message">{message}</span>
      <div className="close-button-container" onClick={handleClose}>
        <span className="close-button">X</span>
      </div>
    </div>
  );
};

export default ErrorPopup;
