import React, { useState, useRef, useEffect } from "react";
import video from "../../../../assets/videos/home_video.mp4";
import image from "../../../../assets/images/jm_investments_logo.png";
import "./Login.css";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { postRequest } from "../../../../api";
import ConfirmationPopup from "../../../../components/Popup/ConfirmationPopup/ConfimationPopup";
import ErrorPopup from "../../../../components/Popup/ErrorPopup/ErrorPopup";
import eyeOn from "../../../../assets/images/eye_on.png";
import eyeOff from "../../../../assets/images/eye_off.png";

export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const errorMessageRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // com isto se o utilizador errar uma vez, na próxima ao carregar no butão de Login, a mensagem de erro vai ser limpa
    setErrorMessage("");
    setIsLoggingIn(true);

    if (!email || !password) {
      setErrorMessage("Por favor, preencha o email e a senha.");
      setIsLoggingIn(false);
      return;
    }

    try {
      setIsLoggingIn(true);

      const res = await postRequest("/auth/login", { email, password });

      console.log(res);

      localStorage.setItem("token", res.token);
      localStorage.setItem("userId", res.userId);
      localStorage.setItem("userName", res.userName);
      localStorage.setItem("isLoggedIn", true);
      localStorage.setItem("tokenIssuedAt", Date.now());
      localStorage.setItem("tokenExpiresIn", res.expiresIn);

      setPopupMessage("Login realizado com sucesso!");
      setTimeout(() => {
        setIsLoggingIn(false);
        setShowPopup(true);
      }, 100);
      setShowPopup(false);

      setTimeout(() => {
        window.location.href = "/dashboard";
      }, 1000);
      // navigate(-1);
    } catch (error) {
      setErrorMessage(error.message);
      if (errorMessageRef.current) {
        errorMessageRef.current.style.display = "block";
      }
    }
  };

  // useEffect(() => {
  //   const videoElement = document.querySelector(".background-video");
  //   const playVideo = () => {
  //     videoElement.play().catch((error) => {
  //       console.error("Autoplay failed:", error);
  //       // Handle the error, e.g., show a play button for manual start
  //     });
  //   };

  //   playVideo();

  //   document.addEventListener("touchstart", playVideo, { once: true });
  //   return () => {
  //     document.removeEventListener("touchstart", playVideo);
  //   };
  // }, []);

  const handleDismissError = () => {
    setErrorMessage("");
    if (errorMessageRef.current) {
      // verifica se a referência está definida
      errorMessageRef.current.style.display = "none";
    }
  };

  return (
    <div className="home-title">
      <video autoPlay loop muted src={video}></video>
      {/* <Navbar /> */}

      <div className="login-content">
        <div className="login-square">
          <div className="login-content">
            <img src={image} className="login-image"></img>
            <div className="form-container">
              <form className="form" onSubmit={handleSubmit}>
                {errorMessage && (
                  <ErrorPopup
                    message={errorMessage}
                    onClose={handleDismissError}
                  />
                )}
                <input
                  type="email"
                  placeholder="Email"
                  className="login-input"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <div className="login-input-container">
                  <input
                    type={showPassword ? "text" : "password"}
                    placeholder="Password"
                    className="login-input-password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <div
                    className="password-toggle"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    <img
                      src={showPassword ? eyeOff : eyeOn}
                      className="login-eye"
                    />
                  </div>
                </div>

                <button
                  className={`login-button ${
                    isLoggingIn ? "form--submit-loading" : ""
                  }`}
                  disabled={isLoggingIn}
                >
                  {isLoggingIn ? "" : "Login"}
                </button>
              </form>
              {showPopup && (
                <ConfirmationPopup
                  showPopup={showPopup}
                  message={popupMessage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
