import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import Loading from "../../components/Loading/Loading";
import NavbarPT from "../../components/Navbar/Navbar_PT";
import video from "../../assets/videos/jm_investments.mp4";
import audioOn from "../../assets/images/audio_on.png";
import audioOff from "../../assets/images/audio_off.png";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from "../../config/firebaseConfig";
import "./RealEstate_Home.css";
import { getRequest } from "../../api";
import noImageIcon from "../../assets/images/no_image.png";

const imagesRef = ref(storage, "images/properties/photos/");

export default function RealEstateHomePT() {
  const videoRef = useRef(null);
  const [properties, setProperties] = useState([]);
  const [highlightedProperties, setHighlightedProperties] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isMuted, setIsMuted] = useState(true);
  const [imageList, setImageList] = useState([]);
  const [highlightImageList, setHighlightImageList] = useState([]);
  const [imageIndices, setImageIndices] = useState({});
  const [counter, setCounter] = useState(0);
  const [currentPropertyIndex, setCurrentPropertyIndex] = useState(0);
  const [selectedProperty, setSelectedProperty] = useState(
    highlightedProperties[0]
  );
  const parallaxRef = useRef(null);
  const [intervalId, setIntervalId] = useState(null);

  const handleChangeProperty = (index) => {
    clearInterval(intervalId);
    setCurrentPropertyIndex(index);
    setSelectedProperty(highlightedProperties[index]);
    startCarousel();
  };

  console.log(selectedProperty);

  useEffect(() => {
    const fetchProperties = async () => {
      const res = await getRequest("/properties", null);
      console.log(res);
      setProperties(res);
      const highlightProperties = res.filter((prop) => prop.highlight);
      setHighlightedProperties(highlightProperties);
      const firstProperty = highlightProperties[0];
      setSelectedProperty(firstProperty);

      const resImages = await listAll(imagesRef);
      const urls = await Promise.all(
        Object.values(resImages.items)
          // .filter((itemRef) => itemRef.name.includes(`_0`))
          .map(async (itemRef) => {
            const url = await getDownloadURL(itemRef);
            return new Promise((resolve, reject) => {
              const img = new Image();
              img.onload = () => resolve(url);
              img.onerror = reject;
              img.src = url;
            });
          })
      );

      const highlightedUrls = urls.filter((url) => {
        return highlightProperties.some((prop) =>
          url.includes(`property_${prop.property_id}_0`)
        );
      });

      setImageList(urls);
      setHighlightImageList(highlightedUrls);

      setIsLoading(false);
    };
    fetchProperties();
  }, []);

  // const property = properties[currentPropertyIndex];
  // console.log(properties);
  // console.log(property);
  // const imageUrl = imageList.includes(`property_${property.property_id}_0`)
  //   ? imageList[property.property_id]
  //   : noImageIcon;

  // console.log(properties);

  const toggleMute = () => {
    setIsMuted(!isMuted);
    videoRef.current.muted = !videoRef.current.muted;
  };

  const propertiesGridCode = (property) => {
    return (
      <Link
        key={property.property_id}
        to={`${encodeURIComponent(property.name)}`}
        className="real-estate-home-property-card-link"
      >
        <div className="real-estate-home-row-images">
          <div className="real-estate-column-images">
            <div className="real-estate-image-wrapper">
              {(property.salestate === "Reservado" ||
                property.salestate === "Vendido") && (
                <div>
                  <div
                    className={`reserved-banner ${
                      property.salestate === "Reservado"
                        ? "reserved"
                        : property.salestate === "Vendido"
                        ? "sold"
                        : ""
                    }`}
                  >
                    {property.salestate === "Reservado"
                      ? "Reservado"
                      : "Vendido"}
                  </div>
                  <div
                    className={`banner-triangle ${
                      property.salestate === "Reservado"
                        ? "reserved"
                        : property.salestate === "Vendido"
                        ? "sold"
                        : ""
                    }`}
                  ></div>
                </div>
              )}

              {imageList.length > 0 &&
                (imageList.find((url) =>
                  url.includes(`property_${property.property_id}_0`)
                ) ? (
                  <div className="image-container">
                    <img
                      src={imageList.find((url) =>
                        url.includes(`property_${property.property_id}_0`)
                      )}
                      className="real-estate-image"
                    />
                  </div>
                ) : (
                  <div className="no-image-container-general">
                    <img className="no-image-icon-general" src={noImageIcon} />
                  </div>
                ))}
            </div>
            <p className="real-state-text-location">{property.name}</p>
            <p className="real-state-text-location">
              {property.freguesia_name}, {property.municipio_name}
            </p>
            <p className="real-state-text-type">{property.propertytype_name}</p>
            <p className="real-state-text-price">
              {property.promotionprice ? (
                <span>
                  from{" "}
                  <span className="old-price">
                    {formatNumber(property.price)}€
                  </span>{" "}
                  {formatNumber(property.promotionprice)}€
                </span>
              ) : (
                `from ${formatNumber(property.price)}€`
              )}
            </p>
          </div>
        </div>
      </Link>
    );
  };

  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  useEffect(() => {
    const handleScroll = () => {
      if (parallaxRef.current) {
        const scrollPosition = window.scrollY;
        const parallaxSpeed = 0.3;
        parallaxRef.current.style.backgroundPositionY = `${
          -scrollPosition * parallaxSpeed
        }px`;
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  console.log(
    highlightImageList.find((url) =>
      url.includes(`property_${selectedProperty.property_id}_0`)
    )
  );

  const startCarousel = () => {
    const newIntervalId = setInterval(() => {
      setCurrentPropertyIndex((currentIdx) => {
        const nextIndex = (currentIdx + 1) % highlightedProperties.length;
        setSelectedProperty(highlightedProperties[nextIndex]);
        return nextIndex;
      });
    }, 5000);

    // Limpa o intervalo anterior
    clearInterval(intervalId);

    // Define o novo intervalo
    setIntervalId(newIntervalId);
  };

  useEffect(() => {
    startCarousel();
    return () => clearInterval(intervalId);
  }, [highlightedProperties]);

  return (
    <div className="app">
      <NavbarPT />
      <div
        className={`real-estate-home-container ${
          properties.length === 0 ? "no-properties" : ""
        } ${
          properties.length > 0 &&
          properties.filter((property) => property.promotionprice !== null)
            .length > 0 &&
          highlightedProperties.length === 0
            ? "no-highlight"
            : ""
        } ${
          properties.length > 0 &&
          properties.filter((property) => property.promotionprice !== null)
            .length === 0
            ? highlightedProperties.length > 0
              ? "no-promotion"
              : "no-promotion-no-highlight"
            : ""
        }`}
      >
        <div className="real-estate-home-content">
          <div className="real-estate-home-column">
            <div
              className={`video-container ${
                properties.length === 0 ? "no-properties" : ""
              }`}
            >
              <video
                className={`real-estate-home-video ${
                  properties.length === 0 ? "no-properties" : ""
                }`}
                ref={videoRef}
                src={video}
                autoPlay
                loop
                muted={isMuted}
              />
              <div
                className={`video-text ${
                  properties.length === 0 ? "no-properties" : ""
                }`}
              >
                Na{" "}
                <span style={{ color: "#c28e2b", fontWeight: "bold" }}>
                  JM INVESTMENTS
                </span>{" "}
                oferecemos um serviço de excelência adaptado às necessidades de
                cada cliente
              </div>
              <div
                className={`real-estate-home-buttons-row ${
                  !properties.filter(
                    (property) => property.promotionprice !== null
                  ).length > 0
                    ? "no-properties"
                    : ""
                }`}
              >
                <Link
                  to="/pt/compra-imoveis"
                  className="real-estate-home-button"
                >
                  Comprar
                </Link>
                {/* <button className="real-estate-home-button">Vender</button> */}
              </div>
              <button onClick={toggleMute} className="mute-button">
                <img src={isMuted ? audioOff : audioOn} className="mute-icon" />
              </button>
            </div>

            {properties.length > 0 && highlightedProperties.length > 0 && (
              <div className="real-estate-home-row" ref={parallaxRef}>
                <div className="real-estate-home-title-highlight-container">
                  <div className="real-estate-home-title">
                    Imóveis em destaque
                  </div>
                </div>
                <div className="real-estate-home-properties-carousel">
                  {isLoading ? (
                    <Loading />
                  ) : properties.length > 0 ? (
                    <div className="properties-home-carousel-container">
                      <Link
                        to={`${encodeURIComponent(selectedProperty.name)}`}
                        className="properties-home-row-property-card-link"
                      >
                        <div className="properties-home-real-estate-card-row">
                          {(selectedProperty.salestate === "Reservado" ||
                            selectedProperty.salestate === "Vendido") && (
                            <div>
                              <div
                                className={`reserved-banner ${
                                  selectedProperty.salestate === "Reservado"
                                    ? "reserved"
                                    : selectedProperty.salestate === "Vendido"
                                    ? "sold"
                                    : ""
                                }`}
                              >
                                {selectedProperty.salestate === "Reservado"
                                  ? "Reservado"
                                  : "Vendido"}
                              </div>
                              <div
                                className={`banner-triangle ${
                                  selectedProperty.salestate === "Reservado"
                                    ? "reserved"
                                    : selectedProperty.salestate === "Vendido"
                                    ? "sold"
                                    : ""
                                }`}
                              ></div>
                            </div>
                          )}

                          {highlightImageList.find((url) =>
                            url.includes(
                              `property_${selectedProperty.property_id}_0`
                            )
                          ) ? (
                            <img
                              className="properties-home-carousel-image"
                              src={highlightImageList.find((url) =>
                                url.includes(
                                  `property_${selectedProperty.property_id}_0`
                                )
                              )}
                            />
                          ) : (
                            <div className="properties-home-no-image-container-general">
                              <img
                                className="no-image-icon-general"
                                src={noImageIcon}
                              />
                            </div>
                          )}
                          <div className="real-estate-content-row">
                            <p
                              className="real-state-text-name-row"
                              style={{ marginTop: "0px" }}
                            >
                              {selectedProperty.name}
                            </p>
                            <p
                              className="real-state-text-location-row"
                              style={{ marginTop: "0px" }}
                            >
                              {selectedProperty.freguesia_name},{" "}
                              {selectedProperty.municipio_name}
                            </p>
                            <p className="real-state-text-type-row">
                              {selectedProperty.propertytype_name}
                            </p>
                            <p className="real-state-text-price-row">
                              {selectedProperty.promotionprice ? (
                                <span>
                                  from{" "}
                                  <span className="old-price">
                                    {formatNumber(selectedProperty.price)}€
                                  </span>{" "}
                                  {formatNumber(
                                    selectedProperty.promotionprice
                                  )}
                                  €
                                </span>
                              ) : (
                                `from ${formatNumber(selectedProperty.price)}€`
                              )}
                            </p>
                          </div>
                        </div>
                      </Link>
                      <div className="carousel-dots">
                        {highlightedProperties.map((_, index) => (
                          <span
                            key={index}
                            className={`carousel-dot ${
                              index === currentPropertyIndex ? "active" : ""
                            }`}
                            onClick={() => handleChangeProperty(index)}
                          />
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div>Não existem imóveis</div>
                  )}
                </div>
              </div>
            )}

            {properties.length > 0 && (
              <div className="real-estate-home-row-container">
                <div className="real-estate-home-title-container">
                  <div className="real-estate-home-title">
                    Imóveis mais recentes
                  </div>
                  {/* TODO mandar com o parâmetro de mais recentes */}
                  <Link
                    to={"/pt/compra-imoveis"}
                    className="real-estate-home-see-more"
                  >
                    Ver todos
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      version="1.1"
                      class="icon"
                      viewBox="0 0 18 14"
                      width="16px"
                      height="12px"
                      fill="#ffffff"
                      style={{ marginLeft: "10px" }}
                    >
                      <path
                        d="M10.155 12.195a.75.75 0 0 0 1.133.977l5.946-5.946.073-.084a.75.75 0 0 0-.073-.977L11.288.219l-.084-.073a.75.75 0 0 0-.977.073l-.073.084a.75.75 0 0 0 .073.977l4.666 4.666H.75l-.102.007A.75.75 0 0 0 .75 7.446h14.142l-4.664 4.665-.073.084z"
                        fill-rule="evenodd"
                      ></path>
                    </svg>
                  </Link>
                </div>
                {isLoading ? (
                  <Loading />
                ) : (
                  <div className="real-estate-home-properties-row">
                    {properties.length > 0 ? (
                      properties
                        .sort(
                          (a, b) =>
                            new Date(b.insertion_date) -
                            new Date(a.insertion_date)
                        )
                        .slice(0, 5)
                        .map((property) => {
                          return propertiesGridCode(property);
                        })
                    ) : (
                      <div>Não existem imóveis</div>
                    )}
                  </div>
                )}
              </div>
            )}

            {properties.length > 0 &&
              properties.filter((property) => property.promotionprice !== null)
                .length > 0 && (
                <div className="real-estate-home-row-container">
                  <div className="real-estate-home-title-container">
                    <div className="real-estate-home-title">
                      Imóveis em promoção
                    </div>
                    <Link
                      to={"/pt/compra-imoveis"}
                      className="real-estate-home-see-more"
                    >
                      Ver todos
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        version="1.1"
                        className="icon"
                        viewBox="0 0 18 14"
                        width="16px"
                        height="12px"
                        fill="#ffffff"
                        style={{ marginLeft: "10px" }}
                      >
                        <path
                          d="M10.155 12.195a.75.75 0 0 0 1.133.977l5.946-5.946.073-.084a.75.75 0 0 0-.073-.977L11.288.219l-.084-.073a.75.75 0 0 0-.977.073l-.073.084a.75.75 0 0 0 .073.977l4.666 4.666H.75l-.102.007A.75.75 0 0 0 .75 7.446h14.142l-4.664 4.665-.073.084z"
                          fillRule="evenodd"
                        ></path>
                      </svg>
                    </Link>
                  </div>
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <div className="real-estate-home-properties-row">
                      {properties
                        .filter((property) => property.promotionprice !== null)
                        .slice(0, 5)
                        .map((property) => {
                          return propertiesGridCode(property);
                        })}
                    </div>
                  )}
                </div>
              )}

            {/* {isLoading ? (
              <Loading />
            ) : (
              <div className="real-estate-page-container">
                <div>Imóveis em destaque</div>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
}
