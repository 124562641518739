import React from "react";
import Spinner from "react-spinner-material";
import "./Loading.css";
import { height } from "@fortawesome/free-solid-svg-icons/fa0";
import { useLocation } from "react-router-dom";

export default function LoadingTeste() {
  const location = useLocation();

  const conditionalStyle = location.pathname.startsWith("/dashboard/imoveis")
    ? { height: "80%" }
    : {};

  return (
    <div className="test-loading-container" style={conditionalStyle}>
      {/* <Spinner
        // style="border-color: #c28e2b;"
        size={120}
        spinnercolor={"#c28e2b"}
        spinnerwidth={2}
        visible={true}
      /> */}
      <div className="loader" />
    </div>
  );
}
