import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Navbar.css";
import Portugal_flag from "../../assets/images/Portugal_flag.png";
import United_Kingdom_flag from "../../assets/images/United-Kingdom_flag.png";
import { ReactComponent as ArrowDown } from "../../assets/images/Arrow_down.svg";
import { ReactComponent as ArrowDownYellow } from "../../assets/images/Arrow_down_active.svg";

export default function NavbarPT() {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const [visiblePosition, setVisiblePosition] = useState("top");

  const location = useLocation();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [scrollDirection, setScrollDirection] = useState("scrollBottom");

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 50);

      if (currentScrollPos > prevScrollPos && currentScrollPos < 70) {
        setScrollDirection("scrollDown");
      } else if (prevScrollPos > currentScrollPos) {
        setScrollDirection("scrollTop");
      }

      if (currentScrollPos < 150) {
        setVisiblePosition("top");
      } else {
        setVisiblePosition("bottom");
      }
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  return (
    <nav
      className={`navbar ${
        visible ? "" : "hidden"
      } ${visiblePosition} ${scrollDirection}`}
    >
      <div className="butoesnavegacao-buttons">
        <Link
          to="/pt"
          className={`butoesnavegacao-item ${
            location.pathname === "/pt" ? "active" : ""
          }`}
        >
          Início
        </Link>
        <Link
          to="/pt/sobre-nos"
          className={`butoesnavegacao-item ${
            location.pathname === "/pt/sobre-nos" ? "active" : ""
          }`}
        >
          Sobre Nós
        </Link>
        <Link
          to="/pt/imoveis"
          className={`butoesnavegacao-item ${
            location.pathname === "/pt/imoveis" ? "active" : ""
          }`}
        >
          Imóveis
        </Link>
        <Link
          to="/pt/contactos"
          className={`butoesnavegacao-item ${
            location.pathname === "/pt/contactos" ? "active" : ""
          }`}
        >
          Contactos
        </Link>
        {/* <div className="butoesnavegacao-dropdown"> */}
        <div className="butoesnavegacao-item">
          <div
            className={`butoesnavegacao-item-flag ${
              dropdownOpen ? "active" : ""
            }`}
            onClick={handleDropdownToggle}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <img
              src={Portugal_flag}
              className="butoesnavegacao-flag"
              alt="PT"
            />
            {isHovered || dropdownOpen ? (
              <ArrowDownYellow
                className="butoesnavegacao-arrow"
                fill="#c28e2b"
              />
            ) : (
              <ArrowDown className="butoesnavegacao-arrow" />
            )}
          </div>
          {dropdownOpen && (
            <div className="butoesnavegacao-dropdown-content">
              <Link to="/en" className="butoesnavegacao-dropdown-item">
                <img
                  src={United_Kingdom_flag}
                  className="butoesnavegacao-flag"
                  alt="EN"
                />
              </Link>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
}
