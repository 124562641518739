import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Dashboard_Real_Estate.css";
import SideBarPT from "../../../../components/SideBar/SideBar";
import { getRequest } from "../../../../api";
import Loading from "../../../../components/Loading/Loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import {
  ref,
  listAll,
  getDownloadURL,
  uploadBytesResumable,
  uploadBytes,
} from "firebase/storage";
import { storage } from "../../../../config/firebaseConfig";
import noImageIcon from "../../../../assets/images/no_image.png";
import filtersIcon from "../../../../assets/images/filters.png";
import threeColumn from "../../../../assets/images/three-column.png";
import threeColumnActive from "../../../../assets/images/three-column-active.png";
import threeRows from "../../../../assets/images/three-rows.png";
import threeRowsActive from "../../../../assets/images/three-rows-active.png";
import map from "../../../../assets/images/map.png";
import mapActive from "../../../../assets/images/map_active.png";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import AppMap from "../../../../components/Map/Map.tsx";
import MapProvider from "../../../../components/Map/MapProvider.tsx";
import regioes from "../../../../utils/regioes.json";

const imagesRef = ref(storage, "images/properties/photos/");

export default function DashboardRealEstatePT() {
  const pageRef = useRef(null);
  // const [filteredProperties, setFilteredProperties] = useState([]);
  const [properties, setProperties] = useState([]);
  const [propertiesTypes, setPropertiesTypes] = useState([]);
  const [propertiesRooms, setPropertiesRooms] = useState([]);
  const [propertiesStates, setPropertiesStates] = useState([]);
  const [propertiesSales, setPropertiesSales] = useState([]);
  const [propertiesEnergyCertificates, setPropertiesEnergyCertificates] =
    useState([]);
  const [selectedPropertyTypeName, setSelectedPropertyTypeName] =
    useState(null);
  const [selectedPropertyRoom, setSelectedPropertyRoom] = useState(null);
  const [selectedPropertyState, setSelectedPropertyState] = useState(null);
  const [selectedPropertySale, setSelectedPropertySale] = useState(null);
  const [selectedSortOption, setSelectedSortOption] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchInput, setSearchInput] = useState("");
  const [minSquareMeters, setMinSquareMeters] = useState(0);
  const [maxSquareMeters, setMaxSquareMeters] = useState(5000);
  const [minSquareMetersChanged, setMinSquareMetersChanged] = useState(false);
  const [maxSquareMetersChanged, setMaxSquareMetersChanged] = useState(false);
  const [minPrice, setMinPrice] = useState(0);
  const [maxPrice, setMaxPrice] = useState(5000000);
  const [minPriceChanged, setMinPriceChanged] = useState(false);
  const [maxPriceChanged, setMaxPriceChanged] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [error, setError] = useState(null);
  const [showButton, setShowButton] = useState(true);
  const [rowsInSection, setRowsInSection] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isLocationOpen, setIsLocationOpen] = useState(false);
  const [isMapOpen, setIsMapOpen] = useState(false);
  const [isTypeOpen, setIsTypeOpen] = useState(false);
  const [isRoomOpen, setIsRoomOpen] = useState(false);
  const [isStateOpen, setIsStateOpen] = useState(false);
  const [isSaleOpen, setIsSaleOpen] = useState(false);
  const [isPriceOpen, setIsPriceOpen] = useState(false);
  const [isSquareOpen, setIsSquareOpen] = useState(false);
  const [isSortOpen, setIsSortOpen] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const [selectedRooms, setSelectedRooms] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedSales, setSelectedSales] = useState([]);
  const [menuFixed, setMenuFixed] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [scrollPos, setScrollPosition] = useState(0);
  const [isFirstActive, setIsFirstActive] = useState(true);
  const [selectedGridOption, setSelectedGridOption] = useState("threeColumns");
  const [posToFix, setPosToFix] = useState(0);
  const [imageIndices, setImageIndices] = useState({});
  const [selectedDistrito, setSelectedDistrito] = useState({});
  const [selectedMunicipio, setSelectedMunicipio] = useState({});
  const [selectedFreguesia, setSelectedFreguesia] = useState({});
  const [openMunicipio, setOpenMunicipio] = useState({});
  const [openFreguesia, setOpenFreguesia] = useState({});
  const [isPromotion, setIsPromotion] = useState(false);
  const [isLoadingPopup, setIsLoadingPopup] = useState(false);
  const propertiesPerPage = 9;

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyDQThmy7SHzAz38EWNv3lyEXWeDlIzJBxk",
  });

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 2000);

  //   return () => clearTimeout(timer);
  // }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      setScrollPosition(currentScrollPos);

      if (selectedGridOption === "threeColumns") {
        setPosToFix(947);
      } else {
        if (currentProperties.length == 6) {
          setPosToFix(1400);
        } else if (currentProperties.length == 7) {
          setPosToFix(1750);
        } else if (currentProperties.length == 8) {
          setPosToFix(2100);
        } else if (currentProperties.length == 9) {
          setPosToFix(2450);
        }
      }

      if (isOpen && currentScrollPos > 306 && currentScrollPos < posToFix) {
        setMenuFixed(true);
      } else {
        setMenuFixed(false);
      }

      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [prevScrollPos]);

  useEffect(() => {
    const fetchProperties = async () => {
      const res = await getRequest("/properties", null);
      setProperties(res);

      console.log(res);

      const resImages = await listAll(imagesRef);

      console.log("Aqui");
      const urls = await Promise.all(
        Object.values(resImages.items)
          .filter((itemRef) => itemRef.name.includes(`_0`))
          .map(async (itemRef) => {
            const url = await getDownloadURL(itemRef);
            return new Promise((resolve, reject) => {
              const img = new Image();
              img.onload = () => resolve(url);
              img.onerror = reject;
              img.src = url;
            });
          })
      );

      console.log(resImages);
      console.log(urls);

      setImageList(urls);

      setIsLoading(false);
    };
    fetchProperties();
  }, []);

  console.log(imageList);
  console.log(properties);
  console.log(properties.length);

  useEffect(() => {
    const fetchPropertiesFilters = async () => {
      const res = await getRequest("/property/filters", null);
      setPropertiesTypes(res.types);
      setPropertiesRooms(res.rooms);
      setPropertiesStates(res.states);
      setPropertiesSales(res.sales);
      setPropertiesEnergyCertificates(res.energyCertificates);
    };
    fetchPropertiesFilters();
  }, []);

  // useEffect(() => {
  //   const fetchImageURLs = async () => {
  //     try {
  //       const res = await listAll(imagesRef);
  //       const resUsers = await listAll(usersImagesRef);
  //       const urls = await Promise.all(
  //         Object.values(res.items).map((itemRef) => getDownloadURL(itemRef))
  //       );
  //       const usersUrls = await Promise.all(
  //         Object.values(resUsers.items).map((userItemRef) =>
  //           getDownloadURL(userItemRef)
  //         )
  //       );
  //       setImageList(urls);
  //       setUsersImageList(usersUrls);
  //       setIsImagesLoaded(true);
  //     } catch (error) {
  //       console.log("Erro ao listar os arquivos na pasta:", error);
  //     }
  //   };

  //   fetchImageURLs();
  // }, [imagesRef, usersImagesRef]);

  const filters = [
    {
      key: "searchInput",
      match: (property) => {
        const normalizedSearchInput = searchInput
          ? searchInput
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          : "";
        const normalizedPropertyName = property.name
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "");
        return (
          !searchInput || normalizedPropertyName.includes(normalizedSearchInput)
        );
      },
    },
    {
      key: "selectedFreguesia",
      match: (property) => {
        if (Object.keys(selectedFreguesia).length === 0) return true;
        const selectedMunicipio =
          selectedFreguesia?.[property.distrito_name]?.[
            property.municipio_name
          ];
        return selectedMunicipio && selectedMunicipio[property.freguesia_name];
      },
    },
    {
      key: "selectedPropertyTypeName",
      match: (property) =>
        !selectedTypes.length ||
        selectedTypes.includes(property.propertytype_name),
    },
    {
      key: "selectedPropertyRoom",
      match: (property) =>
        !selectedRooms.length || selectedRooms.includes(property.room),
    },
    {
      key: "selectedPropertyState",
      match: (property) =>
        !selectedStates.length || selectedStates.includes(property.state),
    },
    {
      key: "selectedPropertySale",
      match: (property) =>
        !selectedSales.length || selectedSales.includes(property.sale),
    },
    {
      key: "squareMeters",
      match: (property) =>
        (!minSquareMeters || property.square >= minSquareMeters) &&
        (!maxSquareMeters || property.square <= maxSquareMeters),
    },
    {
      key: "price",
      match: (property) =>
        (!minPrice || property.price >= minPrice) &&
        (!maxPrice || property.price <= maxPrice),
    },
    {
      key: "promotionPrice",
      match: (property) => !isPromotion || property.promotionprice !== null,
    },
  ];

  // useEffect(() => {
  //   setFilteredProperties(
  //     properties.filter((property) => {
  //       return filters.every((filter) => filter.match(property));
  //     })
  //   );
  // }, [properties, filters]);

  const sortOptions = {
    "Preço ascendente": (a, b) =>
      (a.promotionprice || a.price) - (b.promotionprice || b.price),
    "Preço descendente": (a, b) =>
      (b.promotionprice || b.price) - (a.promotionprice || a.price),
    "Mais recentes": (a, b) =>
      new Date(b.insertion_date) - new Date(a.insertion_date),
    "Mais antigos": (a, b) =>
      new Date(a.insertion_date) - new Date(b.insertion_date),
    "Mais visualizados": (a, b) => b.views - a.views,
    "Menos visualizados": (a, b) => a.views - b.views,
    "Maior área": (a, b) => b.square - a.square,
    "Menor área": (a, b) => a.square - b.square,
  };

  console.log(
    properties
      .filter((property) => {
        return filters.every((filter) => filter.match(property));
      })
      .sort(sortOptions[selectedSortOption])
  );

  console.log(filters);

  const filteredProperties = properties
    .filter((property) => {
      return filters.every((filter) => filter.match(property));
    })
    .sort(sortOptions[selectedSortOption]);

  const totalPages = Math.ceil(filteredProperties.length / propertiesPerPage);

  function clearFilters() {
    setSearchInput("");
    setSelectedTypes([]);
    setSelectedRooms([]);
    setSelectedStates([]);
    setSelectedSales([]);
    setMinPrice(0);
    setMaxPrice(5000000);
    setMinPriceChanged(false);
    setMaxPriceChanged(false);
    setMinSquareMeters(0);
    setMaxSquareMeters(1000);
    setMinSquareMetersChanged(false);
    setMaxSquareMetersChanged(false);
  }

  const renderPaginationButtons = () => {
    const buttons = [];
    const pageLimit = 3;
    const leftSide = currentPage - Math.floor(pageLimit / 2);
    const rightSide = currentPage + Math.floor(pageLimit / 2);

    for (let i = 1; i <= totalPages; i++) {
      if (
        i === 1 ||
        i === totalPages ||
        (i >= leftSide && i <= rightSide) ||
        (currentPage === 2 && i === 3) ||
        (currentPage === totalPages - 1 && i === totalPages - 2)
      ) {
        buttons.push(
          <button
            key={i}
            className={`pagination-button ${
              currentPage === i ? "active-page" : ""
            }`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </button>
        );
      } else if (
        i === leftSide - 1 ||
        i === rightSide + 1 ||
        (currentPage === 2 && i === 4) ||
        (currentPage === totalPages - 1 && i === totalPages - 3)
      ) {
        buttons.push(
          <span key={i} className="pagination-ellipsis">
            ...
          </span>
        );
      }
    }
    return buttons;
  };

  useEffect(() => {
    // Coloque o código de scroll dentro do useEffect
    scrollToTop();
  }, [currentPage]); // Executa o useEffect sempre que currentPage mudar

  // Função para fazer o scroll para o topo
  const scrollToTop = () => {
    // window.scrollTo(0, 0);
    pageRef.current?.scrollToTop({ behavior: "smooth" });
  };

  useEffect(() => {
    Math.ceil(filteredProperties.length / (propertiesPerPage * 3));
    let rows;
    if (currentPage === totalPages) {
      // Última página pode ter menos linhas
      rows =
        filteredProperties.length % propertiesPerPage === 0
          ? 3
          : Math.ceil((filteredProperties.length % propertiesPerPage) / 3);
    } else {
      rows = 3;
    }
    setRowsInSection(rows);
  }, [currentPage, filteredProperties.length]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);

    // window.scrollTo(0, 0);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const startIndex = (currentPage - 1) * propertiesPerPage;
  const endIndex = startIndex + propertiesPerPage;
  const currentProperties = filteredProperties.slice(startIndex, endIndex);

  console.log(currentProperties);
  console.log(filteredProperties);

  const handleSquareMinChange = (e) => {
    const value = e.target.value;
    if (value === "" || (parseInt(value) >= 0 && parseInt(value) <= 999)) {
      setMinSquareMeters(value === "" ? "" : parseInt(value));

      if (maxSquareMeters < parseInt(value)) {
        setMaxSquareMeters(parseInt(value) + 1);
      }
    }
  };

  const handleSquareMaxChange = (e) => {
    const value = e.target.value;
    if (value === "" || (parseInt(value) >= 1 && parseInt(value) <= 1000)) {
      setMaxSquareMeters(value === "" ? "" : parseInt(value));

      if (minSquareMeters > parseInt(value)) {
        setMinSquareMeters(parseInt(value) - 1);
      }
    }
  };

  const handleSquareDecrementMin = () => {
    if (minSquareMeters > 0) {
      setMinSquareMeters((prevValue) => prevValue - 1);
    }
  };

  const handleSquareIncrementMin = () => {
    if (minSquareMeters < 999) {
      setMinSquareMeters((prevValue) => prevValue + 1);
      if (minSquareMeters + 1 >= maxSquareMeters) {
        setMaxSquareMeters((prevValue) => prevValue + 1);
      }
    }
  };

  const handleSquareDecrementMax = () => {
    if (maxSquareMeters > 1) {
      setMaxSquareMeters((prevValue) => prevValue - 1);
      if (maxPrice - 1 <= minSquareMeters) {
        setMinSquareMeters((prevValue) => prevValue - 1);
      }
    }
  };

  const handleSquareIncrementMax = () => {
    if (maxSquareMeters < 1000) {
      setMaxSquareMeters((prevValue) => prevValue + 1);
    }
  };

  const handlePriceMinChange = (e) => {
    const value = e.target.value;

    if (!minPriceChanged) {
      setMinPriceChanged(true);
    }

    if (value === "" || (parseInt(value) >= 0 && parseInt(value) <= 9999999)) {
      setMinPrice(value === "" ? "" : parseInt(value));

      if (maxPrice < parseInt(value)) {
        setMaxPrice(parseInt(value) + 1);
      }
    }
  };

  const handlePriceMaxChange = (e) => {
    const value = e.target.value;

    if (!maxPriceChanged) {
      setMaxPriceChanged(true);
    }

    if (value === "" || (parseInt(value) >= 1 && parseInt(value) <= 10000000)) {
      setMaxPrice(value === "" ? "" : parseInt(value));

      if (minPrice > parseInt(value)) {
        setMinPrice(parseInt(value) - 1);
      }
    }
  };

  const handlePriceDecrementMin = () => {
    if (minPrice > 0) {
      setMinPrice((prevValue) => prevValue - 1);
    }
  };

  const handlePriceIncrementMin = () => {
    if (minPrice < 9999999) {
      setMinPrice((prevValue) => prevValue + 1);
      if (minPrice + 1 >= maxPrice) {
        setMaxPrice((prevValue) => prevValue + 1);
      }
    }
  };

  const handlePriceDecrementMax = () => {
    if (maxPrice > 1) {
      setMaxPrice((prevValue) => prevValue - 1);
      if (maxPrice - 1 <= minPrice) {
        setMinPrice((prevValue) => prevValue - 1);
      }
    }
  };

  const handlePriceIncrementMax = () => {
    if (maxSquareMeters < 10000000) {
      setMaxPrice((prevValue) => prevValue + 1);
    }
  };

  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const handleTypeChange = (type) => {
    const currentIndex = selectedTypes.indexOf(type);
    const newSelectedTypes = [...selectedTypes];

    if (currentIndex === -1) {
      newSelectedTypes.push(type);
    } else {
      newSelectedTypes.splice(currentIndex, 1);
    }

    setSelectedTypes(newSelectedTypes);
  };

  const handleRoomChange = (room) => {
    const currentIndex = selectedRooms.indexOf(room);
    const newSelectedRooms = [...selectedRooms];

    if (currentIndex === -1) {
      newSelectedRooms.push(room);
    } else {
      newSelectedRooms.splice(currentIndex, 1);
    }

    setSelectedRooms(newSelectedRooms);
  };

  const handleStateChange = (state) => {
    const currentIndex = selectedStates.indexOf(state);
    const newSelectedStates = [...selectedStates];

    if (currentIndex === -1) {
      newSelectedStates.push(state);
    } else {
      newSelectedStates.splice(currentIndex, 1);
    }

    setSelectedStates(newSelectedStates);
  };

  const handleSaleChange = (sale) => {
    const currentIndex = selectedSales.indexOf(sale);
    const newSelectedSales = [...selectedSales];

    if (currentIndex === -1) {
      newSelectedSales.push(sale);
    } else {
      newSelectedSales.splice(currentIndex, 1);
    }

    setSelectedSales(newSelectedSales);
  };

  const handleSortOptionChange = (option) => {
    setSelectedSortOption(option);
  };

  const toggleFilters = () => setIsOpen(!isOpen);
  const toggleMap = () => setIsMapOpen(!isMapOpen);
  const typeDropdown = () => setIsTypeOpen(!isTypeOpen);
  const roomDropdown = () => setIsRoomOpen(!isRoomOpen);
  const stateDropdown = () => setIsStateOpen(!isStateOpen);
  const saleDropdown = () => setIsSaleOpen(!isSaleOpen);
  const priceDropdown = () => setIsPriceOpen(!isPriceOpen);
  const squareDropdown = () => setIsSquareOpen(!isSquareOpen);
  const sortDropdown = () => setIsSortOpen(!isSortOpen);
  const locationDropdown = () => setIsLocationOpen(!isLocationOpen);

  useEffect(() => {
    const initialIndices = {};
    filteredProperties.forEach((property) => {
      initialIndices[property.property_id] = 0;
    });
    setImageIndices(initialIndices);
  }, [properties]);

  const propertiesGridCode = (property, imageUrls) => {
    // console.log(filteredProperties);
    // console.log(property);

    var currentImageIndex = 0;

    const handlePrevImage = (propertyId) => {
      setImageIndices((prevIndices) => ({
        ...prevIndices,
        [propertyId]:
          prevIndices[propertyId] === 0
            ? imageUrls.length - 1
            : prevIndices[propertyId] - 1,
      }));
    };

    const handleNextImage = (propertyId) => {
      setImageIndices((prevIndices) => ({
        ...prevIndices,
        [propertyId]: (prevIndices[propertyId] + 1) % imageUrls.length,
      }));
    };

    const handlePrevButtonClick = (event, propertyId) => {
      handlePrevImage(propertyId);
      event.preventDefault(); // Evita o redirecionamento
    };

    const handleNextButtonClick = (event, propertyId) => {
      handleNextImage(propertyId);
      event.preventDefault(); // Evita o redirecionamento
    };

    if (selectedGridOption == "threeColumns") {
      return (
        <Link
          key={property.property_id}
          to={`${encodeURIComponent(property.name)}`}
          className="property-card-link"
        >
          <div className="real-estate-column-images">
            <div className="real-estate-image-wrapper">
              {(property.salestate === "Reservado" ||
                property.salestate === "Vendido") && (
                <div>
                  <div
                    className={`reserved-banner ${
                      property.salestate === "Reservado"
                        ? "reserved"
                        : property.salestate === "Vendido"
                        ? "sold"
                        : ""
                    }`}
                  >
                    {property.salestate === "Reservado"
                      ? "Reservado"
                      : "Vendido"}
                  </div>
                  <div
                    className={`banner-triangle ${
                      property.salestate === "Reservado"
                        ? "reserved"
                        : property.salestate === "Vendido"
                        ? "sold"
                        : ""
                    }`}
                  ></div>
                </div>
              )}

              {imageUrls.length > 0 ? (
                <div className="image-container">
                  <img
                    src={imageUrls[imageIndices[property.property_id]]}
                    className="real-estate-image"
                  />

                  {imageUrls.length > 1 && (
                    <div>
                      <button
                        onClick={(event) =>
                          handlePrevButtonClick(
                            event,
                            property.property_id,
                            imageUrls
                          )
                        }
                        className="arrow prev"
                        style={{ zIndex: 1000 }}
                      >
                        ❮
                      </button>
                      <button
                        onClick={(event) =>
                          handleNextButtonClick(event, property.property_id)
                        }
                        className="arrow next"
                        style={{ zIndex: 1000 }}
                      >
                        ❯
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <div className="no-image-container-general">
                  <img className="no-image-icon-general" src={noImageIcon} />
                </div>
              )}
            </div>
            <p className="real-state-text-location">{property.name}</p>
            <p className="real-state-text-location">
              {property.freguesia_name}, {property.municipio_name}
            </p>
            <p className="real-state-text-type">{property.propertytype_name}</p>
            <p className="real-state-text-price">
              {property.promotionprice ? (
                <span>
                  from{" "}
                  <span className="old-price">
                    {formatNumber(property.price)}€
                  </span>{" "}
                  {formatNumber(property.promotionprice)}€
                </span>
              ) : (
                `from ${formatNumber(property.price)}€`
              )}
            </p>
          </div>
        </Link>
      );
    } else {
      return (
        <Link
          key={property.property_id}
          to={`${encodeURIComponent(property.name)}`}
          className="row-property-card-link"
        >
          <div className="real-estate-card-row">
            <div className="real-estate-image-wrapper-row">
              {(property.salestate === "Reservado" ||
                property.salestate === "Vendido") && (
                <div>
                  <div
                    className={`reserved-banner ${
                      property.salestate === "Reservado"
                        ? "reserved"
                        : property.salestate === "Vendido"
                        ? "sold"
                        : ""
                    }`}
                  >
                    {property.salestate === "Reservado"
                      ? "Reservado"
                      : "Vendido"}
                  </div>
                  <div
                    className={`banner-triangle ${
                      property.salestate === "Reservado"
                        ? "reserved"
                        : property.salestate === "Vendido"
                        ? "sold"
                        : ""
                    }`}
                  ></div>
                </div>
              )}

              {imageUrls.length > 0 ? (
                <div className="image-container">
                  <img
                    src={imageUrls[imageIndices[property.property_id]]}
                    className="real-estate-image"
                  />
                  {imageUrls.length > 1 && (
                    <div>
                      <button
                        onClick={(event) =>
                          handlePrevButtonClick(
                            event,
                            property.property_id,
                            imageUrls
                          )
                        }
                        className="arrow prev"
                        style={{ zIndex: 1000 }}
                      >
                        ❮
                      </button>
                      <button
                        onClick={(event) =>
                          handleNextButtonClick(event, property.property_id)
                        }
                        className="arrow next"
                        style={{ zIndex: 1000 }}
                      >
                        ❯
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <div className="no-image-container-general">
                  <img className="no-image-icon-general" src={noImageIcon} />
                </div>
              )}
            </div>
            <div className="real-estate-content-row">
              <p
                className="real-state-text-name-row"
                style={{ marginTop: "0px" }}
              >
                {property.name}
              </p>
              <p
                className="real-state-text-location-row"
                style={{ marginTop: "0px" }}
              >
                {property.freguesia_name}, {property.municipio_name}
              </p>
              <p className="real-state-text-type-row">
                {property.propertytype_name}
              </p>
              <p className="real-state-text-price-row">
                {property.promotionprice ? (
                  <span>
                    from{" "}
                    <span className="old-price">
                      {formatNumber(property.price)}€
                    </span>{" "}
                    {formatNumber(property.promotionprice)}€
                  </span>
                ) : (
                  `from ${formatNumber(property.price)}€`
                )}
              </p>
            </div>
          </div>
        </Link>
      );
    }
  };

  const selectAllTypes = () => {
    const allTypes = propertiesTypes.map((type) => type.name);
    setSelectedTypes(allTypes);
  };

  const clearSelections = () => {
    setSelectedTypes([]);
  };

  const getButtonText = () => {
    if (selectedTypes.length === 0) {
      return "Tipo de Imóvel";
    }

    // Combine os nomes com vírgulas
    const combinedText = selectedTypes.join(", ");

    // Exemplo: Suponha que cada caractere tenha aproximadamente 8px e o container 150px
    const maxApproxWidth = 150; // Largura máxima do container em pixels
    const approxCharWidth = 8; // Largura aproximada de cada caractere

    // Calcula a largura aproximada do texto
    const combinedTextWidth = combinedText.length * approxCharWidth;

    if (combinedTextWidth <= maxApproxWidth) {
      // Se o texto combinado couber no espaço, exiba-o completo
      return combinedText;
    } else {
      // Se não couber, exiba o primeiro tipo mais o contador dos restantes
      return `${selectedTypes[0]} (+${selectedTypes.length - 1})`;
    }
  };

  const handleGridButtonClick = (grid) => {
    // setIsFirstActive(!isFirstActive);
    setIsMapOpen(false);
    setSelectedGridOption(grid);
  };

  const defaultMapOptions = {
    fullscreenControl: false,
  };

  const handleDistritoChange = (distrito) => {
    setSelectedDistrito((prevSelectedDistrito) => {
      const isDistritoSelected = !prevSelectedDistrito[distrito];

      // Atualizar a seleção do distrito
      const updatedDistritos = {
        ...prevSelectedDistrito,
        [distrito]: isDistritoSelected,
      };

      const municipiosDoDistrito =
        regioes
          .flatMap((regiao) => regiao.regiões)
          .find((d) => d.distrito === distrito)?.municipios || [];

      setSelectedMunicipio((prevSelectedMunicipio) => {
        const updatedMunicipios = { ...prevSelectedMunicipio };
        if (!updatedMunicipios[distrito]) {
          updatedMunicipios[distrito] = {};
        }
        municipiosDoDistrito.forEach((municipio) => {
          updatedMunicipios[distrito][municipio.municipio] = isDistritoSelected;
        });
        return updatedMunicipios;
      });

      setSelectedFreguesia((prevSelectedFreguesia) => {
        const updatedFreguesias = { ...prevSelectedFreguesia };

        if (!isDistritoSelected) {
          // Se o distrito foi desselecionado, remover todas as freguesias associadas
          delete updatedFreguesias[distrito];
        } else {
          // Se o distrito foi selecionado, marcar todas as freguesias associadas como selecionadas
          updatedFreguesias[distrito] = {};
          municipiosDoDistrito.forEach((municipio) => {
            updatedFreguesias[distrito][municipio.municipio] = {};
            municipio.freguesias.forEach((freguesia) => {
              updatedFreguesias[distrito][municipio.municipio][
                freguesia.freguesia
              ] = true;
            });
          });
        }

        return updatedFreguesias;
      });

      return updatedDistritos;
    });
  };

  const handleMunicipioChange = (distrito, municipio) => {
    setSelectedMunicipio((prevSelectedMunicipio) => {
      const isMunicipioSelected = !prevSelectedMunicipio[distrito]?.[municipio];

      // Preparar para atualizar as freguesias com base no novo estado do município
      const freguesiasDoMunicipio =
        regioes
          .flatMap((regiao) => regiao.regiões)
          .find((d) => d.distrito === distrito)
          ?.municipios.find((m) => m.municipio === municipio)?.freguesias || [];

      setSelectedFreguesia((prevSelectedFreguesia) => {
        const updatedFreguesias = { ...prevSelectedFreguesia };

        if (!isMunicipioSelected) {
          // Se o município foi desselecionado, remove suas freguesias
          delete updatedFreguesias[distrito]?.[municipio];
        } else {
          // Se o município foi selecionado, adiciona todas as freguesias
          updatedFreguesias[distrito] = {
            ...(updatedFreguesias[distrito] || {}),
            [municipio]: freguesiasDoMunicipio.reduce((acc, freguesia) => {
              acc[freguesia.freguesia] = true;
              return acc;
            }, {}),
          };
        }

        // Remover o distrito se todos os municípios foram desselecionados
        if (Object.keys(updatedFreguesias[distrito] || {}).length === 0) {
          delete updatedFreguesias[distrito];
        }

        return updatedFreguesias;
      });

      return {
        ...prevSelectedMunicipio,
        [distrito]: {
          ...prevSelectedMunicipio[distrito],
          [municipio]: isMunicipioSelected,
        },
      };
    });
  };

  const handleFreguesiaChange = (distrito, municipio, freguesia) => {
    setSelectedFreguesia((prevSelectedFreguesia) => {
      const updatedFreguesias = { ...prevSelectedFreguesia };

      const updatedMunicipioFreguesias = {
        ...(prevSelectedFreguesia[distrito]?.[municipio] || {}),
        [freguesia]: !prevSelectedFreguesia[distrito]?.[municipio]?.[freguesia],
      };

      updatedFreguesias[distrito] = {
        ...(prevSelectedFreguesia[distrito] || {}),
        [municipio]: updatedMunicipioFreguesias,
      };

      // Verifica se não há mais freguesias selecionadas para o município
      const isMunicipioEmpty = Object.values(updatedMunicipioFreguesias).every(
        (isSelected) => !isSelected
      );
      if (isMunicipioEmpty) {
        delete updatedFreguesias[distrito][municipio];
      }

      // Verifica se não há mais municípios selecionados para o distrito
      const isDistritoEmpty =
        Object.keys(updatedFreguesias[distrito] || {}).length === 0;
      if (isDistritoEmpty) {
        delete updatedFreguesias[distrito];
      }

      return updatedFreguesias;
    });
  };

  const toggleMunicipio = (distrito) => {
    setOpenMunicipio((prevOpenMunicipio) => ({
      ...prevOpenMunicipio,
      [distrito]: !prevOpenMunicipio[distrito],
    }));
  };

  const toggleFreguesia = (distrito, municipio) => {
    setOpenFreguesia((prevOpenFreguesia) => ({
      ...prevOpenFreguesia,
      [distrito]: {
        ...prevOpenFreguesia[distrito],
        [municipio]: !prevOpenFreguesia[distrito]?.[municipio],
      },
    }));
  };

  return (
    <div className="app">
      {/* <div className="sidebar-container"> */}
      {/* <NavbarPT visible={true} /> */}
      {/* <nav className={`navbar ${visible ? "" : "hidden"}`}>
        <h1>Navbar</h1>
      </nav> */}
      <SideBarPT />
      {/* </div> */}
      {/* <div
        className={`dashboard-real-estate-container ${
          !isLoading
            ? !isMapOpen
              ? filteredProperties.length > 0
                ? selectedGridOption === "threeColumns"
                  ? rowsInSection === 1
                    ? "threeColumns-oneLine"
                    : rowsInSection === 2
                    ? "threeColumns-twoLines"
                    : "threeColumns-threeLines"
                  : currentProperties.length == 1
                  ? "oneRow"
                  : currentProperties.length == 2
                  ? "twoRows"
                  : currentProperties.length == 3
                  ? "threeRows"
                  : currentProperties.length == 4
                  ? "fourRows"
                  : currentProperties.length == 5
                  ? "fiveRows"
                  : currentProperties.length == 6
                  ? "sixRows"
                  : currentProperties.length == 7
                  ? "sevenRows"
                  : currentProperties.length == 8
                  ? "eightRows"
                  : currentProperties.length == 9
                  ? "nineRows"
                  : "threeRows-threeLines"
                : "threeColumns-oneLine"
              : "map"
            : "loading"
        }`}
      > */}
      <div
        className={`real-estate-container ${isLoading ? "loading" : ""}`}
        style={{
          height:
            properties.length === 0 ||
            filteredProperties.length === 0 ||
            properties.length === 1 ||
            filteredProperties.length === 1
              ? "100%"
              : "",
        }}
      >
        <div
          className="real-estate-content"
          style={{
            marginLeft: "100px",
            height:
              (!isLoading && properties.length === 0) ||
              filteredProperties.length === 0
                ? "70%"
                : "",
          }}
        >
          {/* <div className="real-estate-column"> */}
          <div
            className={`dashboard-title-container ${
              isLoading ? "loading" : ""
            }`}
          >
            <h2 className="dashboard-real-estate-title">IMÓVEIS</h2>
            <div className="dashboard-title-buttons-container">
              <Link
                to="/dashboard/imoveis/destaque"
                className="dashboard-real-estate-header-button"
              >
                Imóveis em destaque
              </Link>
              <Link
                to="/dashboard/imoveis/adicionar"
                className="dashboard-real-estate-header-button"
                // onClick={() => setIsPopupOpen(true)}
              >
                Adicionar imóveis
              </Link>
            </div>
          </div>

          {isLoading ? (
            <Loading />
          ) : (
            <div
              className="real-estate-page-container"
              style={{
                height:
                  (!isLoading && properties.length === 0) ||
                  filteredProperties.length === 0
                    ? "70%"
                    : "",
              }}
            >
              <div className="filters-bar-container">
                <div className="filters-bar-content">
                  <div className="search-container">
                    <input
                      className="search-input"
                      placeholder="Nome do imóvel"
                      value={searchInput}
                      onChange={(event) => setSearchInput(event.target.value)}
                    />
                    <div className="location-container">
                      <button
                        onClick={locationDropdown}
                        className="location-button"
                      >
                        Localização
                        <span
                          className={`dropdown-arrow`}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <svg
                            className={`arrow-down-icon ${
                              selectedSortOption != null && !isLocationOpen
                                ? "close"
                                : ""
                            }`}
                            fill="#000000"
                            onClick={() => {
                              if (
                                selectedSortOption != null &&
                                !isLocationOpen
                              ) {
                                setSelectedSortOption(null);
                              }
                            }}
                          >
                            <path
                              d={
                                !isLocationOpen
                                  ? "M7 5.586L1.707.293A1 1 0 1 0 .293 1.707l6 6a1 1 0 0 0 1.414 0l6-6a1 1 0 0 0 0-1.414 1 1 0 0 0-1.414 0L7 5.586z"
                                  : "M7 2.414l5.293 5.293a1 1 0 0 0 1.414-1.414l-6-6a1 1 0 0 0-1.414 0l-6 6a1 1 0 1 0 1.414 1.414L7 2.414z"
                              }
                              fill-rule="evenodd"
                            ></path>
                          </svg>
                        </span>
                      </button>
                    </div>
                    {isLocationOpen && !isMapOpen && (
                      <div className="location-dropdown">
                        <ul>
                          {regioes.map((regiao) => (
                            <div key={regiao.regiao}>
                              {regiao.regiões.map((distrito) => (
                                <li key={distrito.distrito}>
                                  <div
                                    className="location-dropdown-row"
                                    onClick={(e) => {
                                      toggleMunicipio(distrito.distrito);
                                    }}
                                  >
                                    <label onClick={(e) => e.stopPropagation()}>
                                      <input
                                        type="checkbox"
                                        onChange={() =>
                                          handleDistritoChange(
                                            distrito.distrito
                                          )
                                        }
                                        checked={
                                          !!selectedDistrito[distrito.distrito]
                                        }
                                        className="location-checkbox-item"
                                      />
                                      {distrito.distrito}
                                    </label>
                                    <span
                                      className="dropdown-arrow"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        toggleMunicipio(distrito.distrito);
                                      }}
                                    >
                                      <svg
                                        className={`arrow-down-icon ${
                                          openMunicipio[distrito.distrito]
                                            ? "open"
                                            : ""
                                        }`}
                                        fill="#000000"
                                      >
                                        <path
                                          d={
                                            openMunicipio[distrito.distrito]
                                              ? "M7 2.414l5.293 5.293a1 1 0 0 0 1.414-1.414l-6-6a1 1 0 0 0-1.414 0l-6 6a1 1 0 1 0 1.414 1.414L7 2.414z"
                                              : "M7 5.586L1.707.293A1 1 0 1 0 .293 1.707l6 6a1 1 0 0 0 1.414 0l6-6a1 1 0 0 0 0-1.414 1 1 0 0 0-1.414 0L7 5.586z"
                                          }
                                          fillRule="evenodd"
                                        ></path>
                                      </svg>
                                    </span>
                                  </div>
                                  {openMunicipio[distrito.distrito] && (
                                    <ul className="sub-checkboxes">
                                      {distrito.municipios.map((municipio) => (
                                        <li
                                          key={municipio.municipio}
                                          style={{ paddingRight: 0 }}
                                        >
                                          <div
                                            className="location-dropdown-row"
                                            onClick={(e) => {
                                              toggleFreguesia(
                                                distrito.distrito,
                                                municipio.municipio
                                              );
                                            }}
                                          >
                                            <label
                                              onClick={(e) =>
                                                e.stopPropagation()
                                              }
                                            >
                                              <input
                                                type="checkbox"
                                                onChange={() =>
                                                  handleMunicipioChange(
                                                    distrito.distrito,
                                                    municipio.municipio
                                                  )
                                                }
                                                checked={
                                                  !!selectedMunicipio[
                                                    distrito.distrito
                                                  ]?.[municipio.municipio] ||
                                                  false
                                                }
                                                className="location-checkbox-item"
                                              />
                                              {municipio.municipio}
                                            </label>
                                            <span
                                              className="dropdown-arrow"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                toggleFreguesia(
                                                  distrito.distrito,
                                                  municipio.municipio
                                                );
                                              }}
                                            >
                                              <svg
                                                className={`arrow-down-icon ${
                                                  openFreguesia[
                                                    distrito.distrito
                                                  ]?.[municipio.municipio]
                                                    ? "open"
                                                    : ""
                                                }`}
                                                fill="#000000"
                                              >
                                                <path
                                                  d={
                                                    openFreguesia[
                                                      distrito.distrito
                                                    ]?.[municipio.municipio]
                                                      ? "M7 2.414l5.293 5.293a1 1 0 0 0 1.414-1.414l-6-6a1 1 0 0 0-1.414 0l-6 6a1 1 0 1 0 1.414 1.414L7 2.414z"
                                                      : "M7 5.586L1.707.293A1 1 0 1 0 .293 1.707l6 6a1 1 0 0 0 1.414 0l6-6a1 1 0 0 0 0-1.414 1 1 0 0 0-1.414 0L7 5.586z"
                                                  }
                                                  fill-rule="evenodd"
                                                ></path>
                                              </svg>
                                            </span>
                                          </div>
                                          {openFreguesia[distrito.distrito]?.[
                                            municipio.municipio
                                          ] && (
                                            <ul className="sub-sub-checkboxes">
                                              {municipio.freguesias.map(
                                                (freguesia) => (
                                                  <li>
                                                    <label>
                                                      <input
                                                        type="checkbox"
                                                        onChange={() =>
                                                          handleFreguesiaChange(
                                                            distrito.distrito,
                                                            municipio.municipio,
                                                            freguesia.freguesia
                                                          )
                                                        }
                                                        checked={
                                                          !!selectedFreguesia[
                                                            distrito.distrito
                                                          ]?.[
                                                            municipio.municipio
                                                          ]?.[
                                                            freguesia.freguesia
                                                          ] || false
                                                        }
                                                        className="location-checkbox-item"
                                                      />
                                                      {freguesia.freguesia}
                                                    </label>
                                                  </li>
                                                )
                                              )}
                                            </ul>
                                          )}
                                        </li>
                                      ))}
                                    </ul>
                                  )}
                                </li>
                              ))}
                            </div>
                          ))}
                        </ul>
                      </div>
                    )}

                    <div className="search-container">
                      <FontAwesomeIcon
                        icon={faSearch}
                        className="search-icon"
                      />
                    </div>
                  </div>

                  <div className="header-row">
                    <button onClick={toggleFilters} className="filters-button">
                      <div className="filters-text">
                        {!isOpen ? "Mostrar filtros" : "Ocultar filtros"}
                      </div>
                      <img src={filtersIcon} className="filters-icon" />
                    </button>

                    {!isMapOpen && (
                      <button
                        onClick={sortDropdown}
                        className="filters-button-sort"
                      >
                        <div className="filters-text-sort">
                          {selectedSortOption != null
                            ? `${selectedSortOption}`
                            : "Ordenar por"}
                        </div>
                        <span
                          className={`dropdown-arrow`}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <svg
                            className={`arrow-down-icon ${
                              selectedSortOption != null && !isSortOpen
                                ? "close"
                                : ""
                            }`}
                            fill="#ffffff"
                            onClick={() => {
                              if (selectedSortOption != null && !isSortOpen) {
                                setSelectedSortOption(null);
                              }
                            }}
                          >
                            <path
                              d={
                                selectedSortOption == null
                                  ? !isSortOpen
                                    ? "M7 5.586L1.707.293A1 1 0 1 0 .293 1.707l6 6a1 1 0 0 0 1.414 0l6-6a1 1 0 0 0 0-1.414 1 1 0 0 0-1.414 0L7 5.586z"
                                    : "M7 2.414l5.293 5.293a1 1 0 0 0 1.414-1.414l-6-6a1 1 0 0 0-1.414 0l-6 6a1 1 0 1 0 1.414 1.414L7 2.414z"
                                  : !isSortOpen
                                  ? "M7 5.586L12.293.293a1 1 0 0 1 1.414 0 1 1 0 0 1 0 1.414L8.414 7l5.293 5.293a1 1 0 0 1-1.414 1.414L7 8.414l-5.293 5.293a1 1 0 0 1-1.414 0 1 1 0 0 1 0-1.414L5.586 7 .293 1.707A1 1 0 1 1 1.707.293L7 5.586z"
                                  : "M7 2.414l5.293 5.293a1 1 0 0 0 1.414-1.414l-6-6a1 1 0 0 0-1.414 0l-6 6a1 1 0 1 0 1.414 1.414L7 2.414z"
                              }
                              fill-rule="evenodd"
                            ></path>
                          </svg>
                        </span>
                      </button>
                    )}
                    {isSortOpen && !isMapOpen && (
                      <div className="sort-dropdown">
                        <ul>
                          <li
                            onClick={() =>
                              handleSortOptionChange("Preço ascendente")
                            }
                            className={
                              selectedSortOption === "Preço ascendente"
                                ? "active"
                                : ""
                            }
                          >
                            Preço ascendente
                          </li>
                          <div className="filters-line-container">
                            <div className="filters-line sort" />
                          </div>
                          <li
                            onClick={() =>
                              handleSortOptionChange("Preço descendente")
                            }
                            className={
                              selectedSortOption === "Preço descendente"
                                ? "active"
                                : ""
                            }
                          >
                            Preço descendente
                          </li>
                          <div className="filters-line-container">
                            <div className="filters-line sort" />
                          </div>
                          <li
                            onClick={() =>
                              handleSortOptionChange("Mais recentes")
                            }
                            className={
                              selectedSortOption === "Mais recentes"
                                ? "active"
                                : ""
                            }
                          >
                            Mais recentes
                          </li>
                          <div className="filters-line-container">
                            <div className="filters-line sort" />
                          </div>
                          <li
                            onClick={() =>
                              handleSortOptionChange("Mais antigos")
                            }
                            className={
                              selectedSortOption === "Mais antigos"
                                ? "active"
                                : ""
                            }
                          >
                            Mais antigos
                          </li>
                          <div className="filters-line-container">
                            <div className="filters-line sort" />
                          </div>
                          <li
                            onClick={() => handleSortOptionChange("Maior área")}
                            className={
                              selectedSortOption === "Maior área"
                                ? "active"
                                : ""
                            }
                          >
                            Maior área
                          </li>
                          <div className="filters-line-container">
                            <div className="filters-line sort" />
                          </div>
                          <li
                            onClick={() => handleSortOptionChange("Menor área")}
                            className={
                              selectedSortOption === "Menor área"
                                ? "active"
                                : ""
                            }
                          >
                            Menor área
                          </li>
                          <div className="filters-line-container">
                            <div className="filters-line sort" />
                          </div>
                          <li
                            onClick={() =>
                              handleSortOptionChange("Mais visualizados")
                            }
                            className={
                              selectedSortOption === "Mais visualizados"
                                ? "active"
                                : ""
                            }
                          >
                            Mais visualizados
                          </li>
                          <div className="filters-line-container">
                            <div className="filters-line sort" />
                          </div>
                          <li
                            onClick={() =>
                              handleSortOptionChange("Menos visualizados")
                            }
                            className={
                              selectedSortOption === "Menos visualizados"
                                ? "active"
                                : ""
                            }
                          >
                            Menos visualizados
                          </li>
                        </ul>
                      </div>
                    )}

                    <button
                      className="filters-button-elements"
                      onClick={() => handleGridButtonClick("threeColumns")}
                    >
                      <img
                        src={
                          selectedGridOption == "threeColumns" && !isMapOpen
                            ? threeColumnActive
                            : threeColumn
                        }
                        className="filters-grid-icon"
                      />
                    </button>
                    <button
                      className="filters-button-elements"
                      onClick={() => handleGridButtonClick("threeRows")}
                    >
                      <img
                        src={
                          selectedGridOption == "threeRows" && !isMapOpen
                            ? threeRowsActive
                            : threeRows
                        }
                        className="filters-grid-icon"
                      />
                    </button>

                    {/* <button
                        onClick={toggleMap}
                        className="filters-button-map"
                      >
                        <img
                          src={isMapOpen ? mapActive : map}
                          className="filters-icon"
                          style={{
                            height: "25px",
                            width: "25px",
                            paddingBottom: "7px",
                          }}
                        />
                      </button> */}
                  </div>
                </div>
              </div>
              <div
                className={`properties-container-top ${
                  isOpen ? "menu-open" : ""
                }`}
              >
                {isOpen && (
                  <div
                    // className={`filters-menu ${isMapOpen ? "map" : ""} ${
                    //   !isMapOpen ? (menuFixed ? "fixed" : "") : ""
                    // }
                    className={`filters-menu ${isMapOpen ? "map" : ""} ${
                      !isMapOpen ? (menuFixed ? "fixed" : "") : ""
                    } ${
                      scrollPos > posToFix
                        ? selectedGridOption === "threeColumns"
                          ? "down"
                          : currentProperties.length == 1
                          ? "oneRow-down"
                          : currentProperties.length == 2
                          ? "twoRows-down"
                          : currentProperties.length == 3
                          ? "threeRows-down"
                          : currentProperties.length == 4
                          ? "fourRows-down"
                          : currentProperties.length == 5
                          ? "fiveRows-down"
                          : currentProperties.length == 6
                          ? "sixRows-down"
                          : currentProperties.length == 7
                          ? "sevenRows-down"
                          : currentProperties.length == 8
                          ? "eightRows-down"
                          : currentProperties.length == 9
                          ? "nineRows-down"
                          : ""
                        : ""
                    } ${
                      // !isMapOpen
                      //   ? !isLoading
                      !isLoading
                        ? filteredProperties.length > 0
                          ? selectedGridOption === "threeColumns"
                            ? rowsInSection === 1
                              ? "threeColumns-oneLine"
                              : rowsInSection === 2
                              ? "threeColumns-twoLines"
                              : "threeColumns-threeLines"
                            : currentProperties.length == 1
                            ? "oneRow"
                            : currentProperties.length == 2
                            ? "twoRows"
                            : currentProperties.length == 3
                            ? "threeRows"
                            : currentProperties.length == 4
                            ? "fourRows"
                            : currentProperties.length == 5
                            ? "fiveRows"
                            : currentProperties.length == 6
                            ? "sixRows"
                            : currentProperties.length == 7
                            ? "sevenRows"
                            : currentProperties.length == 8
                            ? "eightRows"
                            : currentProperties.length == 9
                            ? "nineRows"
                            : "threeRows-threeLines"
                          : "threeColumns-oneLine"
                        : "loading"
                      // : ""
                    }`}
                  >
                    <div
                      className={`filters-menu-content  ${
                        !isMapOpen ? (menuFixed ? "fixed" : "") : ""
                      }`}
                    >
                      <p className="filters-menu-title">
                        Imóveis ({filteredProperties.length})
                      </p>
                      <div className="filters-menu-options-container">
                        <div className="filters-line" />
                        <div>
                          <button
                            onClick={typeDropdown}
                            className={`dropdown-option ${
                              !isTypeOpen ? "open" : ""
                            }`}
                          >
                            Tipo de imóveis{" "}
                            {selectedTypes.length > 0
                              ? `(${selectedTypes.length})`
                              : ""}
                            <span
                              className={`dropdown-arrow`}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <svg className={`arrow-down-icon`} fill="#ffffff">
                                <path
                                  d={
                                    !isTypeOpen
                                      ? "M7 5.586L1.707.293A1 1 0 1 0 .293 1.707l6 6a1 1 0 0 0 1.414 0l6-6a1 1 0 0 0 0-1.414 1 1 0 0 0-1.414 0L7 5.586z"
                                      : "M7 2.414l5.293 5.293a1 1 0 0 0 1.414-1.414l-6-6a1 1 0 0 0-1.414 0l-6 6a1 1 0 1 0 1.414 1.414L7 2.414z"
                                  }
                                  fill-rule="evenodd"
                                ></path>
                              </svg>
                            </span>
                          </button>
                          {isTypeOpen && (
                            <div className="dropdown-menu">
                              <div className="checkbox-container">
                                {propertiesTypes.map((type) => (
                                  <div class="checkbox-wrapper">
                                    <input
                                      type="checkbox"
                                      className="checkbox-item"
                                      value={type.name}
                                      checked={selectedTypes.includes(
                                        type.name
                                      )}
                                      onChange={() =>
                                        handleTypeChange(type.name)
                                      }
                                    />
                                    <label class="checkbox-text">
                                      {type.name}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="filters-line" />
                        <div>
                          <button
                            onClick={roomDropdown}
                            className={`dropdown-option ${
                              !isRoomOpen ? "open" : ""
                            }`}
                          >
                            Tipologia{" "}
                            {selectedRooms.length > 0
                              ? `(${selectedRooms.length})`
                              : ""}
                            <span
                              className={`dropdown-arrow`}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <svg className={`arrow-down-icon`} fill="#ffffff">
                                <path
                                  d={
                                    !isRoomOpen
                                      ? "M7 5.586L1.707.293A1 1 0 1 0 .293 1.707l6 6a1 1 0 0 0 1.414 0l6-6a1 1 0 0 0 0-1.414 1 1 0 0 0-1.414 0L7 5.586z"
                                      : "M7 2.414l5.293 5.293a1 1 0 0 0 1.414-1.414l-6-6a1 1 0 0 0-1.414 0l-6 6a1 1 0 1 0 1.414 1.414L7 2.414z"
                                  }
                                  fill-rule="evenodd"
                                ></path>
                              </svg>
                            </span>
                          </button>
                          {isRoomOpen && (
                            <div className="dropdown-menu">
                              <div className="checkbox-container">
                                {propertiesRooms.map((room) => (
                                  // <div
                                  //   key={type.propertytype_id}
                                  //   className="checkbox-item"
                                  // >
                                  //   <label>
                                  //     <input
                                  //       type="checkbox"
                                  //       value={type.name}
                                  //       checked={selectedTypes.includes(type.name)}
                                  //       onChange={() => handleTypeChange(type.name)}
                                  //     />
                                  //     {type.name}
                                  //   </label>
                                  // </div>
                                  <div class="checkbox-wrapper">
                                    <input
                                      type="checkbox"
                                      className="checkbox-item"
                                      value={room.room}
                                      checked={selectedRooms.includes(
                                        room.room
                                      )}
                                      onChange={() =>
                                        handleRoomChange(room.room)
                                      }
                                    />
                                    <label class="checkbox-text">
                                      {room.room}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="filters-line" />
                        <div>
                          <button
                            onClick={stateDropdown}
                            className={`dropdown-option ${
                              !isStateOpen ? "open" : ""
                            }`}
                          >
                            Estado{" "}
                            {selectedStates.length > 0
                              ? `(${selectedStates.length})`
                              : ""}
                            <span
                              className={`dropdown-arrow`}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <svg className={`arrow-down-icon`} fill="#ffffff">
                                <path
                                  d={
                                    !isStateOpen
                                      ? "M7 5.586L1.707.293A1 1 0 1 0 .293 1.707l6 6a1 1 0 0 0 1.414 0l6-6a1 1 0 0 0 0-1.414 1 1 0 0 0-1.414 0L7 5.586z"
                                      : "M7 2.414l5.293 5.293a1 1 0 0 0 1.414-1.414l-6-6a1 1 0 0 0-1.414 0l-6 6a1 1 0 1 0 1.414 1.414L7 2.414z"
                                  }
                                  fill-rule="evenodd"
                                ></path>
                              </svg>
                            </span>
                          </button>
                          {isStateOpen && (
                            <div className="dropdown-menu">
                              <div className="checkbox-container">
                                {propertiesStates.map((state) => (
                                  // <div
                                  //   key={type.propertytype_id}
                                  //   className="checkbox-item"
                                  // >
                                  //   <label>
                                  //     <input
                                  //       type="checkbox"
                                  //       value={type.name}
                                  //       checked={selectedTypes.includes(type.name)}
                                  //       onChange={() => handleTypeChange(type.name)}
                                  //     />
                                  //     {type.name}
                                  //   </label>
                                  // </div>
                                  <div class="checkbox-wrapper">
                                    <input
                                      type="checkbox"
                                      className="checkbox-item"
                                      value={state.state}
                                      checked={selectedStates.includes(
                                        state.state
                                      )}
                                      onChange={() =>
                                        handleStateChange(state.state)
                                      }
                                    />
                                    <label class="checkbox-text">
                                      {state.state}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="filters-line" />
                        <div>
                          <button
                            onClick={saleDropdown}
                            className={`dropdown-option ${
                              !isSaleOpen ? "open" : ""
                            }`}
                          >
                            Venda{" "}
                            {selectedSales.length > 0
                              ? `(${selectedSales.length})`
                              : ""}
                            <span
                              className={`dropdown-arrow`}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <svg className={`arrow-down-icon`} fill="#ffffff">
                                <path
                                  d={
                                    !isSaleOpen
                                      ? "M7 5.586L1.707.293A1 1 0 1 0 .293 1.707l6 6a1 1 0 0 0 1.414 0l6-6a1 1 0 0 0 0-1.414 1 1 0 0 0-1.414 0L7 5.586z"
                                      : "M7 2.414l5.293 5.293a1 1 0 0 0 1.414-1.414l-6-6a1 1 0 0 0-1.414 0l-6 6a1 1 0 1 0 1.414 1.414L7 2.414z"
                                  }
                                  fill-rule="evenodd"
                                ></path>
                              </svg>
                            </span>
                          </button>
                          {isSaleOpen && (
                            <div className="dropdown-menu">
                              <div className="checkbox-container">
                                {propertiesSales.map((sale) => (
                                  // <div
                                  //   key={type.propertytype_id}
                                  //   className="checkbox-item"
                                  // >
                                  //   <label>
                                  //     <input
                                  //       type="checkbox"
                                  //       value={type.name}
                                  //       checked={selectedTypes.includes(type.name)}
                                  //       onChange={() => handleTypeChange(type.name)}
                                  //     />
                                  //     {type.name}
                                  //   </label>
                                  // </div>
                                  <div class="checkbox-wrapper">
                                    <input
                                      type="checkbox"
                                      className="checkbox-item"
                                      value={sale.sale}
                                      checked={selectedSales.includes(
                                        sale.sale
                                      )}
                                      onChange={() =>
                                        handleSaleChange(sale.sale)
                                      }
                                    />
                                    <label class="checkbox-text">
                                      {sale.sale}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="filters-line" />
                        <div>
                          <button
                            onClick={priceDropdown}
                            className={`dropdown-option ${
                              !isPriceOpen ? "open" : ""
                            }`}
                          >
                            Preço
                            <span
                              className={`dropdown-arrow`}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <svg className={`arrow-down-icon`} fill="#ffffff">
                                <path
                                  d={
                                    !isPriceOpen
                                      ? "M7 5.586L1.707.293A1 1 0 1 0 .293 1.707l6 6a1 1 0 0 0 1.414 0l6-6a1 1 0 0 0 0-1.414 1 1 0 0 0-1.414 0L7 5.586z"
                                      : "M7 2.414l5.293 5.293a1 1 0 0 0 1.414-1.414l-6-6a1 1 0 0 0-1.414 0l-6 6a1 1 0 1 0 1.414 1.414L7 2.414z"
                                  }
                                  fill-rule="evenodd"
                                ></path>
                              </svg>
                            </span>
                          </button>
                          {isPriceOpen && (
                            <div className="dropdown-price-menu">
                              <div className="price-input-container">
                                <input
                                  id="minPrice"
                                  type="text"
                                  value={minPriceChanged ? minPrice : ""}
                                  onFocus={() => setMinPrice("")}
                                  onBlur={() => {
                                    if (minPrice === "") setMinPrice("");
                                  }}
                                  onChange={handlePriceMinChange}
                                  className="input-text"
                                  placeholder="Preço min."
                                />
                                <span className="currency">€</span>
                              </div>

                              <div className="price-input-container">
                                <input
                                  id="maxPrice"
                                  type="text"
                                  value={maxPriceChanged ? maxPrice : ""}
                                  onFocus={() => setMaxPrice("")}
                                  onBlur={() => {
                                    if (maxPrice === "") setMaxPrice("");
                                  }}
                                  onChange={handlePriceMaxChange}
                                  className="input-text"
                                  placeholder="Preço max."
                                />
                                <span className="currency">€</span>
                              </div>

                              <div class="checkbox-wrapper">
                                <input
                                  type="checkbox"
                                  className="checkbox-item"
                                  checked={isPromotion}
                                  onChange={() => setIsPromotion(!isPromotion)}
                                />
                                <label class="checkbox-text">
                                  Imóveis em promoção?
                                </label>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="filters-line" />
                        <div>
                          <button
                            onClick={squareDropdown}
                            className={`dropdown-option ${
                              !isSquareOpen ? "open" : ""
                            }`}
                          >
                            Área
                            <span
                              className={`dropdown-arrow`}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <svg className={`arrow-down-icon`} fill="#ffffff">
                                <path
                                  d={
                                    !isSquareOpen
                                      ? "M7 5.586L1.707.293A1 1 0 1 0 .293 1.707l6 6a1 1 0 0 0 1.414 0l6-6a1 1 0 0 0 0-1.414 1 1 0 0 0-1.414 0L7 5.586z"
                                      : "M7 2.414l5.293 5.293a1 1 0 0 0 1.414-1.414l-6-6a1 1 0 0 0-1.414 0l-6 6a1 1 0 1 0 1.414 1.414L7 2.414z"
                                  }
                                  fill-rule="evenodd"
                                ></path>
                              </svg>
                            </span>
                          </button>
                          {isSquareOpen && (
                            <div className="dropdown-price-menu">
                              <div className="price-input-container">
                                <input
                                  id="minSquareMeters"
                                  type="text"
                                  value={
                                    minSquareMetersChanged
                                      ? minSquareMeters
                                      : ""
                                  }
                                  onFocus={() => setMinSquareMeters("")}
                                  onBlur={() => {
                                    if (minSquareMeters === "")
                                      setMinSquareMeters("");
                                  }}
                                  onChange={handleSquareMinChange}
                                  className="input-text"
                                  placeholder="Área min."
                                />
                                <span className="currency-square">
                                  m<sup>2</sup>
                                </span>
                              </div>

                              <div className="price-input-container">
                                <input
                                  id="maxSquareMeters"
                                  type="text"
                                  value={
                                    maxSquareMetersChanged
                                      ? maxSquareMeters
                                      : ""
                                  }
                                  onFocus={() => setMaxSquareMeters("")}
                                  onBlur={() => {
                                    if (maxSquareMeters === "") setMaxPrice("");
                                  }}
                                  onChange={handleSquareMaxChange}
                                  className="input-text price"
                                  placeholder="Área max."
                                />
                                <span className="currency-square">
                                  m<sup>2</sup>
                                </span>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="filters-line" />
                        <div className="filters-menu-final-row">
                          <button
                            className="filters-clear-button"
                            onClick={clearFilters}
                          >
                            Limpar Filtros
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {!isMapOpen ? (
                  <div
                    className={`properties-container ${
                      filteredProperties.length > 0
                        ? selectedGridOption === "threeColumns"
                          ? "threeColumns"
                          : "threeRows"
                        : ""
                    }
                     ${isOpen ? "menu-open" : ""} ${menuFixed ? "fixed" : ""}`}
                  >
                    {currentProperties.map((property) => {
                      const imageUrls = imageList.filter((url) =>
                        url.includes(`property_${property.property_id}`)
                      );
                      return propertiesGridCode(property, imageUrls);
                    })}
                    {(properties.length === 0 ||
                      (filteredProperties.length === 0 && searchInput) ||
                      (selectedPropertyTypeName &&
                        properties.filter(
                          (property) =>
                            property.propertytype_name ===
                            selectedPropertyTypeName
                        ).length === 0) ||
                      (selectedPropertyRoom &&
                        properties.filter(
                          (property) => property.room === selectedPropertyRoom
                        ).length === 0) ||
                      (selectedPropertyState &&
                        properties.filter(
                          (property) => property.state === selectedPropertyState
                        ).length === 0) ||
                      (selectedPropertySale &&
                        properties.filter(
                          (property) => property.sale === selectedPropertySale
                        ).length === 0) ||
                      filteredProperties.every(
                        (property) =>
                          parseInt(property.squareMeters) >=
                            parseInt(minSquareMeters) &&
                          parseInt(property.squareMeters) <=
                            parseInt(maxSquareMeters)
                      ) ||
                      filteredProperties.every(
                        (property) =>
                          parseInt(property.price) <= parseInt(minPrice) &&
                          parseInt(property.price) >= parseInt(maxPrice)
                      )) && (
                      <div className="properties-error-container">
                        <p className="properties-error">
                          Não existem imóveis que satisfaçam a sua pesquisa!
                        </p>
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    className={`map-window ${isOpen ? "menu-open" : ""}`}
                    // className={`map-window ${isOpen ? "menu-open" : ""} ${
                    //   menuFixed ? "fixed" : ""
                    // }`}
                    // className="map-window"
                  >
                    {/* <GoogleMap
                        mapContainerStyle={{ width: "100%", height: "100%" }}
                        center={{
                          lat: 41.46995886081861,
                          lng: -8.544392363028576,
                        }}
                        zoom={10}
                        options={{
                          fullscreenControl: false,
                          zoomControl: false,
                          streetViewControl: false,
                          mapTypeControl: false,
                        }}
                        className="custom-cursor"
                      >
                        <></>
                      </GoogleMap> */}

                    <MapProvider>
                      {console.log("-----------123------------")}
                      <AppMap
                        onShapeDraw={(polyline) => console.log(polyline)}
                        filteredProperties={filteredProperties}
                      />
                    </MapProvider>
                  </div>
                )}
              </div>
              {!isMapOpen && filteredProperties.length > propertiesPerPage && (
                <div className="pagination">
                  <button
                    className="button-cancel"
                    style={{ width: "87px", marginRight: "10px" }}
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange(currentPage - 1)}
                  >
                    Anterior
                  </button>
                  {renderPaginationButtons()}
                  <button
                    className="close-button-confirm"
                    style={{ width: "87px", marginLeft: "10px" }}
                    disabled={currentPage === totalPages}
                    onClick={() => handlePageChange(currentPage + 1)}
                  >
                    Próxima
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
        {/* </div> */}
      </div>
      {/* </div> */}
    </div>
  );
}
