import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./PageNotFound.css";
import NavbarPT from "../../components/Navbar/Navbar_PT";
import PageNotFoundIcon from "../../assets/images/page_not_found.png";

export default function PageNotFound() {
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    const userLanguage = navigator.language || navigator.userLanguage;
    if (userLanguage.includes("pt")) {
      setLanguage("pt");
    } else {
      setLanguage("en");
    }
  }, []);

  return (
    <div className="app">
      {/* <NavbarPT /> */}
      <div className="page-not-found-container">
        <img
          src={PageNotFoundIcon}
          className="page-not-found-icon"
          alt="Page Not Found"
        />
        {language === "pt" ? (
          <>
            <h1
              className="page-not-found-title"
              style={{ color: "#c28e2b", marginTop: 10 }}
            >
              Ups! Página não encontrada
            </h1>
            <p className="page-not-found-text">
              É possível que tenha introduzido um URL inválido, que o nome tenha
              sido alterado ou que a página tenha sido removida
            </p>
          </>
        ) : (
          <>
            <h1
              className="page-not-found-title"
              style={{ color: "#c28e2b", marginTop: 10 }}
            >
              Oops! Page not found
            </h1>
            <p className="page-not-found-text">
              It's possible you entered an invalid URL, the name has been
              changed, or the page has been removed
            </p>
          </>
        )}
      </div>
    </div>
  );
}
