import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import "./PropertyPage.css";
import SideBarPT from "../../../components/SideBar/SideBar";
import NavbarPT from "../../../components/Navbar/Navbar_PT";
import {
  deleteRequestTokenParameter,
  getRequestParameter,
  putRequestParameter,
} from "../../../api";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from "../../../config/firebaseConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../../components/Loading/Loading";
import noImageIcon from "../../../assets/images/no_image.png";
import photosActive from "../../../assets/images/photos_active.png";
import photosInactive from "../../../assets/images/photos_inactive.png";
import videosActive from "../../../assets/images/videos_active.png";
import videosInactive from "../../../assets/images/videos_inactive.png";
import plansActive from "../../../assets/images/plans_active.png";
import plansInactive from "../../../assets/images/plans_inactive.png";
import ConfirmationPopup from "../../../components/Popup/ConfirmationPopup/ConfimationPopup";

const photosRef = ref(storage, "images/properties/photos/");
const videosRef = ref(storage, "videos/properties/");
const plansRef = ref(storage, "images/properties/plans/");

export default function PropertyPagePT() {
  const location = useLocation();
  const { propertyName } = useParams();
  const [propertyId, setPropertyId] = useState("");
  const [propertyPrice, setPropertyPrice] = useState("");
  const [propertyPromotionPrice, setPropertyPromotionPrice] = useState("");
  const [propertyLocation, setPropertyLocation] = useState("");
  const [propertyTypeName, setPropertyTypeName] = useState("");
  const [propertySale, setPropertySale] = useState("");
  const [propertySaleState, setPropertySaleState] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageList, setImageList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [housePlanList, setHousePlanList] = useState([]);
  const [mediaList, setMediaList] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState("photos");
  const [counter, setCounter] = useState(0);
  const [scrollAmount, setScrollAmount] = useState(3);
  const [isLoading, setIsLoading] = useState(true);
  const [hoveringPhotos, setHoveringPhotos] = useState(false);
  const [hoveringVideos, setHoveringVideos] = useState(false);
  const [hoveringPlans, setHoveringPlans] = useState(false);
  const [imageBase64, setImageBase64] = useState("");
  const [mediaLoading, setMediaLoading] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState(false);
  const [isLoadingPopup, setIsLoadingPopup] = useState(false);
  const previewBarRef = useRef(null);

  const conditionalStyle = location.pathname.startsWith("/dashboard")
    ? { marginLeft: "100px" }
    : {};

  // useEffect(() => {
  //   const fetchProperties = async () => {
  //     const resAPI = await getRequestParameter(
  //       "/properties/",
  //       propertyName,
  //       null
  //     );
  //     // setProperties(res);
  //     setPropertyId(resAPI.property_id);
  //     console.log(resAPI);
  //     setIsLoading(false);
  //   };
  //   fetchProperties();
  // }, []);

  useEffect(() => {
    const fetchImageURLs = async () => {
      try {
        console.log("Propriedade aqui");
        const resAPI = await getRequestParameter(
          "/properties/",
          propertyName,
          null
        );

        console.log(resAPI[0]);

        const propertyIdAPI = resAPI[0].property_id;
        setPropertyId(propertyIdAPI);
        setPropertyPrice(resAPI[0].price);
        setPropertyPromotionPrice(resAPI[0].promotionprice);
        console.log(resAPI[0].freguesia_name, resAPI[0].municipio_name);
        setPropertyLocation(
          `${resAPI[0].freguesia_name}, ${resAPI[0].municipio_name}`
        );
        setPropertyTypeName(resAPI[0].propertytype);
        setPropertySaleState(resAPI[0].salestate);
        setPropertySale(resAPI[0].sale);

        const resPhotos = await listAll(photosRef);
        const resVideos = await listAll(videosRef);
        const resPlans = await listAll(plansRef);
        const photosUrls = await Promise.all(
          Object.values(resPhotos.items)
            .filter((itemRef) =>
              itemRef.name.includes(`property_${propertyIdAPI}`)
            )
            .map(async (itemRef) => {
              const url = await getDownloadURL(itemRef);
              return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve(url);
                img.onerror = reject;
                img.src = url;
              });
            })
        );

        const videosUrls = await Promise.all(
          Object.values(resVideos.items)
            .filter((itemRef) =>
              itemRef.name.includes(`property_${propertyIdAPI}`)
            )
            .map(async (itemRef) => {
              return await getDownloadURL(itemRef);
            })
        );

        const plansUrls = await Promise.all(
          Object.values(resPlans.items)
            .filter((itemRef) =>
              itemRef.name.includes(`property_${propertyIdAPI}`)
            )
            .map(async (itemRef) => {
              const url = await getDownloadURL(itemRef);
              return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve(url);
                img.onerror = reject;
                img.src = url;
              });
            })
        );

        photosUrls.sort((a, b) => {
          const regex = /property_[^_]+_(\d+)\.png/;
          const [, numberA] = a.match(regex);
          const [, numberB] = b.match(regex);
          return parseInt(numberA) - parseInt(numberB);
        });

        videosUrls.sort((a, b) => {
          const regex = /property_[^_]+_(\d+)\.mp4/;
          const [, numberA] = a.match(regex);
          const [, numberB] = b.match(regex);
          return parseInt(numberA) - parseInt(numberB);
        });

        plansUrls.sort((a, b) => {
          const regex = /property_[^_]+_(\d+)\.png/;
          const [, numberA] = a.match(regex);
          const [, numberB] = b.match(regex);
          return parseInt(numberA) - parseInt(numberB);
        });

        setImageList(photosUrls);
        setVideoList(videosUrls);
        setHousePlanList(plansUrls);

        setMediaList(photosUrls);

        const url = photosUrls.find((url) =>
          url.includes(`property_${propertyIdAPI}_0`)
        );
        setImageUrl(url);

        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchImageURLs();
  }, [photosRef]);

  // useEffect(() => {
  //   const url = imageList.find((url) =>
  //     url.includes(`property_${propertyId}.png`)
  //   );

  //   console.log("url", url);

  //   setImageUrl(url);
  // }, []);

  const incrementCounter = () => {
    setCounter((prevCounter) => {
      const nextCounter = (prevCounter + 1) % imageList.length;
      scrollPreviewIfNeeded(nextCounter);
      return nextCounter;
    });
  };

  const decrementCounter = () => {
    setCounter((prevCounter) => {
      const nextCounter =
        (prevCounter - 1 + imageList.length) % imageList.length;
      scrollPreviewIfNeeded(nextCounter);
      return nextCounter;
    });
  };

  const scrollPreviewIfNeeded = (nextCounter) => {
    const previewBar = previewBarRef.current;
    if (previewBar) {
      const previewImages = previewBar.querySelectorAll(".preview-image");
      const selectedImage = previewImages[nextCounter];
      if (selectedImage) {
        const previewBarRect = previewBar.getBoundingClientRect();
        const selectedImageRect = selectedImage.getBoundingClientRect();

        // Verifica se a imagem selecionada está dentro da visualização da barra de pré-visualização
        if (
          selectedImageRect.right > previewBarRect.right ||
          selectedImageRect.left < previewBarRect.left
        ) {
          // Calcula a quantidade de rolagem necessária para trazer a imagem selecionada para a visualização
          const scrollAmount =
            selectedImageRect.width * (nextCounter - counter);
          previewBar.scrollBy({
            left: scrollAmount,
            behavior: "smooth",
          });
        }
      }
    }
  };

  useEffect(() => {
    if (!location.pathname.startsWith("/dashboard")) {
      let viewedProperties = JSON.parse(
        localStorage.getItem("viewedProperties")
      );

      if (!viewedProperties) {
        viewedProperties = [];
      }

      if (propertyId && !viewedProperties.includes(propertyId)) {
        incrementViews(propertyId);
        viewedProperties.push(propertyId);
        localStorage.setItem(
          "viewedProperties",
          JSON.stringify(viewedProperties)
        );
      }
    }
  }, [propertyId]);

  const incrementViews = async (propertyId) => {
    try {
      const resAPI = await putRequestParameter("/views/", propertyId, null);

      console.log(`Incrementing views for property ${propertyId}`);
    } catch (error) {
      console.error("Error incrementing views:", error);
    }
  };

  const selectImage = (index) => {
    setCounter(index);
  };

  // useEffect(() => {
  //   const previewBar = previewBarRef.current;
  //   if (previewBar) {
  //     // Encontrar o primeiro elemento da barra de pré-visualização
  //     const firstImage = previewBar.querySelector(".preview-image");
  //     // Se existir, fazer scroll para que ele esteja visível
  //     if (firstImage) {
  //       firstImage.scrollIntoView({
  //         behavior: "smooth",
  //         block: "nearest",
  //         inline: "start",
  //       });
  //     }
  //   }
  // }, [previewBarRef]);

  // useEffect(() => {
  //   const previewBar = previewBarRef.current;
  //   if (previewBar) {
  //     // Selecionar o primeiro filho da barra de pré-visualização
  //     const firstImage = previewBar.firstChild;
  //     // Se o primeiro filho existir, fazer scroll até ele estar visível
  //     if (firstImage) {
  //       firstImage.scrollIntoView({
  //         behavior: "smooth",
  //         block: "nearest",
  //         inline: "start",
  //       });
  //     }
  //   }
  // }, [previewBarRef]);

  const scrollPreview = (direction) => {
    const previewBar = previewBarRef.current;
    if (previewBar) {
      const scrollAmount = 3; // Quantidade de miniaturas a serem roladas
      const scrollOffset =
        (scrollAmount * previewBar.scrollWidth) / imageList.length;
      previewBar.scrollBy({
        top: 0,
        left: direction === "left" ? -scrollOffset : scrollOffset,
        behavior: "smooth",
      });
    }
  };

  const expandImage = () => {
    const image = document.querySelector(".carousel-image");
    if (image) {
      if (image.requestFullscreen) {
        image.requestFullscreen();
      } else if (image.mozRequestFullScreen) {
        /* Firefox */
        image.mozRequestFullScreen();
      } else if (image.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        image.webkitRequestFullscreen();
      } else if (image.msRequestFullscreen) {
        /* IE/Edge */
        image.msRequestFullscreen();
      }
    }
  };

  const switchMedia = (mediaType) => {
    switch (mediaType) {
      case "photos":
        setMediaLoading(true);
        setSelectedMedia("photos");
        setMediaList(imageList);
        setMediaLoading(false);
        break;
      case "videos":
        setMediaLoading(true);
        setSelectedMedia("videos");
        setMediaList(videoList);
        setMediaLoading(false);
        break;
      case "plans":
        setMediaLoading(true);
        setSelectedMedia("plans");
        setMediaList(housePlanList);
        setMediaLoading(false);
        break;
      default:
        setMediaLoading(true);
        setSelectedMedia("photos");
        setMediaList(imageList);
        setMediaLoading(false);
        break;
    }
  };

  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const handleDelete = async () => {
    // setIsConfirmationPopupOpen(true);
    const token = localStorage.getItem("token");
    try {
      //await validateInfoUser();
      await deleteRequestTokenParameter("/admin/property/", propertyId, token);
      // localStorage.clear();

      setIsPopupOpen(false);
      setIsConfirmationPopupOpen(true);

      setTimeout(() => {
        window.location.href = "/dashboard/imoveis";
      }, 1000);

      // link
    } catch (error) {
      // setPopupMessage(error.message);
      setIsPopupOpen(true);
    }
  };

  useEffect(() => {
    console.log(isPopupOpen);
  }, [isPopupOpen]);

  return (
    <div>
      {location.pathname.startsWith("/dashboard") ? (
        <SideBarPT />
      ) : (
        <NavbarPT />
      )}
      <div className={`real-estate-container ${isLoading ? "loading" : ""}`}>
        <div className="real-estate-content" style={conditionalStyle}>
          {isLoading ? (
            <Loading />
          ) : (
            // <div className="property-container">
            <div>
              <div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="header-row" style={{ width: "100%" }}>
                    <div className="dashboard-title-container">
                      <h2 className="dashboard-real-estate-highlight-title">
                        <Link
                          to="/dashboard/imoveis"
                          className="dashboard-real-estate-link"
                        >
                          IMÓVEIS
                        </Link>{" "}
                        <div className="dashboard-real-estate-highlight-divider"></div>
                        <span className="dashboard-real-estate-title-highlight">
                          IMÓVEL
                        </span>
                      </h2>
                    </div>
                    {location.pathname.startsWith("/dashboard") && (
                      <div className="dashboard-property-page-title-container">
                        <Link
                          to={`/dashboard/imoveis/editar/${propertyId}`}
                          className="login-button"
                          style={{
                            height: "40px",
                            width: "200px",
                            fontFamily: "Open Sans, sans-serif",
                            textDecoration: "none",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "black",
                          }}
                        >
                          Editar imóvel
                        </Link>
                      </div>
                    )}
                    {location.pathname.startsWith("/dashboard") && (
                      <div className="dashboard-property-page-title-container">
                        <button
                          className="dashboard-property-page-header-button"
                          onClick={() => {
                            setIsPopupOpen(true);
                            console.log("Setting isPopupOpen to true");
                          }}
                        >
                          Eliminar imóvel
                        </button>
                      </div>
                    )}
                  </div>
                </div>

                <div className="carousel-container">
                  <div className="media-buttons-container">
                    <div className="media-buttons">
                      <button
                        className={`media-button ${
                          selectedMedia === "photos" ? "active" : ""
                        }`}
                        onClick={() => switchMedia("photos")}
                        onMouseEnter={() => setHoveringPhotos(true)}
                        onMouseLeave={() => setHoveringPhotos(false)}
                      >
                        <img
                          src={
                            selectedMedia === "photos" || hoveringPhotos
                              ? photosActive
                              : photosInactive
                          }
                          className="media-buttons-image"
                        />
                        Fotos
                      </button>
                      <button
                        className={`media-button ${
                          selectedMedia === "videos" ? "active" : ""
                        }`}
                        onClick={() => switchMedia("videos")}
                        onMouseEnter={() => setHoveringVideos(true)}
                        onMouseLeave={() => setHoveringVideos(false)}
                      >
                        <img
                          src={
                            selectedMedia === "videos" || hoveringVideos
                              ? videosActive
                              : videosInactive
                          }
                          className="media-buttons-image"
                        />
                        Vídeos
                      </button>
                      <button
                        className={`media-button ${
                          selectedMedia === "plans" ? "active" : ""
                        }`}
                        onClick={() => switchMedia("plans")}
                        onMouseEnter={() => setHoveringPlans(true)}
                        onMouseLeave={() => setHoveringPlans(false)}
                      >
                        <img
                          src={
                            selectedMedia === "plans" || hoveringPlans
                              ? plansActive
                              : plansInactive
                          }
                          className="media-buttons-image"
                        />
                        Plantas
                      </button>
                    </div>
                  </div>
                  {mediaLoading ? (
                    <Loading />
                  ) : (
                    <div>
                      <div className="carousel">
                        {mediaList.length > 0 ? (
                          <div>
                            {propertySaleState === "Reservado" && (
                              <div className="page-reserved-banner reserved">
                                Reservado
                              </div>
                            )}
                            {propertySaleState === "Vendido" && (
                              <div className="page-reserved-banner sold">
                                Vendido
                              </div>
                            )}
                            {selectedMedia != "videos" ? (
                              <img
                                key={counter}
                                className="carousel-image"
                                src={mediaList[counter]}
                                alt={`Property Image ${counter}`}
                              />
                            ) : (
                              <video
                                key={counter}
                                className="carousel-image"
                                controls
                              >
                                <source
                                  src={mediaList[counter]}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                            )}
                            <div className="image-number">
                              {counter + 1}/{mediaList.length}
                            </div>
                            <div className="expand-icon" onClick={expandImage}>
                              <FontAwesomeIcon icon={faExpand} />
                            </div>
                            <button
                              className="arrow prev"
                              onClick={decrementCounter}
                            >
                              ❮
                            </button>
                            <button
                              className="arrow next"
                              onClick={incrementCounter}
                            >
                              ❯
                            </button>
                          </div>
                        ) : (
                          <div className="no-image-container">
                            {propertySaleState === "Reservado" && (
                              <div className="page-reserved-banner reserved">
                                Reservado
                              </div>
                            )}
                            {propertySaleState === "Vendido" && (
                              <div className="page-reserved-banner sold">
                                Vendido
                              </div>
                            )}
                            <img className="no-image-icon" src={noImageIcon} />
                          </div>
                        )}
                      </div>
                      {mediaList.length > 0 && (
                        <div className="scroll-row">
                          <button
                            className="scroll-arrow prev"
                            onClick={() => scrollPreview("left")}
                          >
                            ❮
                          </button>
                          <div className="preview-bar" ref={previewBarRef}>
                            {mediaList.map((mediaUrl, index) =>
                              selectedMedia != "videos" ? (
                                <img
                                  key={index}
                                  className={`preview-image ${
                                    counter === index
                                      ? ""
                                      : "preview-image-inactive"
                                  }`}
                                  src={mediaUrl}
                                  alt={`Preview ${index + 1}`}
                                  onClick={() => selectImage(index)}
                                />
                              ) : (
                                <video
                                  key={index}
                                  className={`preview-image ${
                                    counter === index
                                      ? ""
                                      : "preview-image-inactive"
                                  }`}
                                  src={mediaUrl}
                                  alt={`Preview ${index + 1}`}
                                  onClick={() => selectImage(index)}
                                />
                              )
                            )}
                          </div>
                          <button
                            className="scroll-arrow next"
                            onClick={() => scrollPreview("right")}
                          >
                            ❯
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <div className="property-page-row ">
                  <p className="property-page-name">{propertyName}</p>
                  <p className="real-state-text-price">
                    {propertyPromotionPrice ? (
                      <span>
                        Desde{" "}
                        <span className="old-price">
                          {formatNumber(propertyPrice)}€
                        </span>{" "}
                        {formatNumber(propertyPromotionPrice)}€
                      </span>
                    ) : (
                      `Desde ${formatNumber(propertyPrice)}€`
                    )}
                  </p>
                </div>
                <p className="property-page-location">{propertyLocation}</p>
                <p className="property-page-type">{propertyTypeName}</p>
              </div>
              {isPopupOpen && (
                <div
                  className="modal-overlay"
                  onClick={() => setIsPopupOpen(false)}
                >
                  <div className="class-modal">
                    <div className="export-row">
                      <h2 className="export-title" style={{ fontSize: "20px" }}>
                        Tem a certeza que pretende eliminar o imóvel?
                      </h2>
                    </div>
                    <div className="close-buttons">
                      <button
                        className="button-cancel"
                        style={{ marginLeft: "0px" }}
                        onClick={() => setIsPopupOpen(false)}
                      >
                        Cancelar
                      </button>

                      <button
                        onClick={handleDelete}
                        className={`close-button-confirm ${
                          isLoadingPopup ? "close-form--submit-loading" : ""
                        }`}
                        disabled={isLoadingPopup}
                      >
                        {isLoadingPopup ? "" : "Confirmar"}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
          {isConfirmationPopupOpen && (
            <ConfirmationPopup
              showPopup={isConfirmationPopupOpen}
              message="Imóvel removido com sucesso!"
            />
          )}
          {/* </div> */}
        </div>
      </div>
    </div>
  );
}
