import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Dashboard_Real_Estate_Highlight.css";
import SideBarPT from "../../../../components/SideBar/SideBar";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from "../../../../config/firebaseConfig";
import noImageIcon from "../../../../assets/images/no_image.png";
import plusIcon from "../../../../assets/images/plus.png";
import { getRequest, putRequestToken } from "../../../../api";
import Loading from "../../../../components/Loading/Loading";

const imagesRef = ref(storage, "images/properties/photos/");

export default function DashboardRealEstateHighlight() {
  const [properties, setProperties] = useState([]);
  const [highlightedProperties, setHighlightedProperties] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [imageList, setImageList] = useState([]);
  const [highlightImageList, setHighlightImageList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isButtonEnabled, setIsButtonEnabled] = useState(false);

  useEffect(() => {
    const fetchProperties = async () => {
      const res = await getRequest("/properties", null);
      console.log(res);
      setProperties(res);
      const highlightProperties = res.filter((prop) => prop.highlight);
      setHighlightedProperties(highlightProperties);
      setSelectedProperties(
        highlightProperties.map((prop) => prop.property_id)
      );

      const resImages = await listAll(imagesRef);
      const urls = await Promise.all(
        Object.values(resImages.items)
          // .filter((itemRef) => itemRef.name.includes(`_0`))
          .map(async (itemRef) => {
            const url = await getDownloadURL(itemRef);
            return new Promise((resolve, reject) => {
              const img = new Image();
              img.onload = () => resolve(url);
              img.onerror = reject;
              img.src = url;
            });
          })
      );

      const highlightedUrls = urls.filter((url) => {
        return highlightProperties.some((prop) =>
          url.includes(`property_${prop.property_id}_0`)
        );
      });

      setImageList(urls);
      setHighlightImageList(highlightedUrls);

      setIsLoading(false);
    };
    fetchProperties();
  }, []);

  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const handleClickOutside = (e) => {
    if (e.target.classList.contains("popup-overlay")) {
      setIsPopupOpen(false);
    }
  };

  const handlePropertySelect = (propertyId) => {
    const updatedSelectedProperties = selectedProperties.includes(propertyId)
      ? selectedProperties.filter((id) => id !== propertyId)
      : [...selectedProperties, propertyId];
    setSelectedProperties(updatedSelectedProperties);
  };

  const handleConfirmSelection = async () => {
    try {
      const token = localStorage.getItem("token");
      await putRequestToken(
        "/admin/properties/highlight",
        { highlightedProperties: selectedProperties },
        token
      );

      setHighlightedProperties(
        selectedProperties.map((id) =>
          properties.find((prop) => prop.property_id === id)
        )
      );
      setIsPopupOpen(false);
    } catch (error) {
      console.error("error:", error);
    }
  };

  const propertiesGridCode = (property) => {
    return (
      <Link
        key={property.property_id}
        to={`/dashboard/imoveis/${encodeURIComponent(property.name)}`}
        className="real-estate-highlight-property-card-link"
      >
        <div className="real-estate-home-row-images">
          <div className="real-estate-column-images">
            <div className="real-estate-image-wrapper">
              {(property.salestate === "Reservado" ||
                property.salestate === "Vendido") && (
                <div>
                  <div
                    className={`reserved-banner ${
                      property.salestate === "Reservado"
                        ? "reserved"
                        : property.salestate === "Vendido"
                        ? "sold"
                        : ""
                    }`}
                  >
                    {property.salestate === "Reservado"
                      ? "Reservado"
                      : "Vendido"}
                  </div>
                  <div
                    className={`banner-triangle ${
                      property.salestate === "Reservado"
                        ? "reserved"
                        : property.salestate === "Vendido"
                        ? "sold"
                        : ""
                    }`}
                  ></div>
                </div>
              )}

              {imageList.length > 0 &&
                (imageList.find((url) =>
                  url.includes(`property_${property.property_id}_0`)
                ) ? (
                  <div className="image-container">
                    <img
                      src={imageList.find((url) =>
                        url.includes(`property_${property.property_id}_0`)
                      )}
                      className="real-estate-image"
                    />
                  </div>
                ) : (
                  <div className="no-image-container-general">
                    <img className="no-image-icon-general" src={noImageIcon} />
                  </div>
                ))}
            </div>
            <p className="real-state-text-location">{property.location}</p>
            <p className="real-state-text-type">{property.propertytype_name}</p>
            <p className="real-state-text-price">
              {property.promotionprice ? (
                <span>
                  from{" "}
                  <span className="old-price">
                    {formatNumber(property.price)}€
                  </span>{" "}
                  {formatNumber(property.promotionprice)}€
                </span>
              ) : (
                `from ${formatNumber(property.price)}€`
              )}
            </p>
          </div>
        </div>
      </Link>
    );
  };

  console.log(selectedProperties);
  console.log(highlightedProperties);

  return (
    <div className="app">
      <SideBarPT />
      <div className="dashboard-real-estate-highlight-container">
        <div className="dashboard-real-estate-highlight-column">
          <div className="dashboard-title-container">
            <h2 className="dashboard-real-estate-highlight-title">
              <Link
                to="/dashboard/imoveis"
                className="dashboard-real-estate-link"
              >
                IMÓVEIS
              </Link>{" "}
              <div className="dashboard-real-estate-highlight-divider"></div>
              <span className="dashboard-real-estate-title-highlight">
                DESTAQUE
              </span>
            </h2>
          </div>
          <div className="dashboard-highlight-container">
            {isLoading ? (
              <Loading />
            ) : (
              <div className="dashboard-highlight-row">
                {highlightedProperties.map((property) =>
                  propertiesGridCode(property)
                )}
                {/* {highlightedProperties.length < 3 && ( */}
                <button
                  className="add-more-container"
                  onClick={() => setIsPopupOpen(true)}
                >
                  <img className="plus-icon" src={plusIcon} />
                </button>
                {/* )} */}
              </div>
            )}
          </div>
          {isPopupOpen && (
            <div className="popup-overlay" onClick={handleClickOutside}>
              <div className="popup-content">
                <button
                  className="close-button"
                  onClick={() => setIsPopupOpen(false)}
                ></button>
                <p className="dashboard-real-estate-highlight-title">
                  Adicionar Propriedades
                </p>
                <table className="properties-table">
                  <thead>
                    <tr>
                      <th className="table-header-title">Imagem</th>
                      <th className="table-header-title">Nome</th>
                      <th className="table-header-title">Seleção</th>
                    </tr>
                  </thead>
                  <tbody>
                    {properties.length === 0 ? (
                      <tr>
                        <td
                          colSpan="3"
                          style={{ color: "red", textAlign: "center" }}
                        >
                          Não existem imóveis
                        </td>
                      </tr>
                    ) : (
                      properties.map((property) => (
                        <tr key={property.property_id}>
                          <td>
                            {imageList.length > 0 &&
                            imageList.find((url) =>
                              url.includes(`property_${property.property_id}_0`)
                            ) ? (
                              <img
                                src={imageList.find((url) =>
                                  url.includes(
                                    `property_${property.property_id}_0`
                                  )
                                )}
                                className="thumbnail-image"
                              />
                            ) : (
                              <img
                                className="no-image-icon-thumbnail"
                                src={noImageIcon}
                              />
                            )}
                          </td>
                          <td className="table-header-title">
                            {property.name}
                          </td>
                          <td>
                            <input
                              type="checkbox"
                              checked={selectedProperties.some(
                                (selectedProperty) =>
                                  selectedProperty === property.property_id
                              )}
                              onChange={() =>
                                handlePropertySelect(property.property_id)
                              }
                              disabled={
                                selectedProperties.length >= 3 &&
                                !selectedProperties.includes(
                                  property.property_id
                                )
                              }
                              className="location-checkbox-item"
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                <button
                  className="confirm-button"
                  onClick={handleConfirmSelection}
                  disabled={
                    (highlightedProperties.length === 0 &&
                      selectedProperties.length === 0) ||
                    (selectedProperties.length > 0 &&
                      selectedProperties.every((propId) =>
                        highlightedProperties.find(
                          (prop) => prop.property_id === propId
                        )
                      ))
                  }
                  style={{ marginTop: 40 }}
                >
                  Confirmar
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
