import { Outlet } from "react-router-dom";
import { Navigate } from "react-router-dom";

const useAuth = () => {
  const isLoggedIn = localStorage.getItem("isLoggedIn");

  return { isLoggedIn };
};

export const ProtectedRoutes = () => {
  const { isLoggedIn } = useAuth();

  if (!isLoggedIn) {
    return <Navigate to="/dashboard/login" replace />;
  }

  return <Outlet />;
};
