import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import "./Dashboard_Real_Estate_Add_Property.css";
import {
  ref,
  listAll,
  getDownloadURL,
  uploadBytesResumable,
  uploadBytes,
} from "firebase/storage";
import { storage } from "../../../../config/firebaseConfig";
import SideBarPT from "../../../../components/SideBar/SideBar";
import ConfirmationPopup from "../../../../components/Popup/ConfirmationPopup/ConfimationPopup.js";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getRequest, postRequestToken } from "../../../../api/index.js";
import regioes from "../../../../utils/regioes.json";
import Loading from "../../../../components/Loading/Loading.js";
import axios from "axios";

const imagesRef = ref(storage, "images/properties/photos/");

export default function DashboardRealEstateAddProperty() {
  const [activeTab, setActiveTab] = useState("images");
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [plants, setPlants] = useState([]);
  const [isDragging, setIsDragging] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [warningMessage, setWarningMessage] = useState("");
  const [errors, setErrors] = useState({});
  const imageRefs = useRef([]);
  const [isAdding, setIsAdding] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState(null);
  const [selectedFormDistrito, setSelectedFormDistrito] = useState({});
  const [selectedFormMunicipio, setSelectedFormMunicipio] = useState({});
  const [selectedFormFreguesia, setSelectedFormFreguesia] = useState({});
  const [openFormMunicipio, setOpenFormMunicipio] = useState({});
  const [openFormFreguesia, setOpenFormFreguesia] = useState({});
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState(false);
  const [selectedPropertyType, setSelectedPropertyType] = useState(null);
  const [propertyContent, setPropertyContent] = useState([]);
  const [isFormLocationOpen, setIsFormLocationOpen] = useState(false);
  const [propertiesTypes, setPropertiesTypes] = useState([]);
  const [propertiesRooms, setPropertiesRooms] = useState([]);
  const [propertiesStates, setPropertiesStates] = useState([]);
  const [propertiesSales, setPropertiesSales] = useState([]);
  const [propertiesEnergyCertificates, setPropertiesEnergyCertificates] =
    useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [formData, setFormData] = useState({
    name: "",
    name_en: "",
    address: "",
    price: "",
    description: "",
    description_en: "",
    propertytypeId: "",
    roomId: "",
    stateId: "",
    saleId: "",
    square: "",
    energycertificateId: "",
    lat: "",
    lng: "",
    freguesia: "",
    municipio: "",
    distrito: "",
    content: "",
    content_en: "",
  });

  const isFormLocationDropdown = () =>
    setIsFormLocationOpen(!isFormLocationOpen);

  useEffect(() => {
    const fetchPropertiesFilters = async () => {
      try {
        const res = await getRequest("/property/filters", null);
        setPropertiesTypes(res.types);
        setPropertiesRooms(res.rooms);
        setPropertiesStates(res.states);
        setPropertiesSales(res.sales);
        setPropertiesEnergyCertificates(res.energyCertificates);
      } catch (error) {
        console.error("Erro ao buscar filtros de propriedades:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPropertiesFilters();
  }, []);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    let newFiles = [];
    let invalidFiles = [];

    if (activeTab === "images" || activeTab === "plants") {
      newFiles = files
        .filter((file) => file.type.startsWith("image/"))
        .map((file) => URL.createObjectURL(file));
      invalidFiles = files.filter((file) => !file.type.startsWith("image/"));
    } else if (activeTab === "videos") {
      newFiles = files
        .filter((file) => file.type.startsWith("video/"))
        .map((file) => URL.createObjectURL(file));
      invalidFiles = files.filter((file) => !file.type.startsWith("video/"));
    }

    if (invalidFiles.length > 0) {
      setWarningMessage("Alguns ficheiros não são aceites!");
      setTimeout(() => setWarningMessage(""), 3000);
    }

    if (activeTab === "images") {
      setImages([...images, ...newFiles]);
    } else if (activeTab === "videos") {
      setVideos([...videos, ...newFiles]);
    } else if (activeTab === "plants") {
      setPlants([...plants, ...newFiles]);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const removeItem = (index) => {
    if (activeTab === "images") {
      setImages(images.filter((_, i) => i !== index));
    } else if (activeTab === "videos") {
      setVideos(videos.filter((_, i) => i !== index));
    } else if (activeTab === "plants") {
      setPlants(plants.filter((_, i) => i !== index));
    }
  };

  const openModal = (index) => {
    setCurrentImageIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const nextImage = () => {
    const activeArray =
      activeTab === "images"
        ? images
        : activeTab === "videos"
        ? videos
        : plants;
    setCurrentImageIndex((prevIndex) =>
      prevIndex === activeArray.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    const activeArray =
      activeTab === "images"
        ? images
        : activeTab === "videos"
        ? videos
        : plants;
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? activeArray.length - 1 : prevIndex - 1
    );
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = Array.from(event.dataTransfer.files);
    let newFiles = [];
    let invalidFiles = [];

    if (activeTab === "images" || activeTab === "plants") {
      newFiles = files
        .filter((file) => file.type.startsWith("image/"))
        .map((file) => URL.createObjectURL(file));
      invalidFiles = files.filter((file) => !file.type.startsWith("image/"));
    } else if (activeTab === "videos") {
      newFiles = files
        .filter((file) => file.type.startsWith("video/"))
        .map((file) => URL.createObjectURL(file));
      invalidFiles = files.filter((file) => !file.type.startsWith("video/"));
    }

    if (invalidFiles.length > 0) {
      setWarningMessage("Alguns ficheiros não são aceites!");
      setTimeout(() => setWarningMessage(""), 3000);
    }

    if (activeTab === "images") {
      setImages([...images, ...newFiles]);
    } else if (activeTab === "videos") {
      setVideos([...videos, ...newFiles]);
    } else if (activeTab === "plants") {
      setPlants([...plants, ...newFiles]);
    }

    setIsDragging(false);
  };

  const toggleFormMunicipio = (distrito) => {
    setOpenFormMunicipio((prevOpenMunicipio) => ({
      ...prevOpenMunicipio,
      [distrito]: !prevOpenMunicipio[distrito],
    }));
  };

  const toggleFormFreguesia = (distrito, municipio) => {
    setOpenFormFreguesia((prevOpenFreguesia) => ({
      ...prevOpenFreguesia,
      [distrito]: {
        ...prevOpenFreguesia[distrito],
        [municipio]: !prevOpenFreguesia[distrito]?.[municipio],
      },
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Verifica se o campo é o campo de preço
    if (name === "price" || name === "square") {
      // Remove caracteres não numéricos
      const numericValue = value.replace(/\D/, "");
      // Atualiza o estado com o valor numérico
      setFormData({
        ...formData,
        [name]: numericValue,
      });
      if (value.trim() !== "") {
        validateField(name, value);
      } else {
        // Remove o erro do campo se o valor estiver vazio
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors[name];
          return newErrors;
        });
      }
    } else {
      console.log(name);
      // Atualiza o estado normalmente para outros campos
      setFormData({
        ...formData,
        [name]: value,
      });
      if (value.trim() !== "") {
        validateField(name, value);
      } else {
        // Remove o erro do campo se o valor estiver vazio
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors[name];
          return newErrors;
        });
      }
    }
  };

  const validateField = (name, value) => {
    console.log("Entrou aqui");
    if (value.trim() === "" || value === "Selecione") {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "Este campo é obrigatório",
      }));
    } else {
      console.log("Entrou aqui 2");
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const handleLatChange = (e) => {
    let { value } = e.target;

    // Remover todos os caracteres não numéricos exceto o ponto decimal
    value = value.replace(/[^0-9.]/g, "");

    // Adicionar o ponto decimal na posição correta
    if (value.length === 3 && !value.includes(".")) {
      value = value.slice(0, 2) + "." + value.slice(2);
    }

    const regex = /^\d{0,2}(\.\d{0,6})?$/;
    if (value === "" || regex.test(value)) {
      setFormData({
        ...formData,
        lat: value,
      });
    }
  };

  const handleLngChange = (e) => {
    let { value } = e.target;
    // Garantir que o valor comece com um "-"
    if (!value.startsWith("-")) {
      value = "-" + value;
    }

    if (value.length === 4 && !value.includes(".")) {
      value = value.slice(0, 3) + "." + value.slice(3);
    }

    const regex = /^-\d{0,3}(\.\d{0,6})?$/;
    if (value === "-" || regex.test(value)) {
      setFormData({
        ...formData,
        lng: value,
      });
    }
  };

  const handleChangeContent = (value) => {
    setFormData({
      ...formData,
      content: value,
    });

    if (value.trim() !== "") {
      validateField("content", value);
    } else {
      // Remove o erro do campo se o valor estiver vazio
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors["content"];
        return newErrors;
      });
    }
  };

  const handleChangeContentEN = (value) => {
    setFormData({
      ...formData,
      content_en: value,
    });

    if (value.trim() !== "") {
      validateField("content_en", value);
    } else {
      // Remove o erro do campo se o valor estiver vazio
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors["content_en"];
        return newErrors;
      });
    }
  };

  const handleAddProperty = async () => {
    // setIsConfirmationPopupOpen(true);
    const token = localStorage.getItem("token");
    try {
      //await validateInfoUser();
      // await deleteRequestTokenParameter("/admin/property/", propertyId, token);
      // // localStorage.clear();
      setIsPopupOpen(false);
      // setIsConfirmationPopupOpen(true);
      // setTimeout(() => {
      //   window.location.href = "/dashboard/imoveis";
      // }, 1000);
      // // link
    } catch (error) {
      // setPopupMessage(error.message);
      setIsPopupOpen(true);
    }
  };

  const handleDropdownChange = (fieldName, idField, options) => (e) => {
    const selectedValue = e.target.value;
    const selectedItem = options.find(
      (item) => item[idField] === selectedValue
    );

    console.log(selectedValue);
    console.log(selectedItem);
    console.log(fieldName);

    let updatedFormData = {
      ...formData,
      [`${fieldName}Id`]: selectedItem ? selectedItem[`${fieldName}_id`] : "",
    };

    if (fieldName === "propertytype") {
      setSelectedPropertyType(selectedItem.name);
      console.log(selectedPropertyType);

      if (selectedItem.name.toLowerCase() === "terreno") {
        // Define os campos room_id e energycertificate_id como null se for terreno
        updatedFormData = {
          ...updatedFormData,
          roomId: "",
          energycertificateId: "",
        };

        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors.roomId;
          delete newErrors.energycertificateId;
          return newErrors;
        });
      }
    }

    setFormData(updatedFormData);

    console.log(selectedValue);
    console.log(fieldName);
    if (selectedValue !== "") {
      validateField(fieldName, selectedValue);
    } else {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[fieldName];
        return newErrors;
      });
    }
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
  ];

  const handleContentChange = (value) => {
    setPropertyContent(value);
  };

  const validateForm = () => {
    const newErrors = {};

    // IDs específicos para propertytypeId
    const specificPropertyTypeIds = [
      "fc8a20c9-29b8-4ee8-bf3d-8d0c7fbe9510",
      "4485da45-6c0f-4984-b66c-d88a4e97a64d",
    ];

    for (const field in formData) {
      // Verificação especial para energycertificateId e roomId
      if (
        specificPropertyTypeIds.includes(formData.propertytypeId) &&
        (field === "energycertificateId" || field === "roomId") &&
        formData[field] === ""
      ) {
        newErrors[field] = "Este campo é obrigatório";
      }

      // Verificação para todos os outros campos
      if (
        field !== "energycertificateId" &&
        field !== "roomId" &&
        formData[field] === ""
      ) {
        newErrors[field] = "Este campo é obrigatório";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    console.log(Object.keys(errors).length);
    console.log(Object.keys(errors).length === 0);
    if (validateForm()) {
      console.log(formData);
      try {
        setIsAdding(true);
        const response = await submitForm(formData);
        console.log(response);
        if (response.status === 200) {
          const propertyId = response.data.property_id;
          await uploadFiles(propertyId);

          setTimeout(() => {
            setIsAdding(false);
            setIsConfirmationPopupOpen(true);
          }, 100);

          setTimeout(() => {
            window.location.href = "/dashboard/imoveis";
          }, 1000);
        }
      } catch (error) {
        console.error("Erro na requisição:", error);
      }
    }
  };

  const submitForm = async (data) => {
    const token = localStorage.getItem("token");
    console.log(token);
    const response = await postRequestToken("/admin/property", formData, token);
    console.log(response);
    return response;
  };

  const uploadFiles = async (propertyId) => {
    const uploadPromises = [];

    const uploadToFirebase = async (file, path, mimeType) => {
      const response = await fetch(file);
      const blob = await response.blob();
      const storageRef = ref(storage, path);
      const metadata = {
        contentType: mimeType,
      };

      return new Promise((resolve, reject) => {
        const uploadTask = uploadBytes(storageRef, blob, metadata);
        uploadTask.then(
          async (snapshot) => {
            const downloadURL = await getDownloadURL(snapshot.ref);
            resolve(downloadURL);
          },
          (error) => {
            console.error("Upload failed:", error);
            reject(error);
          }
        );
      });
    };

    // Upload images
    if (images.length > 0) {
      images.forEach((file, index) => {
        const path = `images/properties/photos/property_${propertyId}_${index}.png`;
        uploadPromises.push(uploadToFirebase(file, path, "image/png"));
      });
    }

    // Upload plans
    if (plants.length > 0) {
      plants.forEach((file, index) => {
        const path = `images/properties/plans/property_${propertyId}_${index}.png`;
        uploadPromises.push(uploadToFirebase(file, path, "image/png"));
      });
    }

    // Upload videos
    if (videos.length > 0) {
      videos.forEach((file, index) => {
        const path = `videos/properties/property_${propertyId}_${index}.mp4`;
        uploadPromises.push(uploadToFirebase(file, path, "video/mp4"));
      });
    }

    await Promise.all(uploadPromises);
  };

  const handleFreguesiaChange = (distrito, municipio, freguesia) => {
    setSelectedFormFreguesia((prevSelectedFreguesia) => {
      const updatedFreguesias = {};

      // Se a freguesia está sendo desmarcada, apenas atualizamos o estado de forma limpa
      if (prevSelectedFreguesia[distrito]?.[municipio]?.[freguesia]) {
        const updatedMunicipioFreguesias = {
          ...(prevSelectedFreguesia[distrito]?.[municipio] || {}),
          [freguesia]: false,
        };

        updatedFreguesias[distrito] = {
          ...(prevSelectedFreguesia[distrito] || {}),
          [municipio]: updatedMunicipioFreguesias,
        };

        // Verifica se não há mais freguesias selecionadas para o município
        const isMunicipioEmpty = Object.values(
          updatedMunicipioFreguesias
        ).every((isSelected) => !isSelected);
        if (isMunicipioEmpty) {
          delete updatedFreguesias[distrito][municipio];
        }

        // Verifica se não há mais municípios selecionados para o distrito
        const isDistritoEmpty =
          Object.keys(updatedFreguesias[distrito] || {}).length === 0;
        if (isDistritoEmpty) {
          delete updatedFreguesias[distrito];
        }

        // Limpar o formData
        setFormData({
          ...formData,
          distrito: "",
          municipio: "",
          freguesia: "",
        });

        return updatedFreguesias;
      }

      console.log(freguesia);

      if (freguesia !== "") {
        validateField("freguesia", freguesia);
      } else {
        setErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors["freguesia"];
          return newErrors;
        });
      }

      console.log(formData);

      // Limpa todas as freguesias selecionadas
      return {
        [distrito]: {
          [municipio]: {
            [freguesia]: true,
          },
        },
      };
    });

    // Atualiza o formData
    setFormData({
      ...formData,
      distrito,
      municipio,
      freguesia,
    });
  };

  // const handleTranslate = async (e) => {
  //   // const { name_en, value } = e.target;
  //   // setFormData((prevData) => ({
  //   //   ...prevData,
  //   //   [name_en]: value,
  //   // }));

  //   try {
  //     const response = await axios.post(
  //       "https://api-free.deepl.com/v2/translate",
  //       {
  //         auth_key: "198NhbUFtUuRcEYwp",
  //         text: formData.name,
  //         source_lang: "PT-PT",
  //         target_lang: "EN-GB",
  //       },
  //       {
  //         headers: {
  //           "Content-Type": "application/x-www-form-urlencoded",
  //         },
  //       }
  //     );

  //     setFormData((prevData) => ({
  //       ...prevData,
  //       name_en: response.data.translations[0].text,
  //     }));
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  return (
    <div className="app">
      <SideBarPT />
      <div className={`real-estate-container ${isLoading ? "loading" : ""}`}>
        <div className="real-estate-content" style={{ marginLeft: "100px" }}>
          <div className="dashboard-title-container">
            <h2 className="dashboard-real-estate-highlight-title">
              <Link
                to="/dashboard/imoveis"
                className="dashboard-real-estate-link"
              >
                IMÓVEIS
              </Link>{" "}
              <div className="dashboard-real-estate-highlight-divider"></div>
              <span className="dashboard-real-estate-title-highlight">
                ADICIONAR IMÓVEL
              </span>
            </h2>
          </div>
          {isLoading ? (
            <Loading />
          ) : (
            <div className="dashboard-add-container">
              <div className="image-upload-header">
                <h3 style={{ color: "white" }}>Conteúdos</h3>
                <div className="tabs">
                  <button
                    className={`tab-button ${
                      activeTab === "images" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("images")}
                  >
                    Imagens
                  </button>
                  <button
                    className={`tab-button ${
                      activeTab === "videos" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("videos")}
                  >
                    Vídeos
                  </button>
                  <button
                    className={`tab-button ${
                      activeTab === "plants" ? "active" : ""
                    }`}
                    onClick={() => handleTabChange("plants")}
                  >
                    Plantas
                  </button>
                </div>
                <label className="upload-button">
                  Adicionar{" "}
                  {activeTab === "images"
                    ? "Imagens"
                    : activeTab === "videos"
                    ? "Vídeos"
                    : "Plantas"}
                  <input
                    type="file"
                    multiple
                    accept={
                      activeTab === "images" || activeTab === "plants"
                        ? "image/*"
                        : "video/*"
                    }
                    onChange={handleFileChange}
                    style={{ display: "none" }}
                  />
                </label>
              </div>
              {warningMessage && (
                <div className="warning-message">{warningMessage}</div>
              )}
              <div
                className={`image-grid-container fixed-height-container ${
                  (activeTab === "images" && images.length === 0) ||
                  (activeTab === "videos" && videos.length === 0) ||
                  (activeTab === "plants" && plants.length === 0)
                    ? "no-items"
                    : ""
                } ${isDragging ? "dragging" : ""}`}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                {isDragging ? (
                  <div className="drag-message">Solte o seu ficheiro aqui</div>
                ) : (activeTab === "images" && images.length === 0) ||
                  (activeTab === "videos" && videos.length === 0) ||
                  (activeTab === "plants" && plants.length === 0) ? (
                  <div className="empty-message">
                    Adicione{" "}
                    {activeTab === "images"
                      ? "imagens"
                      : activeTab === "videos"
                      ? "vídeos"
                      : "plantas"}{" "}
                    ou arraste para aqui
                  </div>
                ) : (
                  (activeTab === "images"
                    ? images
                    : activeTab === "videos"
                    ? videos
                    : plants
                  ).map((item, index) => (
                    <div
                      key={index}
                      className="image-grid-item"
                      onClick={() => openModal(index)}
                      ref={(el) => (imageRefs.current[index] = el)}
                    >
                      {activeTab === "images" || activeTab === "plants" ? (
                        <img src={item} alt={`Upload Preview ${index + 1}`} />
                      ) : (
                        <video src={item} controls />
                      )}
                      <button
                        className="remove-image-button"
                        onClick={(e) => {
                          e.stopPropagation();
                          removeItem(index);
                        }}
                      >
                        &times;
                      </button>
                    </div>
                  ))
                )}
              </div>
              {isModalOpen && (
                <div className="add-modal-overlay" onClick={closeModal}>
                  <div
                    className="add-modal-content"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <button className="add-modal-prev" onClick={prevImage}>
                      &#10094;
                    </button>
                    {activeTab === "images" || activeTab === "plants" ? (
                      <img
                        src={
                          activeTab === "images"
                            ? images[currentImageIndex]
                            : plants[currentImageIndex]
                        }
                        alt={`Image ${currentImageIndex + 1}`}
                      />
                    ) : (
                      <video src={videos[currentImageIndex]} controls />
                    )}
                    <button className="add-modal-next" onClick={nextImage}>
                      &#10095;
                    </button>
                  </div>
                </div>
              )}
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  placeholder="Nome*"
                  value={formData.name}
                  onChange={handleChange}
                  className={`form-group-input ${
                    errors.name ? "error-input" : ""
                  }`}
                />
                {errors.name && (
                  <span className="form-submit-error">{errors.name}</span>
                )}
              </div>
              <div className="form-group">
                <input
                  type="text"
                  name="name_en"
                  placeholder="Nome Inglês*"
                  value={formData.name_en}
                  onChange={handleChange}
                  className={`form-group-input ${
                    errors.name_en ? "error-input" : ""
                  }`}
                />
                {errors.name_en && (
                  <span className="form-submit-error">{errors.name_en}</span>
                )}
              </div>
              {/* <button type="button" onClick={handleTranslate}>
                Gerar Tradução
              </button> */}
              <div className="form-group">
                <input
                  type="text"
                  name="address"
                  placeholder="Endereço*"
                  value={formData.address}
                  onChange={handleChange}
                  className={`form-group-input ${
                    errors.address ? "error-input" : ""
                  }`}
                />
                {errors.address && (
                  <span className="form-submit-error">{errors.address}</span>
                )}
              </div>
              <div className="form-group-lat-lng">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="price-input-container"
                    style={{ width: "98%" }}
                  >
                    <input
                      id="price"
                      type="text"
                      name="price"
                      placeholder="Preço*"
                      value={formData.price}
                      onChange={handleChange}
                      className={`form-group-input ${
                        errors.price ? "error-input" : ""
                      }`}
                    />
                    <span
                      className={`currency ${
                        errors.square ? "error-input" : ""
                      }`}
                    >
                      €
                    </span>
                  </div>
                  {errors.price && (
                    <span className="form-submit-error">{errors.price}</span>
                  )}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <div
                    className="price-input-container"
                    style={{ width: "98%" }}
                  >
                    <input
                      id="square"
                      type="text"
                      name="square"
                      value={formData.square}
                      onChange={handleChange}
                      className={`form-group-input ${
                        errors.square ? "error-input" : ""
                      }`}
                      placeholder="Área*"
                    />
                    <span
                      className={`currency-square ${
                        errors.square ? "error-input" : ""
                      }`}
                    >
                      m<sup>2</sup>
                    </span>
                  </div>
                  {errors.square && (
                    <span className="form-submit-error">{errors.square}</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <textarea
                  name="description"
                  value={formData.description}
                  placeholder="Descrição*"
                  onChange={handleChange}
                  className={`form-group-textarea ${
                    errors.description ? "error-input" : ""
                  }`}
                  maxLength={340}
                />
                {errors.description && (
                  <span className="form-submit-error">
                    {errors.description}
                  </span>
                )}
              </div>
              <div className="form-group">
                <textarea
                  name="description_en"
                  value={formData.description_en}
                  placeholder="Descrição Inglês*"
                  onChange={handleChange}
                  className={`form-group-textarea ${
                    errors.description_en ? "error-input" : ""
                  }`}
                  maxLength={340}
                />
                {errors.description_en && (
                  <span className="form-submit-error">
                    {errors.description_en}
                  </span>
                )}
              </div>
              <div className="form-group-lat-lng">
                <div
                  style={{
                    width: "90%",
                    marginRight: "15px",
                  }}
                >
                  <input
                    type="text"
                    name="lat"
                    placeholder="Latitude*"
                    value={formData.lat}
                    onChange={handleLatChange}
                    className={`form-group-input ${
                      errors.lat ? "error-input" : ""
                    }`}
                  />
                  {errors.lat && (
                    <span className="form-submit-error">{errors.lat}</span>
                  )}
                </div>
                <div style={{ width: "90%", marginLeft: "15px" }}>
                  <input
                    type="text"
                    name="lng"
                    placeholder="Longitude*"
                    value={formData.lng}
                    onChange={handleLngChange}
                    className={`form-group-input ${
                      errors.lng ? "error-input" : ""
                    }`}
                  />
                  {errors.lng && (
                    <span className="form-submit-error">{errors.lng}</span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label style={{ color: "white" }}>Tipo de Propriedade*: </label>
                <select
                  name="propertytypeId"
                  value={formData.propertytypeId}
                  onChange={(e) => {
                    handleChange(e); // Chama a função handleChange para atualizar o estado
                    handleDropdownChange(
                      // Chama a função handleDropdownChange para validar o campo
                      "propertytype",
                      "propertytype_id",
                      propertiesTypes
                    )(e);
                  }}
                  className={errors.propertytypeId ? "error-input" : ""} // Adiciona a classe de erro se houver um erro
                >
                  <option value="" disabled>
                    Selecione
                  </option>
                  {propertiesTypes.map((type) => (
                    <option
                      key={type.propertytype_id}
                      value={type.propertytype_id}
                    >
                      {type.name}
                    </option>
                  ))}
                </select>
                {errors.propertytypeId && ( // Exibe a mensagem de erro se houver erro ou Selecione for selecionado
                  <span className="form-submit-error">
                    {errors.propertytypeId || "Este campo é obrigatório"}
                  </span>
                )}
              </div>

              {selectedPropertyType && selectedPropertyType != "Terreno" && (
                <div>
                  <div className="form-group">
                    <label style={{ color: "white" }}>Quartos*: </label>
                    <select
                      name="roomId"
                      value={formData.roomId}
                      onChange={(e) => {
                        handleChange(e); // Chama a função handleChange para atualizar o estado
                        handleDropdownChange(
                          // Chama a função handleDropdownChange para validar o campo
                          "room",
                          "room_id",
                          propertiesRooms
                        )(e);
                      }}
                      className={errors.roomId ? "error-input" : ""} // Adiciona a classe de erro se houver um erro
                    >
                      <option value="" disabled>
                        Selecione
                      </option>
                      {propertiesRooms.map((room) => (
                        <option key={room.room_id} value={room.room_id}>
                          {room.room}
                        </option>
                      ))}
                    </select>
                    {errors.roomId && ( // Exibe a mensagem de erro se houver erro ou Selecione for selecionado
                      <span className="form-submit-error">
                        {errors.roomId || "Este campo é obrigatório"}
                      </span>
                    )}
                  </div>

                  <div className="form-group">
                    <label style={{ color: "white" }}>
                      Certificado energético*:{" "}
                    </label>
                    <select
                      name="energycertificateId"
                      value={formData.energycertificateId}
                      onChange={(e) => {
                        handleChange(e); // Chama a função handleChange para atualizar o estado
                        handleDropdownChange(
                          // Chama a função handleDropdownChange para validar o campo
                          "energycertificate",
                          "energycertificate_id",
                          propertiesEnergyCertificates
                        )(e);
                      }}
                      className={
                        errors.energycertificateId ? "error-input" : ""
                      } // Adiciona a classe de erro se houver um erro
                    >
                      <option value="" disabled>
                        Selecione
                      </option>
                      {propertiesEnergyCertificates.map((certificate) => (
                        <option
                          key={certificate.energycertificate_id}
                          value={certificate.energycertificate_id}
                        >
                          {certificate.energycertificate}
                        </option>
                      ))}
                    </select>
                    {(formData.propertytypeId ===
                      "fc8a20c9-29b8-4ee8-bf3d-8d0c7fbe9510" ||
                      formData.propertytypeId ===
                        "4485da45-6c0f-4984-b66c-d88a4e97a64d") &&
                      errors.energycertificateId && ( // Exibe a mensagem de erro se houver erro ou Selecione for selecionado
                        <span className="form-submit-error">
                          {errors.energycertificateId ||
                            "Este campo é obrigatório"}
                        </span>
                      )}
                  </div>
                </div>
              )}
              <div className="form-group">
                <label style={{ color: "white" }}>Estado*: </label>
                <select
                  name="stateId"
                  value={formData.stateId}
                  onChange={(e) => {
                    handleChange(e); // Chama a função handleChange para atualizar o estado
                    handleDropdownChange(
                      // Chama a função handleDropdownChange para validar o campo
                      "state",
                      "state_id",
                      propertiesStates
                    )(e);
                  }}
                  className={errors.stateId ? "error-input" : ""} // Adiciona a classe de erro se houver um erro
                >
                  <option value="" disabled>
                    Selecione
                  </option>
                  {propertiesStates.map((state) => (
                    <option key={state.state_id} value={state.state_id}>
                      {state.state}
                    </option>
                  ))}
                </select>
                {errors.stateId && ( // Exibe a mensagem de erro se houver erro ou Selecione for selecionado
                  <span className="form-submit-error">
                    {errors.stateId || "Este campo é obrigatório"}
                  </span>
                )}
              </div>
              <div className="form-group">
                <label style={{ color: "white" }}>Negócio*: </label>
                <select
                  name="saleId"
                  value={formData.saleId}
                  onChange={(e) => {
                    handleChange(e); // Chama a função handleChange para atualizar o estado
                    handleDropdownChange(
                      // Chama a função handleDropdownChange para validar o campo
                      "sale",
                      "sale_id",
                      propertiesSales
                    )(e);
                  }}
                  className={errors.saleId ? "error-input" : ""} // Adiciona a classe de erro se houver um erro
                >
                  <option value="" disabled>
                    Selecione
                  </option>
                  {propertiesSales.map((sale) => (
                    <option key={sale.sale_id} value={sale.sale_id}>
                      {sale.sale}
                    </option>
                  ))}
                </select>
                {errors.saleId && ( // Exibe a mensagem de erro se houver erro ou Selecione for selecionado
                  <span className="form-submit-error">
                    {errors.saleId || "Este campo é obrigatório"}
                  </span>
                )}
              </div>
              <div>
                <div className="add-form-location-container">
                  <button
                    onClick={isFormLocationDropdown}
                    className="add-location-button"
                  >
                    Localização*
                    <span
                      className={`dropdown-arrow`}
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <svg
                        className={`arrow-down-icon ${
                          selectedSortOption != null && !isFormLocationOpen
                            ? "close"
                            : ""
                        }`}
                        fill="#ffffff"
                        onClick={() => {
                          if (
                            selectedSortOption != null &&
                            !isFormLocationOpen
                          ) {
                            setSelectedSortOption(null);
                          }
                        }}
                      >
                        <path
                          d={
                            !isFormLocationOpen
                              ? "M7 5.586L1.707.293A1 1 0 1 0 .293 1.707l6 6a1 1 0 0 0 1.414 0l6-6a1 1 0 0 0 0-1.414 1 1 0 0 0-1.414 0L7 5.586z"
                              : "M7 2.414l5.293 5.293a1 1 0 0 0 1.414-1.414l-6-6a1 1 0 0 0-1.414 0l-6 6a1 1 0 1 0 1.414 1.414L7 2.414z"
                          }
                          fill-rule="evenodd"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </div>
                {isFormLocationOpen && (
                  <div className="form-location-dropdown">
                    <ul>
                      {regioes.map((regiao) => (
                        <div key={regiao.regiao}>
                          {regiao.regiões.map((distrito) => (
                            <li key={distrito.distrito}>
                              <div
                                className="location-dropdown-row"
                                onClick={(e) => {
                                  toggleFormMunicipio(distrito.distrito);
                                }}
                              >
                                <label onClick={(e) => e.stopPropagation()}>
                                  {distrito.distrito}
                                </label>
                                <span
                                  className="dropdown-arrow"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    toggleFormMunicipio(distrito.distrito);
                                  }}
                                >
                                  <svg
                                    className={`arrow-down-icon ${
                                      openFormMunicipio[distrito.distrito]
                                        ? "open"
                                        : ""
                                    }`}
                                    fill="#000000"
                                  >
                                    <path
                                      d={
                                        openFormMunicipio[distrito.distrito]
                                          ? "M7 2.414l5.293 5.293a1 1 0 0 0 1.414-1.414l-6-6a1 1 0 0 0-1.414 0l-6 6a1 1 0 1 0 1.414 1.414L7 2.414z"
                                          : "M7 5.586L1.707.293A1 1 0 1 0 .293 1.707l6 6a1 1 0 0 0 1.414 0l6-6a1 1 0 0 0 0-1.414 1 1 0 0 0-1.414 0L7 5.586z"
                                      }
                                      fillRule="evenodd"
                                    ></path>
                                  </svg>
                                </span>
                              </div>
                              {openFormMunicipio[distrito.distrito] && (
                                <ul className="sub-checkboxes">
                                  {distrito.municipios.map((municipio) => (
                                    <li
                                      key={municipio.municipio}
                                      style={{ paddingRight: 0 }}
                                    >
                                      <div
                                        className="location-dropdown-row"
                                        onClick={(e) => {
                                          toggleFormFreguesia(
                                            distrito.distrito,
                                            municipio.municipio
                                          );
                                        }}
                                      >
                                        <label
                                          onClick={(e) => e.stopPropagation()}
                                        >
                                          {municipio.municipio}
                                        </label>
                                        <span
                                          className="dropdown-arrow"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            toggleFormFreguesia(
                                              distrito.distrito,
                                              municipio.municipio
                                            );
                                          }}
                                        >
                                          <svg
                                            className={`arrow-down-icon ${
                                              openFormFreguesia[
                                                distrito.distrito
                                              ]?.[municipio.municipio]
                                                ? "open"
                                                : ""
                                            }`}
                                            fill="#000000"
                                          >
                                            <path
                                              d={
                                                openFormFreguesia[
                                                  distrito.distrito
                                                ]?.[municipio.municipio]
                                                  ? "M7 2.414l5.293 5.293a1 1 0 0 0 1.414-1.414l-6-6a1 1 0 0 0-1.414 0l-6 6a1 1 0 1 0 1.414 1.414L7 2.414z"
                                                  : "M7 5.586L1.707.293A1 1 0 1 0 .293 1.707l6 6a1 1 0 0 0 1.414 0l6-6a1 1 0 0 0 0-1.414 1 1 0 0 0-1.414 0L7 5.586z"
                                              }
                                              fill-rule="evenodd"
                                            ></path>
                                          </svg>
                                        </span>
                                      </div>
                                      {openFormFreguesia[distrito.distrito]?.[
                                        municipio.municipio
                                      ] && (
                                        <ul className="sub-sub-checkboxes">
                                          {municipio.freguesias.map(
                                            (freguesia) => {
                                              const isFreguesiaSelected =
                                                !!selectedFormFreguesia[
                                                  distrito.distrito
                                                ]?.[municipio.municipio]?.[
                                                  freguesia.freguesia
                                                ];
                                              const isAnyFreguesiaSelected =
                                                Object.values(
                                                  selectedFormFreguesia
                                                ).some((distrito) =>
                                                  Object.values(distrito).some(
                                                    (municipio) =>
                                                      Object.values(
                                                        municipio
                                                      ).some(
                                                        (isSelected) =>
                                                          isSelected
                                                      )
                                                  )
                                                );

                                              return (
                                                <li key={freguesia.freguesia}>
                                                  <label>
                                                    <input
                                                      type="checkbox"
                                                      onChange={() =>
                                                        handleFreguesiaChange(
                                                          distrito.distrito,
                                                          municipio.municipio,
                                                          freguesia.freguesia
                                                        )
                                                      }
                                                      checked={
                                                        isFreguesiaSelected
                                                      }
                                                      disabled={
                                                        !isFreguesiaSelected &&
                                                        isAnyFreguesiaSelected
                                                      }
                                                      className="location-checkbox-item"
                                                    />
                                                    {freguesia.freguesia}
                                                  </label>
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      )}
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          ))}
                        </div>
                      ))}
                    </ul>
                  </div>
                )}
                {errors.freguesia && ( // Exibe a mensagem de erro se houver erro ou Selecione for selecionado
                  <span className="form-submit-error">
                    {errors.freguesia || "Este campo é obrigatório"}
                  </span>
                )}
              </div>
              <div>
                <ReactQuill
                  className="article-content-container"
                  style={{
                    height: "1400px",
                    maxHeight: "1440px",
                    marginBottom: "20px",
                    color: "white",
                  }}
                  value={formData.content}
                  onChange={handleChangeContent}
                  modules={modules}
                  formats={formats}
                  placeholder="Conteúdo*"
                />
                {errors.content && (
                  <span
                    className="form-submit-error"
                    style={{ paddingTop: 40 }}
                  >
                    {errors.content}
                  </span>
                )}
              </div>
              <div>
                <ReactQuill
                  className="article-content-container"
                  style={{
                    height: "1400px",
                    maxHeight: "1440px",
                    marginTop: "100px",
                    marginBottom: "20px",
                    color: "white",
                  }}
                  value={formData.content_en}
                  onChange={handleChangeContentEN}
                  modules={modules}
                  formats={formats}
                  placeholder="Conteúdo Inglês*"
                />
                {errors.content_en && (
                  <span
                    className="form-submit-error"
                    style={{ paddingTop: 40 }}
                  >
                    {errors.content_en}
                  </span>
                )}
              </div>
              <div className="add-popup-buttons-row">
                <Link
                  to="/dashboard/imoveis"
                  className="add-button-cancel"
                  onClick={() => setIsPopupOpen(false)}
                >
                  Cancelar
                </Link>
                <button
                  className={`login-button ${
                    isAdding ? "form--submit-loading" : ""
                  }`}
                  disabled={isAdding}
                  onClick={handleSubmit}
                >
                  {isAdding ? "" : "Adicionar"}
                </button>
              </div>
              {isConfirmationPopupOpen && (
                <ConfirmationPopup
                  showPopup={isConfirmationPopupOpen}
                  message="Imóvel adicionado com sucesso!"
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
