import React, { useState, useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import "./Teste_Detalhes_Imovel.css";
import SideBarPT from "../../components/SideBar/SideBar";
import NavbarPT from "../../components/Navbar/Navbar_PT";
import {
  deleteRequestTokenParameter,
  getRequestParameter,
  putRequestParameter,
} from "../../api";
import { ref, listAll, getDownloadURL } from "firebase/storage";
import { storage } from "../../config/firebaseConfig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand } from "@fortawesome/free-solid-svg-icons";
import Loading from "../../components/Loading/Loading";
import noImageIcon from "../../assets/images/no_image.png";
import photosActive from "../../assets/images/photos_active.png";
import photosInactive from "../../assets/images/photos_inactive.png";
import videosActive from "../../assets/images/videos_active.png";
import videosInactive from "../../assets/images/videos_inactive.png";
import plansActive from "../../assets/images/plans_active.png";
import plansInactive from "../../assets/images/plans_inactive.png";
import ConfirmationPopup from "../../components/Popup/ConfirmationPopup/ConfimationPopup";
import Portugal_flag from "../../assets/images/Portugal_flag.png";
import United_Kingdom_flag from "../../assets/images/United-Kingdom_flag.png";
import { ReactComponent as ArrowDown } from "../../assets/images/Arrow_down.svg";
import { ReactComponent as ArrowDownYellow } from "../../assets/images/Arrow_down_active.svg";
import { ReactComponent as ArrowDownTransparent } from "../../assets/images/Arrow_down_transparent.svg";

const photosRef = ref(storage, "images/properties/photos/");
const videosRef = ref(storage, "videos/properties/");
const plansRef = ref(storage, "images/properties/plans/");

export default function TesteDetalhesImovelEN() {
  const location = useLocation();
  const { propertyName } = useParams();
  const [propertyId, setPropertyId] = useState(
    "9a49a8de-33f2-4d54-94d8-e8344c13758c"
  );
  // const [propertyPrice, setPropertyPrice] = useState("");
  // const [propertyPromotionPrice, setPropertyPromotionPrice] = useState("");
  // const [propertyLocation, setPropertyLocation] = useState("");
  // const [propertyTypeName, setPropertyTypeName] = useState("");
  // const [propertyFreguesiaName, setPropertyFreguesiaName] = useState("");
  // const [propertyMunicipioName, setPropertyMunicipioName] = useState("");
  // const [propertyRoom, setPropertyRoom] = useState("");
  // const [propertyContent, setPropertyContent] = useState("");
  const [propertyPrice, setPropertyPrice] = useState(0);
  const [propertyPromotionPrice, setPropertyPromotionPrice] = useState();
  const [propertyLocation, setPropertyLocation] = useState("");
  const [propertyTypeName, setPropertyTypeName] = useState("");
  const [propertyFreguesiaName, setPropertyFreguesiaName] = useState("");
  const [propertyMunicipioName, setPropertyMunicipioName] = useState("");
  const [propertyRoom, setPropertyRoom] = useState("");
  const [propertyContent, setPropertyContent] = useState();
  const [propertySale, setPropertySale] = useState("");
  const [propertySaleState, setPropertySaleState] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [imageList, setImageList] = useState([]);
  const [videoList, setVideoList] = useState([]);
  const [housePlanList, setHousePlanList] = useState([]);
  const [mediaList, setMediaList] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState("photos");
  const [counter, setCounter] = useState(0);
  const [scrollAmount, setScrollAmount] = useState(3);
  const [isLoading, setIsLoading] = useState(true);
  const [hoveringPhotos, setHoveringPhotos] = useState(false);
  const [hoveringVideos, setHoveringVideos] = useState(false);
  const [hoveringPlans, setHoveringPlans] = useState(false);
  const [imageBase64, setImageBase64] = useState("");
  const [mediaLoading, setMediaLoading] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isConfirmationPopupOpen, setIsConfirmationPopupOpen] = useState(false);
  const [isLoadingPopup, setIsLoadingPopup] = useState(false);
  const previewBarRef = useRef(null);
  const [buttonActive, setButtonActive] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    // Limpe o event listener quando o componente for desmontado
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const conditionalStyle = location.pathname.startsWith("/dashboard")
    ? { marginLeft: "100px" }
    : {};

  // useEffect(() => {
  //   const fetchProperties = async () => {
  //     const resAPI = await getRequestParameter(
  //       "/properties/",
  //       propertyName,
  //       null
  //     );
  //     // setProperties(res);
  //     setPropertyId(resAPI.property_id);
  //     console.log(resAPI);
  //     setIsLoading(false);
  //   };
  //   fetchProperties();
  // }, []);

  useEffect(() => {
    const fetchImageURLs = async () => {
      try {
        console.log("Propriedade aqui");
        const resAPI = await getRequestParameter(
          "/properties/",
          propertyName,
          null
        );

        console.log(resAPI[0]);

        const propertyIdAPI = resAPI[0].property_id;
        setPropertyId(propertyIdAPI);
        setPropertyPrice(resAPI[0].price);
        setPropertyPromotionPrice(resAPI[0].promotionprice);
        console.log(resAPI[0].freguesia_name, resAPI[0].municipio_name);
        setPropertyLocation(
          `${resAPI[0].freguesia_name}, ${resAPI[0].municipio_name}`
        );
        setPropertyTypeName(resAPI[0].propertytype_name);
        setPropertySaleState(resAPI[0].salestate);
        setPropertySale(resAPI[0].sale);
        setPropertyFreguesiaName(resAPI[0].freguesia_name);
        setPropertyMunicipioName(resAPI[0].municipio_name);
        setPropertyRoom(resAPI[0].room);
        setPropertyContent(resAPI[0].content_en);

        console.log(resAPI[0]);

        const resPhotos = await listAll(photosRef);
        const resVideos = await listAll(videosRef);
        const resPlans = await listAll(plansRef);
        const photosUrls = await Promise.all(
          Object.values(resPhotos.items)
            .filter((itemRef) =>
              itemRef.name.includes(`property_${propertyIdAPI}`)
            )
            .map(async (itemRef) => {
              const url = await getDownloadURL(itemRef);
              return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve(url);
                img.onerror = reject;
                img.src = url;
              });
            })
        );

        const videosUrls = await Promise.all(
          Object.values(resVideos.items)
            .filter((itemRef) =>
              itemRef.name.includes(`property_${propertyIdAPI}`)
            )
            .map(async (itemRef) => {
              return await getDownloadURL(itemRef);
            })
        );

        const plansUrls = await Promise.all(
          Object.values(resPlans.items)
            .filter((itemRef) =>
              itemRef.name.includes(`property_${propertyIdAPI}`)
            )
            .map(async (itemRef) => {
              const url = await getDownloadURL(itemRef);
              return new Promise((resolve, reject) => {
                const img = new Image();
                img.onload = () => resolve(url);
                img.onerror = reject;
                img.src = url;
              });
            })
        );

        photosUrls.sort((a, b) => {
          const regex = /property_[^_]+_(\d+)\.png/;
          const [, numberA] = a.match(regex);
          const [, numberB] = b.match(regex);
          return parseInt(numberA) - parseInt(numberB);
        });

        videosUrls.sort((a, b) => {
          const regex = /property_[^_]+_(\d+)\.mp4/;
          const [, numberA] = a.match(regex);
          const [, numberB] = b.match(regex);
          return parseInt(numberA) - parseInt(numberB);
        });

        plansUrls.sort((a, b) => {
          const regex = /property_[^_]+_(\d+)\.png/;
          const [, numberA] = a.match(regex);
          const [, numberB] = b.match(regex);
          return parseInt(numberA) - parseInt(numberB);
        });

        setImageList(photosUrls);
        setVideoList(videosUrls);
        setHousePlanList(plansUrls);

        setMediaList(photosUrls);

        const url = photosUrls.find((url) =>
          url.includes(`property_${propertyIdAPI}_0`)
        );
        setImageUrl(url);

        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    };

    fetchImageURLs();
  }, []);

  useEffect(() => {
    const url = imageList.find((url) =>
      url.includes(`property_${propertyId}.png`)
    );

    console.log("url", url);

    setImageUrl(url);
  }, []);

  const incrementCounter = () => {
    setCounter((prevCounter) => {
      const nextCounter = (prevCounter + 1) % imageList.length;
      scrollPreviewIfNeeded(nextCounter);
      return nextCounter;
    });
  };

  const decrementCounter = () => {
    setCounter((prevCounter) => {
      const nextCounter =
        (prevCounter - 1 + imageList.length) % imageList.length;
      scrollPreviewIfNeeded(nextCounter);
      return nextCounter;
    });
  };

  const scrollPreviewIfNeeded = (nextCounter) => {
    const previewBar = previewBarRef.current;
    if (previewBar) {
      const previewImages = previewBar.querySelectorAll(".preview-image");
      const selectedImage = previewImages[nextCounter];
      if (selectedImage) {
        const previewBarRect = previewBar.getBoundingClientRect();
        const selectedImageRect = selectedImage.getBoundingClientRect();

        // Verifica se a imagem selecionada está dentro da visualização da barra de pré-visualização
        if (
          selectedImageRect.right > previewBarRect.right ||
          selectedImageRect.left < previewBarRect.left
        ) {
          // Calcula a quantidade de rolagem necessária para trazer a imagem selecionada para a visualização
          const scrollAmount =
            selectedImageRect.width * (nextCounter - counter);
          previewBar.scrollBy({
            left: scrollAmount,
            behavior: "smooth",
          });
        }
      }
    }
  };

  useEffect(() => {
    if (!location.pathname.startsWith("/dashboard")) {
      let viewedProperties = JSON.parse(
        localStorage.getItem("viewedProperties")
      );

      if (!viewedProperties) {
        viewedProperties = [];
      }

      if (propertyId && !viewedProperties.includes(propertyId)) {
        incrementViews(propertyId);
        viewedProperties.push(propertyId);
        localStorage.setItem(
          "viewedProperties",
          JSON.stringify(viewedProperties)
        );
      }
    }
  }, [propertyId]);

  const incrementViews = async (propertyId) => {
    try {
      const resAPI = await putRequestParameter("/views/", propertyId, null);

      console.log(`Incrementing views for property ${propertyId}`);
    } catch (error) {
      console.error("Error incrementing views:", error);
    }
  };

  const selectImage = (index) => {
    setCounter(index);
  };

  const truncateText = (text, maxLength, width) => {
    // console.log(width);
    if (
      (width >= 0 && width <= 768) ||
      (width >= 768 && width <= 840) ||
      (width >= 1024 && width <= 1062)
    ) {
      console.log("Aqui");
      console.log(text.length);
      console.log(maxLength);
      if (text.length <= maxLength) {
        return text;
      }
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  // useEffect(() => {
  //   const previewBar = previewBarRef.current;
  //   if (previewBar) {
  //     // Encontrar o primeiro elemento da barra de pré-visualização
  //     const firstImage = previewBar.querySelector(".preview-image");
  //     // Se existir, fazer scroll para que ele esteja visível
  //     if (firstImage) {
  //       firstImage.scrollIntoView({
  //         behavior: "smooth",
  //         block: "nearest",
  //         inline: "start",
  //       });
  //     }
  //   }
  // }, [previewBarRef]);

  // useEffect(() => {
  //   const previewBar = previewBarRef.current;
  //   if (previewBar) {
  //     // Selecionar o primeiro filho da barra de pré-visualização
  //     const firstImage = previewBar.firstChild;
  //     // Se o primeiro filho existir, fazer scroll até ele estar visível
  //     if (firstImage) {
  //       firstImage.scrollIntoView({
  //         behavior: "smooth",
  //         block: "nearest",
  //         inline: "start",
  //       });
  //     }
  //   }
  // }, [previewBarRef]);

  const scrollPreview = (direction) => {
    const previewBar = previewBarRef.current;
    if (previewBar) {
      const scrollAmount = 3; // Quantidade de miniaturas a serem roladas
      const scrollOffset =
        (scrollAmount * previewBar.scrollWidth) / imageList.length;
      previewBar.scrollBy({
        top: 0,
        left: direction === "left" ? -scrollOffset : scrollOffset,
        behavior: "smooth",
      });
    }
  };

  const expandImage = () => {
    const image = document.querySelector(".test-carousel-image");
    if (image) {
      if (image.requestFullscreen) {
        image.requestFullscreen();
      } else if (image.mozRequestFullScreen) {
        /* Firefox */
        image.mozRequestFullScreen();
      } else if (image.webkitRequestFullscreen) {
        /* Chrome, Safari and Opera */
        image.webkitRequestFullscreen();
      } else if (image.msRequestFullscreen) {
        /* IE/Edge */
        image.msRequestFullscreen();
      }
    }
  };

  const switchMedia = (mediaType) => {
    switch (mediaType) {
      case "photos":
        setMediaLoading(true);
        setSelectedMedia("photos");
        setMediaList(imageList);
        setMediaLoading(false);
        break;
      case "videos":
        setMediaLoading(true);
        setSelectedMedia("videos");
        setMediaList(videoList);
        setMediaLoading(false);
        break;
      case "plans":
        setMediaLoading(true);
        setSelectedMedia("plans");
        setMediaList(housePlanList);
        setMediaLoading(false);
        break;
      default:
        setMediaLoading(true);
        setSelectedMedia("photos");
        setMediaList(imageList);
        setMediaLoading(false);
        break;
    }
  };

  const formatNumber = (number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  };

  const handleDelete = async () => {
    // setIsConfirmationPopupOpen(true);
    const token = localStorage.getItem("token");
    try {
      //await validateInfoUser();
      await deleteRequestTokenParameter("/admin/property/", propertyId, token);
      // localStorage.clear();

      setIsPopupOpen(false);
      setIsConfirmationPopupOpen(true);

      setTimeout(() => {
        window.location.href = "/dashboard/imoveis";
      }, 1000);

      // link
    } catch (error) {
      // setPopupMessage(error.message);
      setIsPopupOpen(true);
    }
  };

  useEffect(() => {
    console.log(isPopupOpen);
  }, [isPopupOpen]);

  console.log(propertyContent);

  return (
    <div className="app-container-imoveis">
      <div className="container-header">
        <div className="container-options">
          <Link
            to="/en"
            className={`container-suboption ${
              location.pathname === "/en" ? "active" : ""
            }`}
          >
            Home
          </Link>
          <Link
            to="/en/about-us"
            className={`container-suboption ${
              location.pathname === "/en/about-us" ? "active" : ""
            }`}
          >
            About Us
          </Link>
          <Link
            to="/en/real-estate"
            className={`container-suboption ${
              location.pathname === "/en/real-estate" ? "active" : ""
            }`}
          >
            Real Estate
          </Link>
          <Link
            to="/en/contacts"
            className={`container-suboption ${
              location.pathname === "/en/contacts" ? "active" : ""
            }`}
          >
            Contacts
          </Link>
          <div className="container-suboption">
            <div
              className={`container-suboption-flag ${
                dropdownOpen ? "active" : ""
              }`}
              onClick={handleDropdownToggle}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <img
                src={United_Kingdom_flag}
                className="container-flag"
                alt="EN"
              />
              {isHovered || dropdownOpen ? (
                <ArrowDownYellow className="container-arrow" fill="#c28e2b" />
              ) : (
                <ArrowDown className="container-arrow" />
              )}
            </div>
            {dropdownOpen && (
              // <div className="container-dropdown-content">
              //   <Link to="/en" className="container-dropdown-item">
              //     <img
              //       src={United_Kingdom_flag}
              //       className="container-flag"
              //       alt="EN"
              //     />
              //   </Link>
              // </div>
              <div
                className="container-dropdown-content"
                // onClick={handleDropdownToggle}
              >
                <div className="container-suboption-flag">
                  <Link
                    to={`/pt/imoveis/${encodeURIComponent(propertyName)}`}
                    className="container-dropdown-item"
                  >
                    <img
                      src={Portugal_flag}
                      className="container-flag"
                      alt="PT"
                    />
                  </Link>
                  <ArrowDownTransparent className="container-arrow" />
                </div>
              </div>
            )}
          </div>
        </div>
        <button
          className={`container-menu ${buttonActive ? "active" : ""}`}
          onClick={() => setButtonActive(!buttonActive)}
        >
          {!buttonActive ? (
            <svg
              className="menu-icon"
              stroke="currentColor"
              fill="#ffffff"
              strokeWidth="0"
              viewBox="0 0 24 24"
              aria-hidden="true"
              height="3em"
              width="3em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="menu-line top"
                d="M3 6.75A.75.75 0 013.75 6h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 6.75z"
              ></path>
              <path
                className="menu-line middle"
                d="M3 12a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75A.75.75 0 013 12z"
              ></path>
              <path
                className="menu-line bottom"
                d="M3 17.25a.75.75 0 01.75-.75h16.5a.75.75 0 010 1.5H3.75a.75.75 0 01-.75-.75z"
              ></path>
            </svg>
          ) : (
            <svg
              className="menu-icon-active"
              stroke="#ffffff"
              fill="#ffffff"
              strokeWidth="0"
              viewBox="0 0 24 24"
              aria-hidden="true"
              height="3em"
              width="3em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke="#ffffff"
                stroke-width="1.5"
                d="M18.973 5.027L5.028 18.972m0-13.945l13.944 13.945"
              ></path>
            </svg>
          )}
        </button>
        <div className={`menu-container ${buttonActive ? "active" : ""}`}>
          <div className={`menu-subcontainer ${buttonActive ? "active" : ""}`}>
            <div className="menu-subcontainer-column">
              <Link
                to="/en"
                className={`menu-subcontainer-option ${
                  location.pathname === "/en" ? "active" : ""
                }`}
              >
                Home
              </Link>
              <Link
                to="/en/about-us"
                className={`menu-subcontainer-option ${
                  location.pathname === "/en/about-us" ? "active" : ""
                }`}
              >
                About Us
              </Link>
              <Link
                to="/en/real-estate"
                className={`menu-subcontainer-option ${
                  location.pathname === "/en/real-estate" ? "active" : ""
                }`}
              >
                Real Estate
              </Link>
              <Link
                to="/en/contacts"
                className={`menu-subcontainer-option ${
                  location.pathname === "/en/contacts" ? "active" : ""
                }`}
              >
                Contacts
              </Link>
              <Link
                to={`/pt/imoveis/${encodeURIComponent(propertyName)}`}
                className={`menu-subcontainer-option ${
                  location.pathname === "/pt" ? "active" : ""
                }`}
              >
                <div className="menu-container-flag">
                  <div className="menu-subcontainer-flag">
                    <img
                      src={Portugal_flag}
                      alt="PT"
                      className="menu-subcontainer-flag-image"
                    />
                  </div>
                  <div>PT</div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={`real-estate-container ${isLoading ? "loading" : ""}`}>
        <div className="test-real-estate-content" style={conditionalStyle}>
          {isLoading ? (
            <Loading />
          ) : (
            // <div className="property-container">
            <div className="test-property-container">
              <div>
                <div className="test-carousel-container">
                  <div
                    className={`test-media-buttons-container ${
                      (videoList.length === 0 && housePlanList.length !== 0) ||
                      (videoList.length !== 0 && housePlanList.length === 0)
                        ? "no-one-property"
                        : ""
                    } ${
                      videoList.length === 0 && housePlanList.length === 0
                        ? "no-two-properties"
                        : ""
                    }`}
                  >
                    <div
                      className={`test-media-buttons ${
                        (videoList.length === 0 &&
                          housePlanList.length !== 0) ||
                        (videoList.length !== 0 && housePlanList.length === 0)
                          ? "no-one-property"
                          : ""
                      } ${
                        videoList.length === 0 && housePlanList.length === 0
                          ? "no-two-properties"
                          : ""
                      }`}
                    >
                      <button
                        className={`test-media-button ${
                          selectedMedia === "photos" ? "active" : ""
                        }`}
                        onClick={() => switchMedia("photos")}
                        onMouseEnter={() => setHoveringPhotos(true)}
                        onMouseLeave={() => setHoveringPhotos(false)}
                      >
                        <img
                          src={
                            selectedMedia === "photos" || hoveringPhotos
                              ? photosActive
                              : photosInactive
                          }
                          className="test-media-buttons-image"
                        />
                        <span className="media-text">Photos</span>
                      </button>
                      {videoList.length > 0 && (
                        <button
                          className={`test-media-button ${
                            selectedMedia === "videos" ? "active" : ""
                          }`}
                          onClick={() => switchMedia("videos")}
                          onMouseEnter={() => setHoveringVideos(true)}
                          onMouseLeave={() => setHoveringVideos(false)}
                        >
                          <img
                            src={
                              selectedMedia === "videos" || hoveringVideos
                                ? videosActive
                                : videosInactive
                            }
                            className="test-media-buttons-image"
                          />
                          <span className="media-text">Videos</span>
                        </button>
                      )}

                      {housePlanList.length > 0 && (
                        <button
                          className={`test-media-button ${
                            selectedMedia === "plans" ? "active" : ""
                          }`}
                          onClick={() => switchMedia("plans")}
                          onMouseEnter={() => setHoveringPlans(true)}
                          onMouseLeave={() => setHoveringPlans(false)}
                          style={
                            window.innerWidth <= 839
                              ? {
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }
                              : {}
                          }
                        >
                          <img
                            src={
                              selectedMedia === "plans" || hoveringPlans
                                ? plansActive
                                : plansInactive
                            }
                            className={`test-media-buttons-image ${
                              window.innerWidth <= 839 ? "small-image" : ""
                            }`}
                          />
                          <span className="media-text">Plans</span>
                        </button>
                      )}
                    </div>
                  </div>
                  {mediaLoading ? (
                    <Loading />
                  ) : (
                    <div className="test-carousel">
                      {mediaList.length > 0 ? (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <div className="test-property-no-image-container">
                            {propertySaleState === "Reservado" && (
                              <div className="test-reserved-banner reserved">
                                Reserved
                              </div>
                            )}
                            {propertySaleState === "Vendido" && (
                              <div className="test-reserved-banner sold">
                                Sold
                              </div>
                            )}
                            <div
                              className={`test-banner-triangle ${
                                propertySaleState === "Reservado"
                                  ? "reserved"
                                  : propertySaleState === "Vendido"
                                  ? "sold"
                                  : ""
                              }`}
                            ></div>
                            {selectedMedia != "videos" ? (
                              <img
                                key={counter}
                                className="test-carousel-image"
                                src={mediaList[counter]}
                                alt={`Property Image ${counter}`}
                              />
                            ) : (
                              <video
                                key={counter}
                                className="test-carousel-image"
                                controls
                                onLoadedData={(e) =>
                                  (e.target.style.display = "block")
                                }
                              >
                                <source
                                  src={mediaList[counter]}
                                  type="video/mp4"
                                />
                                Your browser does not support the video tag.
                              </video>
                            )}
                            <div className="image-number">
                              {counter + 1}/{mediaList.length}
                            </div>
                            <div className="expand-icon" onClick={expandImage}>
                              <FontAwesomeIcon icon={faExpand} />
                            </div>
                            <button
                              className="arrow prev"
                              onClick={decrementCounter}
                            >
                              ❮
                            </button>
                            <button
                              className="arrow next"
                              onClick={incrementCounter}
                            >
                              ❯
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <div className="test-property-no-image-container">
                            {propertySaleState === "Reservado" && (
                              <div className="test-reserved-banner reserved">
                                Reserved
                              </div>
                            )}
                            {propertySaleState === "Vendido" && (
                              <div className="test-reserved-banner sold">
                                Sold
                              </div>
                            )}
                            <div
                              className={`test-banner-triangle ${
                                propertySaleState === "Reservado"
                                  ? "reserved"
                                  : propertySaleState === "Vendido"
                                  ? "sold"
                                  : ""
                              }`}
                            ></div>
                            <img
                              className="test-property-no-image-icon"
                              src={noImageIcon}
                            />
                          </div>
                        </div>
                      )}
                      {mediaList.length > 0 && (
                        <div className="scroll-row">
                          <button
                            className="scroll-arrow prev"
                            onClick={() => scrollPreview("left")}
                          >
                            ❮
                          </button>
                          <div className="preview-bar" ref={previewBarRef}>
                            {mediaList.map((mediaUrl, index) =>
                              selectedMedia != "videos" ? (
                                <img
                                  key={index}
                                  className={`preview-image ${
                                    counter === index
                                      ? ""
                                      : "preview-image-inactive"
                                  }`}
                                  src={mediaUrl}
                                  alt={`Preview ${index + 1}`}
                                  onClick={() => selectImage(index)}
                                />
                              ) : (
                                <video
                                  key={index}
                                  className={`preview-image ${
                                    counter === index
                                      ? ""
                                      : "preview-image-inactive"
                                  }`}
                                  src={mediaUrl}
                                  alt={`Preview ${index + 1}`}
                                  onClick={() => selectImage(index)}
                                />
                              )
                            )}
                          </div>
                          <button
                            className="scroll-arrow next"
                            onClick={() => scrollPreview("right")}
                          >
                            ❯
                          </button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                {window.innerWidth >= 839 ? (
                  <>
                    <div className="test-property-page-row">
                      <p className="test-property-page-name">
                        {propertyTypeName === "Moradia"
                          ? "House"
                          : propertyTypeName === "Apartamento"
                          ? "Apartment"
                          : propertyTypeName === "Quinta"
                          ? "Farm"
                          : propertyTypeName === "Terreno"
                          ? "Land"
                          : ""}{" "}
                        {propertyRoom}
                      </p>
                      <p
                        className="test-real-state-text-price-row"
                        style={{ color: "#efede7" }}
                      >
                        {propertyPromotionPrice ? (
                          <span>
                            of{" "}
                            <span className="test-old-price">
                              {formatNumber(propertyPrice)}€
                            </span>{" "}
                            {formatNumber(propertyPromotionPrice)}€
                          </span>
                        ) : (
                          <>
                            of{" "}
                            <span className="test-normal-price">
                              {formatNumber(propertyPrice)} €
                            </span>
                          </>
                        )}
                      </p>
                    </div>
                    <p className="test-property-page-location">
                      {truncateText(
                        `${propertyMunicipioName} > ${propertyFreguesiaName}`,
                        150,
                        windowWidth
                      )}
                    </p>
                  </>
                ) : (
                  <div className="test-property-page-column">
                    <p className="test-property-page-name">
                      {propertyTypeName === "Moradia"
                        ? "House"
                        : propertyTypeName === "Apartamento"
                        ? "Apartment"
                        : propertyTypeName === "Quinta"
                        ? "Farm"
                        : propertyTypeName === "Terreno"
                        ? "Land"
                        : ""}{" "}
                      {propertyRoom}
                    </p>

                    <p className="test-property-page-location">
                      {truncateText(
                        `${propertyMunicipioName} > ${propertyFreguesiaName}`,
                        150,
                        windowWidth
                      )}
                    </p>
                    <p
                      className="test-real-state-text-price-row"
                      style={{ color: "#efede7" }}
                    >
                      {propertyPromotionPrice ? (
                        <span>
                          of{" "}
                          <span
                            className="test-old-price"
                            style={{ fontSize: "30px" }}
                          >
                            {formatNumber(propertyPrice)}€
                          </span>{" "}
                          <span style={{ fontSize: "30px" }}>
                            {formatNumber(propertyPromotionPrice)}€
                          </span>
                        </span>
                      ) : (
                        <>
                          of{" "}
                          <span
                            className="test-normal-price"
                            style={{ fontSize: "30px" }}
                          >
                            {formatNumber(propertyPrice)} €
                          </span>
                        </>
                      )}
                    </p>
                  </div>
                )}

                <div
                  className="test-property-page-content"
                  dangerouslySetInnerHTML={{ __html: propertyContent }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
