import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./SideBar.css";
import jm_logo from "../../assets/images/jm_investments_logo.png";
import Home from "../../assets/images/home.png";
import HomeActive from "../../assets/images/home_active.png";
import RealEstate from "../../assets/images/real_estate.png";
import RealEstateActive from "../../assets/images/real_estate_active.png";
import Leave from "../../assets/images/leave.png";
import CloseModalPT from "../CloseModal/CloseModal_PT";

export default function SideBarPT() {
  const [isExpanded, setIsExpanded] = useState(false);
  const location = useLocation();
  const [showPopup, setShowPopup] = useState(false);
  const [popupMessage, setPopupMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  const getHomeIcon = () =>
    location.pathname === "/dashboard" ? HomeActive : Home;
  const getRealEstateIcon = () =>
    location.pathname === "/dashboard/imoveis" ? RealEstateActive : RealEstate;
  // const getClassIcon = () =>
  //   location.pathname === "/gestao/aulas" ? ColorBlue : Color1;
  // const getEmployeeIcon = () =>
  //   location.pathname === "/gestao/funcionarios" ? GroupBlue : Group;
  // const getPriceIcon = () =>
  //   location.pathname === "/gestao/precario" ? DollarBlue : Dollar;
  // const getBlogIcon = () =>
  //   location.pathname.includes("/gestao/blog") ? VectorBlue : Vector;

  const getButtonClass = (path) => {
    if (path === "/dashboard") {
      return location.pathname === path
        ? "sidebar-item active-page"
        : "sidebar-item";
    } else if (path === "/dashboard/imoveis") {
      return location.pathname.startsWith(path)
        ? "sidebar-item active-page"
        : "sidebar-item";
    } else {
      return "sidebar-item";
    }
  };

  const getTextClass = (path) => {
    if (path === "/dashboard") {
      return location.pathname === path
        ? "text-container-active"
        : "text-container";
    } else if (path === "/dashboard/imoveis") {
      return location.pathname === path
        ? "text-container-active"
        : "text-container";
    } else {
      return "text-container";
    }
  };

  const handleClose = () => {
    setShowModal(true);
  };

  const handleCancelClose = () => {
    setShowModal(false);
    setIsExpanded(false);
  };

  return (
    <div
      className="sidebar"
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <div className="sidebar-content">
        <div>
          <div className="sidebar--icon--container">
            <img src={jm_logo} alt="" className="sidebar--icon" />
          </div>
          <hr style={{ marginLeft: "15px", marginRight: "15px" }} />
          <div>
            <ul style={{ marginTop: "0px" }}>
              <li>
                <Link to="/dashboard" className={getButtonClass("/dashboard")}>
                  <div className="item-container">
                    <div className="icon-container">
                      <img src={getHomeIcon()} className="sidebar-image" />
                    </div>

                    {isExpanded && (
                      <div className={getTextClass("/dashboard")}>Início</div>
                    )}
                  </div>
                </Link>
              </li>
              <li>
                <Link
                  to="/dashboard/imoveis"
                  className={getButtonClass("/dashboard/imoveis")}
                >
                  <div className="item-container">
                    <div className="icon-container">
                      <img
                        src={getRealEstateIcon()}
                        className="sidebar-image"
                      />
                    </div>

                    {isExpanded && (
                      <div className={getTextClass("/dashboard/imoveis")}>
                        Imóveis
                      </div>
                    )}
                  </div>
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div>
          <Link onClick={handleClose} className="close-item">
            <div className="close-item-container">
              <div className="icon-container" style={{ marginRight: "-12px" }}>
                <img src={Leave} className="sidebar-image" />
              </div>

              {isExpanded && <div className="text-container-leave">Sair</div>}
            </div>
          </Link>
        </div>
      </div>

      {showModal && <CloseModalPT onCancel={handleCancelClose} />}
    </div>
  );
}
